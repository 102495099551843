<template>
    <main class="container">
        <bp-banner :title="banner.title" :subtitle="banner.subtitle" />
        <BpInfo :info="info" />
        <div class="col-12">
            <div class="sign-up-body row">
            <div class=" col-12 col-md-4 mb-4 mb-md-0">
                <div class="sign-up-body-item">Go through checklist of what needs to be taken care of</div>
            </div>
            <div class=" col-12 col-md-4 mb-4 mb-md-0">
                <div class="sign-up-body-item">Deal with immediate practicalities</div>
            </div>
            <div class=" col-12 col-md-4 mb-4 mb-md-0">
                <div class="sign-up-body-item">Find a suitable funeral director and arrange the funeral</div>
            </div>
            </div>
        </div>
        <form-phone/>
        <bp-usp/>
        <bp-sign-up-form/>
    </main>
</template>

<script>
import BpUsp from '../../components/Usp.vue'
import BpInfo from '../../components/Info.vue'
import BpBanner from '../../components/Banner.vue'
import formPhone from '../../components/Forms/ContactForm.vue'
import BpSignUpForm from '../../components/Forms/SignUpForm.vue'

export default {
    name: 'LandingPage_1',
    components: {
        BpUsp,
        BpInfo,
        BpBanner,
        formPhone,
        BpSignUpForm
    },
    data() {
        return {
            info: {
                title: 'GET HELP WITH ARRANGING A FUNERAL',
                text:
                'We help you with all the practical lies by telephone and with our web planning tool. You first explain what you need help with and what ideas you already have in mind.',
            },
            banner: {
                title: '0800 8611 720',
                subtitle: 'Call us anytime',
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    width: 75%;
    max-width: 75%;
    display: flex;
    flex-direction: column;

    .sign-up-body {
        margin: 4% auto;
        width: 100%;
        display: flex;
        justify-content: flex-start;

        &-item {
            height: 100%;
            padding: 15px;
            border-left: 5px solid #583B75;
            background: #FFFFFF;
            border-radius: 2px;
            box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.1);
        }
    }
}

@media (max-width: 450px) {
    .container {
        width: 100%;
        max-width: 100%;
    }
}
</style>
