<template>
  <div class="modal__place-body">
    <div class="modal__place-yesNo">
      <div class="modal__place-yesNo-content">
        <div
          class="modal__place-yesNo-content-item"
          v-for="(question, index) in data"
          :key="index"
        >
          <h4>
            <span>{{ currentIndex + 1 }}. {{ question.question }}</span>
            <div
              v-if="question.description"
              class="modal__place-yesNo-content-item-description"
            >
              <p>{{ question.description }}</p>
            </div>
          </h4>
          <div class="modal__place_yesNo_content_item_btns">
            <div
              :class="[
                {
                  modal__place_yesNo_content_item_btns_text:
                    btnObj.imgDown || btnObj.imgUp || btnObj.imgCenter
                      ? false
                      : true,
                },
                {
                  modal__place_yesNo_content_item_btns_imgUp: btnObj.imgUp
                    ? true
                    : false,
                },
                {
                  modal__place_yesNo_content_item_btns_imgDown: btnObj.imgDown
                    ? true
                    : false,
                },
                {
                  modal__place_yesNo_content_item_btns_imgCenter: btnObj.imgCenter
                    ? true
                    : false,
                },
              ]"
              v-for="(btnObj, index) in question.head"
              :key="index"
              :ref="'div' + index"
              @click="moveForward"
            >
              <img
                v-if="btnObj.imgUp || btnObj.imgCenter"
                :src="btnObj.imgUp || btnObj.imgCenter"
                :alt="index"
              />
              <h3 v-if="btnObj.imgUp || btnObj.imgDown">{{ btnObj.head }}</h3>
              <p>
                {{ btnObj.text }}
                <a v-if="btnObj.imgUp" href="#">Discover more</a>
              </p>
              <img v-if="btnObj.imgDown" :src="btnObj.imgDown" :alt="index" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AskerYesNo',
  props: {
    toggleModalType: Function,
    data: Array,
    currentIndex: Number,
    moveForward: Function,
  },
  methods: {
    mouseOver(index) {
      this.$refs['div' + index][0].classList.add('activeType')

      for (const property in this.$refs) {
        if (this.$refs[property][0] != this.$refs['div' + index][0]) {
          this.$refs[property][0].classList.add('activeOpacity')
        }
      }
    },
  },
  mouseLeave(index) {
    this.$refs['div' + index][0].classList.remove('activeType')

    for (const property in this.$refs) {
      this.$refs[property][0].classList.remove('activeOpacity')
    }
  },
}
</script>

<style lang="scss" scoped>
.activeType {
  border: 1px solid #583b75 !important;
  box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.3);
}

.activeOpacity {
  opacity: 0.5;
}

.modal__place-body {
  width: 90%;

  .modal__place-button {
    margin: 15% auto 5% auto;
    background: #583b75;
    color: #ffffff;
    padding: 15px 80px;

    box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.3);

    font-weight: 600;
    font-size: 16px;
    text-align: center;
    border-radius: 2px;
  }

  .modal__place-yesNo {
    .modal__place-yesNo-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      margin: 5% 5% 0 5%;

      .modal__place-yesNo-content-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        h4 {
          display: flex;
          flex-direction: column;
          align-items: center;

          font-size: 140%;
          font-weight: 550;
          text-align: center;

          width: 100%;
          .modal__place-yesNo-content-item-description {
            background: #ffffff;
            box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.25);
            border-radius: 2px;
            margin-top: 5%;
            margin-bottom: 10%;
            width: 83%;
            p {
              font-size: 63%;
              font-weight: 500;
              line-height: 20px;
              padding: 3% 5% 1% 5%;
              text-align: center;
            }
          }
        }
        .modal__place_yesNo_content_item_btns {
          display: flex;
          justify-content: center;
          margin-top: 5%;
          width: 100%;
        }

        .modal__place_yesNo_content_item_btns_text {
          max-width: 100%;
          border: 1px solid rgba(139, 135, 143, 0.3);
          border-radius: 3px;
          padding: 4% 10% 2% 10%;
          &:first-child {
            margin-left: 0 !important;
          }
          margin-left: 5%;

          &:hover {
            cursor: pointer;
            border: 1px solid #583b75 !important;
            box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.3);
          }
        }

        .modal__place_yesNo_content_item_btns_imgUp {
          display: flex;
          flex-direction: column;
          flex: 1;

          &:hover {
            cursor: pointer;
            border: 1px solid #583b75 !important;
            box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.3);
          }

          border: 1px solid rgba(139, 135, 143, 0.3);
          border-radius: 3px;

          &:first-child {
            margin-left: 0 !important;
          }
          margin-left: 1.5%;

          img {
            width: 100%;
          }
          h3 {
            font-size: 14px;
            text-align: left;
            margin: 5% 6% 5% 6%;
          }
          p {
            text-align: left;
            font-size: 11px;
            margin: 0 6% 5% 6%;
            a {
              text-decoration: underLineProgress;
              color: #583b75;
            }
          }
        }

        .modal__place_yesNo_content_item_btns_imgDown {
          display: flex;
          flex-direction: column;
          flex: 1;

          &:hover {
            cursor: pointer;
            border: 1px solid #583b75 !important;
            box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.3);
          }

          border: 1px solid rgba(139, 135, 143, 0.3);
          border-radius: 3px;

          &:first-child {
            margin-left: 0 !important;
          }
          margin-left: 1.5%;

          img {
            width: 100%;
            margin-top: auto;
          }
          h3 {
            font-size: 14px;
            text-align: left;
            margin: 5% 6% 5% 6%;
            font-weight: 580;
          }
          p {
            text-align: left;
            font-size: 11px;
            margin: 0 6% 5% 6%;
          }
        }

        .modal__place_yesNo_content_item_btns_imgCenter {
          display: flex;
          flex: 1;

          &:hover {
            cursor: pointer;
            border: 1px solid #583b75 !important;
            box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.3);
          }

          border: 1px solid rgba(139, 135, 143, 0.3);
          border-radius: 3px;

          &:first-child {
            margin-left: 0 !important;
          }
          margin-left: 1.5%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 1390px) {
  .modal__place-body {
    width: 90%;

    .modal__place-button {
      margin: 15% auto 5% auto;
      background: #583b75;
      color: #ffffff;
      padding: 15px 80px;

      box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.3);

      font-weight: 600;
      font-size: 16px;
      text-align: center;
      border-radius: 2px;
    }

    .modal__place-yesNo {
      font-family: 'Noto Sans';
      .modal__place-yesNo-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin: 5% 5% 0 5%;
        .modal__place-yesNo-content-item {
          display: flex;
          flex-direction: column;
          align-items: center;

          h4 {
            display: flex;
            flex-direction: column;
            align-items: center;

            font-size: 100%;
            font-weight: 550;
            text-align: center;

            width: 100%;
            .modal__place-yesNo-content-item-description {
              background: #ffffff;
              box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.25);
              border-radius: 2px;
              margin-top: 5%;
              width: 80%;
              p {
                font-size: 60%;
                font-weight: 500;
                padding: 3% 5% 1% 5%;
                text-align: center;
              }
            }
          }

          .modal__place_yesNo_content_item_btns {
            display: flex;
            justify-content: center;
            margin-top: 5%;
            width: 100%;
          }

          .modal__place_yesNo_content_item_btns_text {
            max-width: 100%;
            border: 1px solid rgba(139, 135, 143, 0.3);
            border-radius: 3px;
            padding: 4% 10% 2% 10%;
            &:first-child {
              margin-left: 0 !important;
            }
            margin-left: 5%;

            &:hover {
              cursor: pointer;
            }
          }

          .modal__place_yesNo_content_item_btns_imgUp {
            display: flex;
            flex-direction: column;
            flex: 1;

            &:hover {
              cursor: pointer;
            }
            width: 23%;
            border: 1px solid rgba(139, 135, 143, 0.3);
            border-radius: 3px;

            &:first-child {
              margin-left: 0 !important;
            }
            margin-left: 1.5%;

            margin-top: -2.5%;

            img {
              width: 100%;
            }
            h3 {
              font-size: 14px;
              text-align: left;
              margin: 5% 6% 5% 6%;
            }
            p {
              text-align: left;
              font-size: 11px;
              margin: 0 6% 5% 6%;
              a {
                text-decoration: underLineProgress;
                color: #583b75;
              }
            }
          }

          .modal__place_yesNo_content_item_btns_imgDown {
            display: flex;
            flex-direction: column;
            flex: 1;

            &:hover {
              cursor: pointer;
            }
            width: 23%;
            border: 1px solid rgba(139, 135, 143, 0.3);
            border-radius: 3px;

            &:first-child {
              margin-left: 0 !important;
            }
            margin-left: 1.5%;

            margin-top: -2.5%;

            img {
              width: 100%;
              margin-top: auto;
            }
            h3 {
              font-size: 14px;
              text-align: left;
              margin: 5% 6% 5% 6%;
              font-weight: 580;
            }
            p {
              text-align: left;
              font-size: 11px;
              margin: 0 6% 5% 6%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1368px) and (max-height: 800px) {
  .modal__place-body {
    width: 90%;

    .modal__place-button {
      margin: 15% auto 5% auto;
      background: #583b75;
      color: #ffffff;
      padding: 15px 80px;

      box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.3);

      font-weight: 600;
      font-size: 16px;
      text-align: center;
      border-radius: 2px;
    }

    .modal__place-yesNo {
      font-family: 'Noto Sans';
      .modal__place-yesNo-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin: 5% 5% 0 5%;
        .modal__place-yesNo-content-item {
          display: flex;
          flex-direction: column;
          align-items: center;

          h4 {
            display: flex;
            flex-direction: column;
            align-items: center;

            font-size: 100%;
            font-weight: 550;
            text-align: center;

            width: 100%;
            .modal__place-yesNo-content-item-description {
              background: #ffffff;
              box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.25);
              border-radius: 2px;
              margin-top: 5%;
              width: 80%;
              p {
                font-size: 60%;
                font-weight: 500;
                padding: 3% 5% 1% 5%;
                text-align: center;
              }
            }
          }

          .modal__place_yesNo_content_item_btns {
            display: flex;
            justify-content: center;
            margin-top: 5%;
            width: 100%;
          }

          .modal__place_yesNo_content_item_btns_text {
            max-width: 100%;
            border: 1px solid rgba(139, 135, 143, 0.3);
            border-radius: 3px;
            padding: 4% 10% 2% 10%;
            &:first-child {
              margin-left: 0 !important;
            }
            margin-left: 5%;

            &:hover {
              cursor: pointer;
            }
          }

          .modal__place_yesNo_content_item_btns_imgUp {
            display: flex;
            flex-direction: column;
            flex: 1;

            &:hover {
              cursor: pointer;
            }
            width: 23%;
            border: 1px solid rgba(139, 135, 143, 0.3);
            border-radius: 3px;

            &:first-child {
              margin-left: 0 !important;
            }
            margin-left: 1.5%;

            margin-top: -3.5%;

            img {
              width: 100%;
            }
            h3 {
              font-size: 14px;
              text-align: left;
              margin: 5% 6% 5% 6%;
            }
            p {
              text-align: left;
              font-size: 11px;
              margin: 0 6% 5% 6%;
              a {
                text-decoration: underLineProgress;
                color: #583b75;
              }
            }
          }

          .modal__place_yesNo_content_item_btns_imgDown {
            display: flex;
            flex-direction: column;
            flex: 1;

            &:hover {
              cursor: pointer;
            }
            width: 23%;
            border: 1px solid rgba(139, 135, 143, 0.3);
            border-radius: 3px;

            &:first-child {
              margin-left: 0 !important;
            }
            margin-left: 1.5%;

            margin-top: -4%;

            img {
              width: 100%;
              margin-top: auto;
            }
            h3 {
              font-size: 14px;
              text-align: left;
              margin: 5% 6% 5% 6%;
              font-weight: 580;
            }
            p {
              text-align: left;
              font-size: 11px;
              margin: 0 6% 5% 6%;
            }
          }
        }
      }
    }
  }
}
</style>
