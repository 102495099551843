const state = {
    isShowNav: false,
}
export const toggleNavbarMutationsTypes = {
    openNavbar: 'openNavbar/isOpenNav',
    closeNavbar: 'closeNavbar/isCloseNav',
}

const mutations = {
    [toggleNavbarMutationsTypes.openNavbar](state) {
        state.isShowNav = true
    },
    [toggleNavbarMutationsTypes.closeNavbar](state) {
        state.isShowNav = false
    },
}

export default {
    state,
    mutations
}