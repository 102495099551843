<template>
  <div class="wish-list row">
    <div class="col-12 col-md-6">
      <ul class="apply-list">
        <li
          :style="{ color: executorIsAccepted ? 'black' : 'red' }"
          @click="
            toggleModal({
              quizzID: 0,
              quizzDesc: executorDesc,
              quizzData: {
                modals: {
                  type: 'executor',
                },
              },
            })
          "
        >
          <img
            v-if="executorIsAccepted"
            class="icon-confirm"
            alt=""
            src="../../../assets/button_done.png"
          />
          <img
            v-else
            class="icon-confirm"
            alt=""
            src="../../../assets/button_wrong.png"
          />

          <span>Add an Executor to myWishes</span>
          <img
            src="../../../assets/arrow-right.png"
            class="icon-right"
            alt="confirmed"
          />
        </li>

        <li
          @click="
            toggleModal({
              quizzID: 1,
              quizzDesc: 'Add notes to myWishes',
              quizzData: {
                modals: {
                  type: 'notes',
                },
              },
            })
          "
        >
          <img
            v-if="isFilled(1)"
            class="icon-confirm"
            alt=""
            src="../../../assets/button_done.png"
          />
          <img
            v-else
            class="icon-confirm"
            alt=""
            src="../../../assets/button_wrong.png"
          />

          <span>Add notes to myWishes</span>
          <img
            src="../../../assets/arrow-right.png"
            class="icon-right"
            alt="confirmed"
          />
        </li>
      </ul>
      <h3>My Wishes</h3>
      <ul class="apply-list">
        <li
          v-for="quizz in activeItems"
          :key="quizz.quizzID"
          @click="toggleModal(quizz)"
        >
          <img
            v-if="isFilled(quizz.quizzID)"
            class="icon-confirm"
            alt=""
            src="../../../assets/button_done.png"
          />
          <img
            v-else
            class="icon-confirm"
            alt=""
            src="../../../assets/button_wrong.png"
          />
          <span> {{ quizz.quizzDesc }}</span>
          <img
            src="../../../assets/arrow-right.png"
            class="icon-right"
            alt="confirmed"
          />
        </li>
      </ul>

      <h3 class="not-apply">Does not Apply</h3>
      <ul class="apply-list not-apply">
        <li v-for="quizz in notActiveItems" :key="quizz.id">
          <img src="" alt="" />
          <span class="not-apply-text"> {{ quizz.quizzDesc }}</span>
          <img
            src="../../../assets/not-apply-plus.png"
            class="icon-right"
            alt=""
            @click="toggleTooltip(quizz.quizzID)"
          />
          <bp-tooltip
            v-if="activeTooltip == quizz.quizzID && quizz.quizzData.options"
          >
            <span class="text"
              >Are you sure you want to add this to the To-Do-list?!
            </span>
            <button
              class="tooltip-btn"
              v-for="option in quizz.quizzData.options"
              :key="option.optionsID"
              @click="handleUpdate(option, quizz.quizzData.control_question)"
            >
              {{ option.text }}
            </button>
          </bp-tooltip>
        </li>
      </ul>

      <div class="modal" v-if="modalIsShown">
        <transition name="modal">
          <div class="modal__mask" @click="close">
            <div class="modal__container" @click.stop>
              <span @click="close" class="modal__close">&#x2715;</span>
              <h5>{{ quizzDesc }}</h5>
              <span
                v-if="excludeRemoveBtns"
                @click="showDeleteModal"
                class="modal__remove"
                >Remove</span
              >

              <component
                :is="currentContent"
                :close="close"
                :description="modalText"
                :quizzID="modalID"
                :setFilled="setFilled"
                :setExecutorStatus="setExecutorStatus"
                class="modal__body"
                @click="closeModal"
              />
            </div>
          </div>
        </transition>
      </div>
      <bp-delete-modal
        :callback="handleRemove"
        :showModal="deleteModalIsShown"
        :close="close"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { legalActionsTypes, legalGetterTypes } from '@/store/modules/legal'
import { userProfileGetterTypes } from '@/store/modules/userProfile'

import {
  quizzesGettersTypes,
  quizzesActionsTypes,
} from '@/store/modules/quizzesModals'

import { notificationGettersTypes } from '@/store/modules/notificationModals'

import BhPets from './Modals/TextModal.vue'
import BpExecutor from './Modals/AddExecutorModal.vue'
import BpSocial from './Modals/Socials.vue'
import BpBelong from './Modals/BelongingModal.vue'
import BpNotes from './Modals/NotesModal.vue'
import BpTooltip from './Modals/Tooltip.vue'
import BpDeleteModal from '../Modals/ConfirmDeleteModal.vue'
const {
  updateAnswer,
  changeAnswer,
  getDefaultAnswers,
  sendAnswers,
} = quizzesActionsTypes
const { getFilledInfo, getExecutorInfo } = legalActionsTypes

export default {
  name: 'BpBody',
  components: {
    BhPets,
    BpExecutor,
    BpSocial,
    BpTooltip,
    BpBelong,
    BpNotes,
    BpDeleteModal,
  },
  async mounted() {
    this.updateStatuses()
  },
  computed: {
    ...mapState({ currentUser: state => state.auth.currentUser }),
    ...mapGetters({
      activeItem: userProfileGetterTypes.activeWishlistID,
      myWishes: quizzesGettersTypes.myWishes,
      answers: quizzesGettersTypes.answers,
      notificationIsShown: notificationGettersTypes.notificationModalsIsShown,
      filledItems: legalGetterTypes.filled,
    }),
    excludeRemoveBtns() {
      const exclude = ['notes', 'executor']
      return !exclude.includes(this.modalType)
    },
    executorDesc() {
      return 'Add an Executor to myWishes'
    },
    executorIsAccepted() {
      return this.executorStatus === 'accepted'
    },
    executorIsDecline() {
      return this.executorStatus === 'decline'
    },
    ids() {
      return this.myWishes?.map(w => w.quizzID)
    },
    currentID() {
      return this.activeTooltip || this.modalID
    },
    activeItems() {
      const questionIDs = this.answers
        ?.filter(a => a.answer?.text === 'YES')
        .filter(a => !a.control_question)
        .map(a => a.questionID)

      return this.myWishes?.filter(w => questionIDs.includes(w.quizzID))
    },
    notActiveItems() {
      const questionIDs = this.answers
        ?.filter(a => a.answer?.text === 'NO')
        .map(a => a.questionID)

      return this.myWishes?.filter(w => questionIDs.includes(w.quizzID))
    },
    isControlQestion() {
      return this.myWishes.find(w => w.quizzData.control_question)
    },
    currentContent() {
      const modals = {
        question: 'bh-pets',
        executor: 'bp-executor',
        table: 'bp-belong',
        notes: 'bp-notes',
        /* accounts: 'bp-social', */
      }
      return modals[this.modalType]
    },
  },
  data() {
    return {
      modalIsShown: false,
      activeTooltip: null,
      modalID: null,
      modalType: 'question',
      quizzDesc: '',
      modalText: '',
      options: [],
      filled: [],
      deleteModalIsShown: false,
      executorStatus: null,
    }
  },
  methods: {
    closeModal() {
      this.close()
    },
    updateStatuses() {
      this.$store
        .dispatch(getFilledInfo, this.ids)
        .then(() => {
          this.filled = this.filledItems
          this.$store
            .dispatch(getExecutorInfo, this.currentUser.id)
            .then(res => {
              this.executorStatus = res.status
            })
        })
        .catch(Error)
    },
    toggleModal({ quizzID, quizzDesc, quizzData }) {
      this.modalIsShown = true
      this.modalID = quizzID
      this.options = quizzData?.options
      this.modalText = quizzData?.modals?.text
      this.modalType = quizzData?.modals?.type
      this.quizzDesc = quizzDesc

      // this.$store.commit(toggleActiveWishlistID, id)
    },
    toggleTooltip(id) {
      this.activeTooltip = this.activeTooltip !== id ? id : null
    },
    showDeleteModal() {
      this.modalIsShown = false
      this.deleteModalIsShown = true
    },
    handleRemove() {
      const answer = this.options.find(o => o.text === 'NO')
      this.handleUpdate(answer)
    },
    handleUpdate(answer, isControlQestion) {
      if (isControlQestion && answer.text == 'YES') {
        this.updateDefaultAnswer()
      }
      this.updateAnswer(answer)
      this.close()
    },
    close() {
      this.modalIsShown = false
      this.deleteModalIsShown = false
      this.activeTooltip = null
    },
    getLastWord(str) {
      const lastWord = str.split(' ').pop()
      return lastWord
    },
    setFilled(value) {
      if (this.isFilled(this.modalID) && !value.length) {
        this.filled = this.filled.filter(f => f !== this.modalID)
      } else {
        this.filled.push(this.modalID)
      }
    },
    setExecutorStatus(status) {
      this.executorStatus = status
      this.updateStatuses()
    },
    isFilled(id) {
      return this.filled.includes(id)
    },
    updateDefaultAnswer() {
      this.$store.dispatch(getDefaultAnswers, 'logged-in').then(() => {
        const data = {
          userID: this.currentUser.id,
          answers: this.answers,
        }
        this.$store.dispatch(sendAnswers, data)
      })
    },
    updateAnswer(answer) {
      const data = { questionID: this.currentID, answer }
      this.$store.dispatch(changeAnswer, data).then(() => {
        this.updateStatuses()
        this.$store.dispatch(updateAnswer, data)
      })
    },
  },
  watch: {
    ids(val) {
      this.$store
        .dispatch(getFilledInfo, val)
        .then(() => {
          this.filled = this.filledItems
        })
        .catch(Error)
    },
  },
}
</script>

<style lang="scss" scoped>
.tooltip-btn {
  width: 25%;
  border: 1px solid rgba(88, 59, 117, 0.33);
  border-radius: 2px;
  margin: 5%;
  text-align: center;
  &:hover {
    background-color: #583b75;
    box-shadow: 5px 5px 5px rgba(88, 59, 117, 0.3);
    color: #fff;
  }
}
.wish-list {
  h3 {
    font-size: 23px;
  }

  .not-apply {
    color: #9b9b9b;
    &-text {
      flex-basis: 87%;
    }
  }

  ul {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 1% 0 5% 0;
  }
  li {
    width: 100%;
    margin: 2% 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 17px;
    cursor: pointer;
    &.red {
      color: red;
    }
    span {
      flex-basis: 80%;
      text-align: start;
    }
    .icon-confirm {
      width: 32px;
      height: 32px;
      margin-right: 2%;
    }
  }

  .modal {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal__mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      transition: opacity 0.3s ease;

      .modal__container {
        width: 100%;
        max-width: 800px;
        padding: 40px 70px;
        box-sizing: border-box;
        overflow: auto;
        margin: 3% auto;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        position: relative;

        .modal__close {
          position: absolute;
          top: 10px;
          right: 13px;
          cursor: pointer;
        }

        h5 {
          font-size: 20px;
          text-align: center;
          padding-top: 10px;
          margin-bottom: 30px;
        }
        .modal__remove {
          cursor: pointer;
          text-align: center;
          &:hover {
            color: #583b75;
          }

          position: absolute;
          top: 10px;
          left: 13px;
        }
        .modal__body {
          .modal-enter {
            opacity: 0;
          }

          .modal-leave-active {
            opacity: 0;
          }

          .modal-enter .modal-container,
          .modal-leave-active .modal-container {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
</style>
