<template>
  <div class="container guests" data-vue-name="guests">
    <div class="row">
      <div class="col-12">
        <h3>Funeral guests: {{ countGuests }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div
          class="guests__scroll"
          :class="heightBoxInfo > 380 ? 'guests__scroll--isScroll' : ''"
        >
          <div class="position-relative">
            <ul class="guests_list-guests col-12">
              <li class="guests__title-head">
                <div class="row">
                  <div class="col-4 col-sm-5">Guest Name</div>
                  <div class="col-4 col-sm-5">Comment & contact details</div>
                </div>
              </li>
              <li v-for="guest in guests" :key="guest.id">
                <div class="row align-items-start">
                  <div class="col-4 col-sm-5">
                    <input
                      v-if="updateGuestID == guest.id"
                      class="update-field-input"
                      type="text"
                      v-model="updated.name"
                      @keyup.enter="handleSave(guest)"
                    />
                    <span v-else>{{ guest.name }}</span>
                  </div>
                  <div class="col-4 col-sm-5">
                    <input
                      v-if="updateGuestID == guest.id"
                      class="update-field-input"
                      type="text"
                      v-model="updated.text"
                      @keypress.enter="handleSave(guest)"
                    />
                    <span v-else>{{ guest.text }} </span>
                  </div>
                  <div class="col-4 col-sm-2">
                    <div class="guest__controls">
                      <img
                        v-if="updateGuestID == guest.id"
                        class="icon-confirm btn-save"
                        alt="done"
                        title="Done"
                        src="../../../assets/button_done.png"
                        @click="handleSave(guest)"
                      />

                      <img
                        v-else
                        class="icon-confirm btn-edit"
                        alt="edit"
                        title="Edit"
                        src="../../../assets/button_edit.png"
                        @click="handleUpdate(guest)"
                      />

                      <img
                        class="icon-confirm btn-wrong"
                        alt="remove"
                        title="Remove"
                        src="../../../assets/button_wrong.png"
                        @click="handleDelete(guest.id)"
                      />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <resize-observer @notify="handleResize" />
          </div>
        </div>
      </div>
    </div>

    <form class="guests__add-item row mt-3" @submit.prevent="handleSubmit">
      <div class="col-12 col-sm-5 mb-4 mb-sm-0">
        <input
          type="text"
          placeholder="Insert name"
          v-model="user.name"
          required
        />
      </div>
      <div class="col-12 col-sm-5 mb-4 mb-sm-0">
        <input
          type="text"
          placeholder="Insert comment"
          v-model="user.text"
          required
        />
      </div>
      <div class="col-12 col-sm-2 mb-4 mb-sm-0">
        <button type="submit" class="icon mx-auto d-block">
          <v-icon name="fa-plus-square" scale="2.4" fill="#583b75" />
        </button>
      </div>
    </form>
    <bp-delete-modal
      :callback="deleteGuest"
      :showModal="showModal"
      :close="close"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { guestsActionsTypes } from '@/store/modules/guests'
import BpDeleteModal from '../Modals/ConfirmDeleteModal.vue'
const { addGuest, updateGuest, deleteGuest } = guestsActionsTypes
export default {
  name: 'BpGuests',
  components: { BpDeleteModal },
  computed: {
    ...mapState({ guests: state => state.guests.guests }),
    countGuests() {
      return this.guests.length
    },
  },
  data() {
    return {
      updateGuestID: null,
      user: {
        name: '',
        text: '',
      },
      updated: {
        name: '',
        text: '',
      },
      currentGuest: null,
      showModal: false,
      heightBoxInfo: null,
    }
  },
  methods: {
    handleResize({ height }) {
      this.heightBoxInfo = height
      console.log(this.heightBoxInfo)
    },
    handleSubmit() {
      this.$store.dispatch(addGuest, this.user).then(() => {
        this.user = { name: '', text: '' }
      })
    },
    handleUpdate({ id, name, text }) {
      this.updateGuestID = id
      this.updated.name = name
      this.updated.text = text
    },
    handleSave({ id }) {
      const data = {
        id,
        user: this.updated,
      }
      this.$store.dispatch(updateGuest, data).then(() => {
        this.updateGuestID = null
      })
    },
    handleDelete(id) {
      this.currentGuest = id
      this.showModal = true
    },
    deleteGuest() {
      this.$store
        .dispatch(deleteGuest, this.currentGuest)
        .then(() => {
          this.currentGuest = null
          this.close()
        })
        .catch(Error)
    },
    close() {
      this.showModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.container.guests {
  text-align: left;
  word-break: break-word;
}
.guests__scroll {
  height: 380px;
  overflow-y: auto;
}
.guests__scroll ul {
  margin: 0;
}
.guests__scroll--isScroll {
  margin-right: -15px;
}
.guests__title-head {
  font-size: 18px;
  color: rgba(70, 70, 69, 0.5);
  font-weight: bold;
}
.guest__controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  img {
    width: 50%;
    max-width: 48px;
    height: auto;
    cursor: pointer;
  }
}
.container {
  h3 {
    font-size: 26px;
    color: #583b75;
    padding: 5% 0;
  }
  input {
    width: 100%;
    padding: 2%;
    border: 1px solid rgba(70, 70, 69, 0.3);
    border-radius: 2px;
    &::placeholder {
      color: #a7a7a7;
    }
    .update-field-input {
      display: flex;
      width: 100%;
    }
  }
  .icon {
    display: flex;
    align-items: flex-start;
  }
  .save {
    padding: 1% 5%;
    margin: 3% auto;
    border: 1px solid rgba(88, 59, 117, 0.33);
    box-shadow: 5px 5px 5px rgba(88, 59, 117, 0.3);
    border-radius: 2px;
    text-align: center;
    &:hover {
      background-color: #583b75;
      color: #fff;
    }
  }
}
</style>
