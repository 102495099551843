<template>
  <div>
    <h5>You have successfully Registered!</h5>
    <p>
      We have E-Mailed your activation token for verifying this account. You
      will need to verify your account.
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authActionTypes } from '@/store/modules/auth'
import { notificationMutationsTypes } from '@/store/modules/notificationModals'
import { loginModalsMutationTypes } from '@/store/modules/loginModals'
const { showModals } = notificationMutationsTypes
const { updateLoginProcces } = loginModalsMutationTypes
const { confirmRegister } = authActionTypes
export default {
  name: 'BpForgotForm',
  props: ['closeModal', 'toggleModalType'],
  data() {
    return {
      code: '',
      error: '',
      message: '',
    }
  },

  computed: {
    ...mapState({
      isSubmitting: state => state.auth.isSubmitting,
      validationErrors: state => state.auth.validationErrors,
    }),
  },
  methods: {
    handleSubmit() {
      this.$store.dispatch(confirmRegister, this.code).then(res => {
        if (!res.success) {
          this.error = res.message
        }

        const modalProps = {
          isShown: true,
          procces: 'done',
          message: res.message,
        }
        this.$store.commit(showModals, modalProps)
        this.$store.commit(updateLoginProcces, 'singIn')
        this.error = ''
      })
      /* .then(() => { */
      /*   this.$store.dispatch(getCurrentUser) */
      /*   this.$store.commit(quizzesMutationsTypes.toggleModals) */
      /* }) */
    },
    close() {
      this.$emit('close')
    },
  },
  watch: {
    validationErrors(value) {
      if (!value) {
        return
      }
      this.error = value
    },
  },
}
</script>

<style lang="scss" scoped>
div {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}
p {
  width: 80%;
  text-align: center;
  color: #9b9b9b;
  font-size: 16px;
}
h5 {
  width: 90%;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: #000000;
  margin: 10% auto;
}

form {
  width: 70%;

  small {
    color: red;
    text-align: left;
    padding: 1% auto;
    &.message {
      color: green;
    }
  }

  a {
    margin: 4% auto;
    font-size: 16px;
    text-align: center;
    text-decoration-line: underline;

    color: #9b9b9b;
  }

  input {
    width: 90%;
    padding: 4%;
    border: 1px solid rgba(70, 70, 69, 0.3);
    border-radius: 2px;
    &::placeholder {
      color: #a7a7a7;
    }
    &:last-child {
      background-image: url('../../../assets/eye.png');
      background-repeat: no-repeat;
      background-position: 98% 50%;
    }
  }
}

p > a {
  font-weight: 500;
  font-size: 16px;
  text-decoration-line: underline;
  color: #583b75;
}

a {
  font-size: 14px;
  text-align: center;
  text-decoration-line: underline;
  color: #583b75;
}
button {
  margin-top: 35%;
  margin-bottom: 5%;
  background: #583b75;
  color: #ffffff;
  padding: 3% 18%;

  box-shadow: 0px 10px 10px rgba(88, 59, 117, 0.3);

  font-weight: 600;
  font-size: 16px;
  text-align: center;
  border-radius: 2px;
}
</style>
