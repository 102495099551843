<template>
  <main>
    <div class="modal__place">
      <transition name="modal__place">
        <div class="modal__place-mask" @click="close">
          <div class="modal__place-container" @click.stop>
            <div class="modal__place-header">
              <span @click="close" class="modal__place-close">&#x2715;</span>
            </div>

            <div class="message-body">
              <div class="message-body-head">
                <span
                  >We are sorry for the passing away of {{ user.name }}</span
                >
              </div>
              <div class="message-body-description">
                <span
                  >In order to get access to the wishes First Name Last Name has
                  left behind, you must be able to show a copy of the death
                  certificate. Once you have that, please contact us on
                  telephone +123456789123 or by e-mail wishes@efter.co.uk.</span
                >
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </main>
</template>

<script>
export default {
  name: 'sorryMessage',
  props: ['close', 'user'],
}
</script>

<style lang="scss" scoped>
.modal__place {
  .modal__place-mask {
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
  }

  .modal__place-container {
    width: 940px;
    height: 600px;
    max-width: 60%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;

    position: relative;

    .modal__place-header {
      width: 100%;
      margin: 3% 0;
      font-weight: 500;
      font-size: 24px;
      text-align: center;
    }
    .modal__place-close {
      position: absolute;
      top: 10px;
      right: 13px;
      cursor: pointer;
    }

    .message-body {
      width: 50%;
      margin: 0 auto;

      &-head {
        margin-bottom: 5%;
        span {
          font-size: 30px;
        }
      }

      &-description {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }
}
</style>
