<template>
  <main>
    <div class="modal__place">
      <transition name="modal__place">
        <div class="modal__place-mask" @click="close">
          <div class="modal__place-container" @click.stop>
            <div class="modal__place-header">
              <span @click="close" class="modal__place-close">&#x2715;</span>
            </div>
            <div class="message-body">
                <h3>Change of status?</h3>
                <div class="question">
                    <span>Would you like to withdraw from being an Executor?</span>
                </div>
                <div class="description">
                    <span>If it is the case that the person you have an obligation for has passad away, than click the button below to request the access to the last wishes of that person!</span>
                </div>
                <div class="btn-block">
                    <div @click="withdraw">
                        <input type="button" value="Withdraw as Executor">
                    </div>
                    <div @click="request">
                        <input type="button" value="Request access">
                    </div>
                </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </main>
</template>

<script>
export default {
    name: 'changeStatus',
    props: ['close'],
    methods: {
        withdraw() {
            this.$emit('withdraw', true);
        },
        request() {
            this.$emit('sendRequest', 'sorryMessage');
        },
    },
}
</script>

<style lang="scss" scoped>
.modal__place {
  .modal__place-mask {
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
  }

  .modal__place-container {
    width: 940px;
    height: 600px;
    max-width: 60%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;

    position: relative;

    .modal__place-header {
      width: 100%;
      margin: 3% 0;
      font-weight: 500;
      font-size: 24px;
      text-align: center;
    }
    .modal__place-close {
      position: absolute;
      top: 10px;
      right: 13px;
      cursor: pointer;
    }

    .message-body {
        width: 50%;
        margin: 0 auto;

        h3 {
            font-size: 30px;
        }

        .question {
            margin: 5% 0;
        }

        .description {
            margin-bottom: 7%;
        }

        .btn-block {
            display: flex;
            justify-content: space-between;
            align-items: center;

            div {
                width: 45%;
                background: #583B75;
                box-shadow: 0px 7px 14px rgba(88, 59, 117, 0.2);
                border-radius: 2px;

                &:hover {
                    cursor: pointer;
                }

                input {
                    color: #ffff;
                    padding: 5%;
                }
            }
        }
    }
  }
}
</style>