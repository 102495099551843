<template>
  <main class="container">
    <div class="sideSocialBar">
      <div class="sideSocialBar-item">
        <a @click.prevent="">
          <img
            src="./../../assets/Articles/social/icons/facebook.png"
            alt="facebook"
          />
        </a>
      </div>
      <div class="sideSocialBar-item">
        <a @click.prevent="">
          <img
            src="./../../assets/Articles/social/icons/instagram.png"
            alt="instagram"
          />
        </a>
      </div>
      <div class="sideSocialBar-item">
        <a @click.prevent="">
          <img
            src="./../../assets/Articles/social/icons/twitter.png"
            alt="twitter"
          />
        </a>
      </div>
    </div>
    <section class="intro">
      <img src="./../../assets/Articles/social/banner.png" alt="banner" />
    </section>
    <h6>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget
      faucibus urna, eget dictum risus.
    </h6>
    <section class="paragraphs">
      <p v-for="(paragraph, index) in paragraphs" :key="index">
        {{ paragraph }}
      </p>
    </section>
    <section class="list">
      <h6>List of further relevant reading:</h6>
      <ul>
        <li>
          <a @click.prevent=""
            >Manually handled links to other websites and articles</a
          >
        </li>
        <li>
          <a @click.prevent=""
            >Manually handled links to other websites and articles</a
          >
        </li>
        <li>
          <a @click.prevent=""
            >Manually handled links to other websites and articles</a
          >
        </li>
      </ul>
    </section>
    <bp-usp />
    <bp-sign-up-form :closeModal="closeModal" />
  </main>
</template>

<script>
import BpUsp from './../Usp.vue'
import bpSignUpForm from '../Forms/SignUpForm.vue'
export default {
  name: 'BpArticleSocial',
  components: { BpUsp, bpSignUpForm },
  data() {
    return {
      paragraphs: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget faucibus urna, eget dictum risus. Sed nec arcu at enim finibus congue eu eget erat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec ultricies porta sem quis ultricies. Pellentesque risus sapien, ullamcorper quis tincidunt eu, venenatis et sem. Donec tristique dictum magna, ac pellentesque nulla iaculis id. Nullam semper ligula id purus pulvinar, convallis eleifend eros convallis.',
        'Cras augue arcu, egestas eget leo quis, luctus rutrum urna. In bibendum sapien nisl, ac tincidunt odio blandit eget. Sed convallis magna maximus semper fermentum. Curabitur tempus rhoncus purus iaculis accumsan. Proin ut est nec nisi cursus rutrum et vel purus. Quisque id arcu blandit, ornare erat in, vestibulum metus. Maecenas vitae risus urna. Vestibulum eu consectetur ligula. Etiam cursus augue eget ex blandit, sed vehicula ligula venenatis.',
        'Sed dictum, mi sit amet tempus iaculis, ligula ante aliquam tellus, sit amet fermentum ligula ligula nec justo. Donec eget interdum nisl. Praesent aliquet libero nec sem ornare, non vehicula orci lobortis. Vivamus consequat egestas orci, ac molestie nibh finibus vitae. Fusce nisi dui, facilisis pretium mi eget, dictum viverra magna. Sed dignissim vel diam ut posuere. Cras vitae massa et tortor tempus sagittis. Sed eget tortor sit amet nisi malesuada varius at ut tortor. Donec dignissim orci ac fermentum facilisis. Morbi et sagittis nisi. Phasellus rutrum posuere ligula et maximus. Sed condimentum aliquam odio vel venenatis. Proin nec luctus magna. Mauris efficitur auctor iaculis. Ut ac lacus at libero porttitor viverra non in orci.',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget faucibus urna, eget dictum risus. Sed nec arcu at enim finibus congue eu eget erat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec ultricies porta sem quis ultricies. Pellentesque risus sapien, ullamcorper quis tincidunt eu, venenatis et sem. Donec tristique dictum magna, ac pellentesque nulla iaculis id. Nullam semper ligula id purus pulvinar, convallis eleifend eros convallis.',
        'Cras augue arcu, egestas eget leo quis, luctus rutrum urna. In bibendum sapien nisl, ac tincidunt odio blandit eget. Sed convallis magna maximus semper fermentum. Curabitur tempus rhoncus purus iaculis accumsan. Proin ut est nec nisi cursus rutrum et vel purus. Quisque id arcu blandit, ornare erat in, vestibulum metus. Maecenas vitae risus urna. Vestibulum eu consectetur ligula. Etiam cursus augue eget ex blandit, sed vehicula ligula venenatis.',
        'Sed dictum, mi sit amet tempus iaculis, ligula ante aliquam tellus, sit amet fermentum ligula ligula nec justo. Donec eget interdum nisl. Praesent aliquet libero nec sem ornare, non vehicula orci lobortis. Vivamus consequat egestas orci, ac molestie nibh finibus vitae. Fusce nisi dui, facilisis pretium mi eget, dictum viverra magna. Sed dignissim vel diam ut posuere. Cras vitae massa et tortor tempus sagittis. Sed eget tortor sit amet nisi malesuada varius at ut tortor. Donec dignissim orci ac fermentum facilisis. Morbi et sagittis nisi. Phasellus rutrum posuere ligula et maximus. Sed condimentum aliquam odio vel venenatis. Proin nec luctus magna. Mauris efficitur auctor iaculis. Ut ac lacus at libero porttitor viverra non in orci.',
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 75%;
  max-width: 1920px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
}
h6 {
  margin-bottom: 5%;
  font-size: 28px;
  text-align: left;
  font-weight: 500;
}
.intro {
  margin: 2% 0 7% 0;
}
.list {
  align-self: flex-start;
  margin-bottom: 15%;
  margin-top: 10%;
  h6 {
    font-size: 16px;
    margin: 7% 0 2% 0;
    font-weight: 600;
  }
  a {
    text-decoration: underline;
    font-size: 14px;
  }
  li:hover {
    cursor: pointer;
    color: blue;
  }
}

.sideSocialBar {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 25%;

  width: 2.5%;

  .sideSocialBar-item {
    width: 100%;
    padding: 25%;

    background: #ffffff;
    box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.25);
    border-radius: 2px;

    margin-bottom: 15%;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
