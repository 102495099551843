import { render, staticRenderFns } from "./Socials.vue?vue&type=template&id=ad2aa020&scoped=true&"
import script from "./Socials.vue?vue&type=script&lang=js&"
export * from "./Socials.vue?vue&type=script&lang=js&"
import style0 from "./Socials.vue?vue&type=style&index=0&id=ad2aa020&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad2aa020",
  null
  
)

export default component.exports