import axios from '@/api/axios'

const register = credentials => {
  return axios.post('/register', credentials)
}

const login = credentials => {
  return axios.post('/login', credentials)
}

const logout = () => {
  return axios.get('/user/logout')
}

const changePersonalInfo = ({ id, data}) => {
  return axios.post(`/user/personal-info/${id}`, data)
}

const removeUser = id => {
  return axios.get(`/user/destroy/${id}`)
}

const getCurrentUser = () => {
  return axios.post('/details')
}

const forgotPass = credentials => {
  return axios.post('/password-reset-request', credentials)
}

const changePass = credentials => {
  return axios.post('/change/password', credentials)
}

const confirmRegister = code => {
  return axios.get(`/user/activation/${code}`)
}

const checkConfirmByMail = ({ token, userId }) => {
  return axios.get(`/user/confirm/${token}/${userId}`)
}

const registerExecutor = credentials => {
  return axios.post('/register-executor', credentials)
}

const checkConfirmExecutor = ({ token, userId }) => {
  return axios.get(`/user/confirm-executor/${token}/${userId}`)
}

const loginExecutor = credentials => {
  return axios.post('/login-executor', credentials)
}

const acceptExecutor = ({ id, value }) => {
  return axios.get(`/executor/accept/${id}?accept=${value}`)
}

const removeExecutor = id => {
  return axios.post(`/executor/destroy/${id}`)
}

const getExecutorUser = () => {
  return axios.post(`/get-executor-user`)
}
export default {
  register,
  login,
  logout,
  removeUser,
  getCurrentUser,
  forgotPass,
  changePass,
  confirmRegister,
  checkConfirmByMail,
  checkConfirmExecutor,
  registerExecutor,
  loginExecutor,
  acceptExecutor,
  removeExecutor,
  getExecutorUser,
  changePersonalInfo,
}
