<template>
  <section class="banner mb-5">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="banner__header">
          <h4 class="banner__header-title">{{ title || bannerTitle }}</h4>
          <p class="banner__header-subtitle">{{ subtitle || bannerSubTitle }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BpBanner',
  props: ['title', 'subtitle'],
  data() {
    return {
      bannerTitle: `Practical support
                    when someone dies`,
      bannerSubTitle: `It can be difficult to figure out what to do first in the event of a
        death. Our free planning tool can help guide you through each one.
        First, enter some details about what you have in mind. It doesn’t matter
        if you don’t know all the details yet. We can then direct you to the
        right funeral directors and services so you can compare and choose. At
        every stage, you have all the information you need to hand and our tool
        will help you stay on top of your tasks.`,
    }
  },
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  background: url('../assets/banner.png') no-repeat 100% 50%;
  background-size: cover;

  .banner__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 11% 13%;
    color: #ffffff;
    text-align: left;
  }
  .banner__header-title {
    font-weight: bold;
    font-size: 38px;
    text-transform: uppercase;
  }

  .banner__header-subtitle {
    padding-top: 2%;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }
}
</style>
