import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'

import '@/styles/main.scss'

import Vue from 'vue'
import VueRouter from 'vue-router'

import PlanningPage from '../views/Pages/PlanningPage.vue'
import MainPage from '../views/Pages/ArrangingPage.vue'
import newMainPage from '../views/Pages/MainPage.vue'
import Legal from '../views/Pages/LegalPage.vue'
import About from '../views/Pages/AboutPage.vue'
// import Second from '../views/Second.vue'
// import Third from '../views/Privacy.vue'
import FaqPage from '../views/Pages/FaqPage.vue'
import MainModal from '../components/UserProfile/UserProfileMain.vue'
import ArticleInspireMe from './../components/Articles/ArticleInspireMe.vue'
import ArticleArea from './../components/Articles/ArticleArea'
import ArticleSocial from './../components/Articles/ArticleSocial'
import LandingPage_1 from '../views/Landings/LandingPageMain.vue'
// import SingInForm from '@/components/Modals/Login/SingInForm'
// import ForgotForm from '@/components/Modals/Login/ForgotForm'
// import SingUpForm from '@/components/SignUpForm'
// import DoneMainModal from '@/components/Modals/NotificationModal.vue'
// import UserProfile from '@/views/UserProfile.vue'
// import Confirm from '@/views/ConfirmDefault.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: newMainPage,
  },
  {
    path: '/pre-planning-funeral',
    name: 'planning',
    component: PlanningPage,
  },
  {
    path: '/arranging-a-funeral',
    name: 'funeral',
    component: MainPage,
  },
  {
    path: '/legal',
    name: 'Legal',
    component: Legal,
  },
  {
    path: '/faq',
    name: 'FaqPage',
    component: FaqPage,
  },
  {
    path: '/about-us',
    name: 'About',
    component: About,
  },
  {
    path: '/landing-page-1',
    name: 'Landing1',
    component: LandingPage_1,
  },
  {
    path: '/arranging-a-funeral/1',
    name: 'ArticleInspireMe',
    component: ArticleInspireMe,
  },
  {
    path: '/arranging-a-funeral/2',
    name: 'ArticleArea',
    component: ArticleArea,
  },
  {
    path: '/arranging-a-funeral/3',
    name: 'ArticleSocial',
    component: ArticleSocial,
  },
  {
    path: '/main-modal',
    name: 'main-modal',
    component: MainModal,
  },
  // {
  //   path: '/user-profile',
  //   name: 'user-profile',
  //   component: () =>
  //     import(/* webpackChunkName: "UserProfile" */ '../views/UserProfile.vue'),
  // },
  {
    path: '/died-profile',
    name: 'died-profile',
    component: () =>
      import(/* webpackChunkName: "UserProfile" */ '../views/Profiles/DiedProfile.vue'),
  },
  // {
  //   path: '/executor-profile',
  //   name: 'executor-profile',
  //   component: () =>
  //     import(/* webpackChunkName: "UserProfile" */ '../views/DiedProfile.vue'),
  // },
  {
    path: '/confirm-default',
    name: 'confirm-default',
    component: () =>
      import(/* webpackChunkName: "Confirm" */ '../views/Confirms/ConfirmDefault.vue'),
  },
  {
    path: '/confirm-executor',
    name: 'confirm-executor',
    component: () =>
      import(
        /* webpackChunkName: "ConfirmExecutor" */ '../views/Confirms/ConfirmExecutor.vue'
      ),
  },
  {
    path: '/confirm-died',
    name: 'confirm-died',
    component: () =>
      import(/* webpackChunkName: "ConfirmDead" */ '../views/Confirms/ConfirmDead.vue'),
  },
]
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: () => ({ y: 0 }),
})

export default router
