import contentsApi from '@/api/contents'

const state = {
  errors: null,
  contents: [],
  isLoading: false,
}

export const footerMutationsTypes = {
  getContentsStart: 'footer/getStart',
  getContentsSuccess: 'footer/getSuccess',
  getContentsFailure: 'footer/getFailure',
}

const mutations = {
  // user detail
  [footerMutationsTypes.getContentsStart](state) {
    state.isLoading = true
  },
  [footerMutationsTypes.getContentsSuccess](state, payload) {
    state.isLoading = false
    state.contents = payload
    state.errors = null
  },
  [footerMutationsTypes.getContentsFailure](state, payload) {
    state.isLoading = false
    state.contents = null
    state.errors = payload
  },
}

export const footerActionsTypes = {
  getContents: 'contents/getContents',
}

const actions = {
  [footerActionsTypes.getContents](context) {
    return new Promise(resolve => {
      context.commit(footerMutationsTypes.getContentsStart)
      contentsApi
        .getContents()
        .then(response => {
          context.commit(footerMutationsTypes.getContentsSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          context.commit(footerMutationsTypes.getContentsFailure)
        })
    })
  },
}

export const footerGettersTypes = {
  contents: 'footer/quizzes',
}

const getters = {
  [footerGettersTypes.contents]: state => {
    return state.contents
  },
  // [footerGettersTypes.currentContent]: state => {
  //   return state.contents.items.filter(q => q.quizzID === state.activeQuizID)[0]
  // },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
