<template>
  <main class="step-third steps">
    <div class="modal__place">
      <transition name="modal__place">
        <div class="modal__place-mask" @click="close">
          <Loader v-show="loading"/>
          <div class="modal__place-container" @click.stop>
            <div class="steps__grid">
              <h4 class="modal__place-header">
                <span
                    v-if="question"
                    class="modal__place-header-title"
                >
                  {{ question }}
                </span>
                <span @click="close" class="modal__place-close">&#x2715;</span>
              </h4>
              <div class="steps__container-fluid">
                <div class="col-12 mb-3">
                  <LineProgress :width="progressBarWidth"/>
                </div>
              </div>
              <div class="steps__wrap">
                <div class="steps__container-fluid">
                  <div class="modal__place-body">
                    <form class="modal__place-form" @submit.prevent="">
                      <fieldset :class="['modal__place-field', { active: active1 }]">
                        <legend>In the UK</legend>
                        <input
                            class="modal__place-input"
                            type="search"
                            placeholder="Enter a postcode, town or city"
                            @focus="
                            active1 = true
                            active2 = false
                        "
                            @input="activateBtn()"
                            ref="input1"
                        />
                      </fieldset>
                      <fieldset style="display: none;" :class="['modal__place-field', { active: active2 }]">
                        <legend>In another country</legend>
                        <input
                            class="modal__place-input"
                            type="search"
                            placeholder="Enter a postcode, town or city"
                            @focus="
                            active2 = true
                            active1 = false
                        "
                            @input="activateBtn()"
                            ref="input2"
                        />
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="modal__footer-btn-nav">
                  <img
                      id="arrowLeft"
                      @click="moveBack"
                      src="./../../assets/arrow.png"
                      alt="arrowLeft"
                  />
                </div>
                <button
                    class="btn-chose"
                    @click="handleChoose"
                    :class="['modal__place-button', {'modal__place-button-active': activeBtn}]"
                    :disabled="!activeBtn"
                >
                  Choose
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </main>
</template>

<script>
import LineProgress from '../QiuzModals/components/LineProgress.vue';
import Loader from './../Loader/Loader.vue'

export default {
  name: 'StepThird',
  props: ['loading', 'progressBarWidth', 'close', 'cards'],
  components: {
    LineProgress,
    Loader,
  },
  data() {
    return {
      active1: true,
      active2: false,
      activeBtn: false,
      question: 'Where do you want the funeral to take place?',
    }
  },
  watch: {
    active1(val) {
      if (!val) {
        this.$refs['input1'].value = '';
        this.activeBtn = false;
      }
    },
    active2(val) {
      if (!val) {
        this.$refs['input2'].value = '';
        this.activeBtn = false;
      }
    },
  },
  methods: {
    activateBtn() {
      if (this.$refs['input1'].value.length || this.$refs['input2'].value.length) {
        this.activeBtn = true;
      } else {
        this.activeBtn = false;
      }
    },
    moveBack() {
      if (this.$refs['input1'].value.length || this.$refs['input2'].value.length) {
        const answ = {};
        answ.status = 'second';
        answ.direaction = 'back';
        answ.type = 'Location';
        answ.title = this.$refs['input1'].value;
        this.$emit('setAnswer', answ);
      } else {
        const answ = {};
        answ.status = 'second';
        answ.direaction = 'back';
        this.$emit('setAnswer', answ);
      }
    },
    handleChoose() {
      if (this.active1) {
        window.scrollTo(0, 0);
        const answ = {};
        answ.status = 'completed';
        answ.type = 'Location';
        answ.title = this.$refs['input1'].value;
        this.$emit('setAnswer', answ);
      }

      if (this.active2) {
        window.scrollTo(0, 0);
        const answ = {};
        answ.status = 'completed';
        answ.type = 'Location';
        answ.title = this.$refs['input2'].value;
        this.$emit('setAnswer', answ);
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.modal__place {
  .modal__place-container {
    .modal__place-body {

      .modal__place-form {
        margin: 5% auto;

        display: flex;
        align-items: center;
        justify-content: space-around;

        legend {
          text-align: left;
          font-size: 20px;
          color: #c4c4c4;
        }

        .active legend {
          color: #000000;
        }
      }

      .modal__place-field {
        width: 100%;
        margin: 0 auto;
        padding: 2%;
      }

      .modal__place-input {
        width: 100%;
        padding: 4%;
        margin-bottom: 10%;

        &::placeholder {
          color: #ffffff;
        }
      }

      .active .modal__place-input {
        border: 1px solid rgba(70, 70, 69, 0.3);
        border-radius: 2px;

        &::placeholder {
          color: #a7a7a7;
        }
      }

      .modal__place-button {
        display: block;
        margin: 7% auto 0 auto;
        background: #fff;
        color: #000;
        padding: 15px 80px;
        box-shadow: 0 10px 60px rgba(88, 59, 117, 0.3);
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        border-radius: 2px;

        transition: all 0.5s;
      }

      .modal__place-button-active {
        background: #583b75;
        color: #ffffff;
      }

      .modal-enter {
        opacity: 0;
      }

      .modal-leave-active {
        opacity: 0;
      }

      .modal-enter .modal-container,
      .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  }
}
</style>
