<template>
  <div>
    <h5>My account</h5>

    <form @submit.prevent="handleSubmit">
      <input
        class="modal__input"
        type="email"
        placeholder="Email Address"
        v-model="user.email"
        required
      />
      <small
        v-show="!!errors.email"
        v-for="error in errors.email"
        :key="error"
        >{{ error }}</small
      >

      <input
        class="modal__input"
        type="password"
        placeholder="Password"
        v-model="user.password"
        required
      />
      <small
        v-show="!!errors.password"
        v-for="error in errors.password"
        :key="error"
        >{{ error }}</small
      >

      <button type="submit" class="modal__btn d-block mx-auto" :disabled="isSubmitting">Login</button>
      <small v-show="!!error">{{ error }}</small>
    </form>

    <a @click.prevent="toggleModalType('forgot')" class="modal__link" href="#"
      >Forgotten Password?</a
    >
    <hr />
    <p>
      Don’t have an account
      <a href="#" @click.prevent="toggleModalType('signUp')">Register now</a>
    </p>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'
import { authActionTypes } from '@/store/modules/auth'

export default {
  name: 'BpSingInForm',
  props: ['closeModal', 'toggleModalType'],

  data() {
    return {
      error: this.validationErrors,
      user: {
        password: '',
        email: '',
      },
      errors: {
        email: '',
        password: '',
      },
    }
  },
  computed: {
    ...mapState({
      isSubmitting: ({ auth }) => auth.isSubmitting,
      validationErrors: ({ auth }) => auth.validationErrors,
    }),

    ...mapMutations({
      /* updateLoginProcces: loginModalsMutationsTypes.updateLoginProcces, */
    }),

    ...mapActions({
      /* updateProcces: modalsActionTypes.updateProcces, */
    }),
  },
  methods: {
    toggleModal() {
      /* this.updateProcces(str) */
      /* this.$store.dispatch(loginModalsMutationsTypes.updateLoginProcces, { */
      /*   st: 'forgot', */
      /* }) */
    },
    handleSubmit() {
      this.$store
        .dispatch(authActionTypes.login, {
          email: this.user.email,
          password: this.user.password,
        })
        .then(() => {
          this.$store.dispatch(authActionTypes.getCurrentUser)
          this.closeModal()
        })
    },
    close() {
      this.$emit('close')
    },
  },
  watch: {
    validationErrors(value) {
      console.log('value ->', value) // eslint-disable-line no-console
      if (!value) {
        return
      }
      this.error = 'Login or Password is wrong'

      const { email, password } = value
      this.errors.email = email
      this.errors.password = password
    },
  },
}
</script>

<style lang="scss" scoped>
div {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h5 {
  width: 90%;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: #000000;
  margin: 10% auto;
}

small {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  width: 100%;
  text-align: left;
}
form {
  a {
    margin: 4% auto;
    font-size: 16px;
    text-align: center;
    text-decoration-line: underline;

    color: #9b9b9b;
  }

  input {
    width: 100%;
    padding: 4%;
    margin-bottom: 10%;
    border: 1px solid rgba(70, 70, 69, 0.3);
    border-radius: 2px;
    &::placeholder {
      color: #a7a7a7;
    }
    &:last-child {
      background-image: url('../../../assets/eye.png');
      background-repeat: no-repeat;
      background-position: 98% 50%;
    }
  }
}
p {
  font-size: 16px;
}

p > a {
  font-weight: 500;
  font-size: 16px;
  text-decoration-line: underline;
  color: #583b75;
}

a {
  font-size: 14px;
  text-align: center;
  text-decoration-line: underline;
  color: #583b75;
}

button {
  margin: 5% auto;
  background: #583b75;
  color: #ffffff;
  padding: 3% 18%;

  box-shadow: 0px 10px 10px rgba(88, 59, 117, 0.3);

  font-weight: 600;
  font-size: 16px;
  text-align: center;
  border-radius: 2px;
}
</style>
