<template>
  <div>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis doloribus
    nemo animi molestiae in repudiandae quae quisquam? Veniam obcaecati maiores
    vel culpa consequuntur sunt, exercitationem vero dolorum itaque quisquam!
    Nihil ad perferendis corporis consectetur at culpa nobis accusamus maxime
    fugit voluptates? Veniam eligendi ullam sed animi alias voluptate pariatur
    non exercitationem eaque quidem maiores culpa iusto nesciunt molestias est,
    magni suscipit illum consequatur dolor neque rerum quaerat illo
  </div>
</template>

<script>
export default {
  name: 'BpLorem',
}
</script>

<style lang="scss" scoped>
div {
  width: 90%;
  height: 90%;
  display: flex;
  margin: 3% auto;
}
</style>