import axios from '@/api/axios'

const getToDo = id => {
  return axios.get(`/notes?user_id=${id}`)
}

const updateToDo = notes => {
  return axios.post(`/notes`, notes)
}

const getFilledToDo = ids => {
  return axios.post(`/get-filled-modals`, ids)
}
export default {
  getFilledToDo,
  updateToDo,
  getToDo,
}
