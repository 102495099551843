import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueResize from 'vue-resize'
import './assets/scss/main.scss'

import 'vue-resize/dist/vue-resize.css'
Vue.use(VueResize)

import OhVueIcon from 'oh-vue-icons'
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
  FaWhatsappSquare,
  FaCheckCircle,
  FaUserTimes,
  FaMinusSquare,
  FaPlusSquare,
  FaAngleDown,
  FaAngleUp,
} from 'oh-vue-icons/icons'

import { BootstrapVue } from 'bootstrap-vue'

Vue.use(BootstrapVue)
OhVueIcon.add([
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
  FaWhatsappSquare,
  FaCheckCircle,
  FaUserTimes,
  FaMinusSquare,
  FaPlusSquare,
  FaAngleDown,
  FaAngleUp,
])
Vue.component('v-icon', OhVueIcon)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
