<template>
  <main class="step-two steps">
    <div class="modal__place">
      <transition name="modal__place">
        <div class="modal__place-mask" @click="close">
          <div class="modal__place-container" @click.stop>
            <div class="steps__grid">
              <h4 class="modal__place-header">
                <span
                    v-if="cards.quizzDesc"
                    class="modal__place-header-title"
                >
                  {{ cards.quizzDesc }}
                </span>
                <span @click="close" class="modal__place-close">&#x2715;</span>
              </h4>
              <div class="steps__container-fluid">
                <div class="col-12 mb-3">
                  <LineProgress :width="progressBarWidth"/>
                </div>
              </div>
              <div class="steps__wrap">
                <div class="steps__container-fluid">
                  <div class="cards col-12">
                    <div class="box row">
                      <div
                          v-for="(card, i) in cards.quizzData.options"
                          :key="i"
                          class="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0"
                      >
                        <div
                            class="card h-100"
                            :class="{ active: card.optionsID == activeID }"
                            @click="handleClick(card.optionsID)"
                        >
                          <h5>{{ card.title }}</h5>
                          <p>{{ card.text }}</p>
                          <img class="mt-auto" :src="url + card.img" alt="img"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="modal__footer-btn-nav">
                  <img
                      id="arrowLeft"
                      @click="moveBack"
                      src="./../../assets/arrow.png"
                      alt="arrowLeft"
                  />
                  <img
                      @click="moveForward"
                      class="arrowRight"
                      src="./../../assets/arrow.png"
                      alt="arrowRight"
                  />
                </div>
                <button
                    class="btn-chose"
                    :class="{ active: isActive }"
                    @click="handleChoose"
                    :disabled="!isActive"
                >
                  Choose
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </main>
</template>

<script>
import LineProgress from '../QiuzModals/components/LineProgress.vue';

export default {
  name: 'StepOne',
  props: ['progressBarWidth', 'close', 'cards'],
  components: {
    LineProgress,
  },
  computed: {
    isActive() {
      return !!this.activeID
    },
  },
  data() {
    return {
      activeID: null,
      url: 'http://efter-b.pandateam.net/',
    }
  },
  methods: {
    moveBack() {
      if (!this.activeID) {
        const answ = {};
        answ.status = 'first';
        answ.direaction = 'back';
        this.$emit('setAnswer', answ);
      } else {
        const answ = this.cards.quizzData.options.find(c => c.optionsID === this.activeID)
        answ.typeCost = 'The ceremonies';
        answ.type = 'Type of Ceremony';
        answ.status = 'first';
        answ.direaction = 'back';
        this.$emit('setAnswer', answ);
      }
    },
    moveForward() {
      if (!this.activeID) {
        const answ = {};
        answ.status = 'third';
        answ.direaction = 'forward';
        this.$emit('setAnswer', answ);
      } else {
        const answ = this.cards.quizzData.options.find(c => c.optionsID === this.activeID)
        answ.typeCost = 'The ceremonies';
        answ.type = 'Type of Ceremony';
        answ.status = 'third';
        answ.direaction = 'forward';
        this.$emit('setAnswer', answ);
      }
    },
    handleClick(optionsID) {
      this.activeID = optionsID
    },
    handleChoose() {
      if (!this.activeID) {
        return
      }
      const answ = this.cards.quizzData.options.find(c => c.optionsID === this.activeID)
      answ.typeCost = 'The ceremonies';
      answ.type = 'Type of Ceremony';
      answ.status = 'third';
      this.$emit('setAnswer', answ);
    },
  },
}
</script>
