<template>
  <div>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis doloribus
    nemo animi molestiae in repudiandae quae quisquam? Veniam obcaecati maiores
    vel culpa consequuntur sunt, exercitationem vero dolorum itaque quisquam!
    Nihil ad perferendis corporis consectetur at culpa nobis accusamus maxime
    fugit voluptates? Veniam eligendi ullam sed animi alias voluptate pariatur
    non exercitationem eaque quidem maiores culpa iusto nesciunt molestias est,
    magni suscipit illum consequatur dolor neque rerum quaerat illo
    perspiciatis! Nobis atque aliquid praesentium commodi fugit itaque odit
    iusto doloremque alias veritatis amet sint sunt, ullam necessitatibus,
    accusantium, omnis labore delectus autem asperiores magnam nisi? Suscipit
    recusandae debitis consequatur reiciendis iusto at vitae harum sint quam
    quibusdam reprehenderit alias aliquid totam voluptate, officiis minima, quo
    delectus ut! Reiciendis aliquam, libero consequuntur dolore accusantium,
    natus porro asperiores, magni iusto adipisci voluptas cupiditate vel omnis
    saepe et tempora soluta molestiae earum sunt voluptate ratione ad. Hic quam
    inventore doloremque iste ipsam tenetur quos non. Eaque laborum ipsam
    laudantium, consequatur ipsa non ab, maiores quod reprehenderit officiis
    quae deserunt assumenda omnis iusto minus quis commodi debitis aut
    voluptates a! Esse inventore quod, deserunt necessitatibus non recusandae
    dignissimos earum quia? Eum ipsum, dolorum, non enim culpa praesentium
    aperiam quo nemo ullam dolores adipisci delectus quasi officiis ut
    reiciendis eligendi harum vero dolorem iusto! Fuga, sequi. Ducimus et
    quibusdam voluptate voluptas quis dolorum fugit quam incidunt enim est
    laboriosam possimus sunt a recusandae dolor, dolores voluptatem id deserunt
    aspernatur maxime blanditiis obcaecati soluta voluptatibus? Exercitationem
    non consectetur debitis officiis inventore quod ex enim eveniet voluptas,
    illum aut eaque saepe error ipsum cumque dignissimos ad accusamus atque
    dolorem nemo qui architecto iste voluptatem laborum! Nostrum commodi,
    dolorem optio amet id similique, fuga impedit quam soluta in quis
    accusantium reprehenderit libero corrupti hic incidunt repellat? Numquam
    odio necessitatibus inventore deleniti nesciunt? Assumenda nobis natus quas
    officia molestiae ducimus laudantium fugiat. Sequi consectetur eveniet
    facere a officiis odit vitae dolorem, optio eius aut harum voluptates odio
    animi obcaecati laborum. Amet dignissimos nihil accusamus voluptatum
    molestiae quaerat iure illo molestias corrupti recusandae dicta saepe
    aliquid possimus sunt iusto consequuntur sit, animi necessitatibus cum?
    Repellat est dolore nihil ipsam consequatur, explicabo at accusantium quidem
    iste. Omnis, mollitia. Praesentium facere reprehenderit sit velit possimus
    sed consectetur maiores voluptatibus odit ex labore quae, tempore debitis
    optio? Nostrum similique maxime accusamus quisquam hic totam nam quidem sit
    qui aliquam commodi incidunt culpa deleniti, provident numquam debitis
    tenetur, labore placeat doloremque eius quis aut at voluptatem fugiat!
    Dolorum et placeat odit quos ducimus quidem perferendis nostrum repellendus
    veritatis nam modi illo eveniet voluptates neque vitae deserunt harum, ipsa
    eligendi? Adipisci voluptas dicta velit quisquam, animi sunt nobis pariatur
    facilis a saepe voluptatibus deserunt, molestiae excepturi ex corrupti. Quas
    assumenda nisi velit doloribus eveniet distinctio possimus vitae accusamus
    amet aliquid autem sed rerum, corporis dolorem. Dolorum sapiente aperiam et,
    nemo accusantium sit vitae enim temporibus recusandae, sed adipisci
    obcaecati odio culpa accusamus quo illo fugit maiores. Quis eius eos
    dignissimos, quaerat officiis temporibus eveniet accusantium unde asperiores
    cum consequatur labore molestiae ullam, voluptatibus quo sunt dolor.
  </div>
</template>

<script>
export default {
  name: 'BpLorem',
}
</script>

<style lang="scss" scoped>
div {
  width: 90%;
  height: 90%;
  display: flex;
  margin: 0 auto;
}
</style>
