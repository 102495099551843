<template>
  <div class="wrapper" v-if="!isExecutorDeclined">
    <h3 :class="{ 'margin-btn': executorWithdrawn }">
      My Executor Obligations:
    </h3>
    <div class="executorList">
      <div class="executorList-lable">
        <h5>I am the Executor for:</h5>

        <p>
          Please note that you can only get access to the wishes you are
          responsible for after the person has passed away!
        </p>
      </div>
      <div class="executorList-list">
        <span>{{ executorUserName }} ({{ executorUserEmail }})</span>
        <button @click="toggleModal">Update status</button>
      </div>
    </div>

    <div class="obligation-footer" v-if="!hasQiuzPassed">
      <div>
        <div>
          <img
            src="./../../../assets/ExecutorStyleIcons/icon-info.png"
            alt="icon-info"
          />
        </div>
        <div>
          <span>Want to create your own MyWishes?</span>
        </div>
      </div>
      <div @click="toggleModal_C">
        <img
          src="./../../../assets/ExecutorStyleIcons/icon-add.png"
          alt="icon-add"
        />
      </div>
    </div>
    <component
      v-show="showModal"
      :is="currModal"
      :close="close"
      :user="user"
      @sendRequest="setRequestModal"
      @withdraw="withdraw"
    />

    <ConstructorModal v-if="isShown" :data="quizzes" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {
  userProfileGetterTypes,
  userProfileMutationsTypes,
} from '@/store/modules/userProfile'

import {
  quizzesMutationsTypes,
  quizzesGettersTypes,
} from '@/store/modules/quizzesModals'
import { authActionTypes } from '@/store/modules/auth'
import ConstructorModal from '../../QiuzModals/ConstructorModal.vue'

import changeStatus from './components/changeStatus.vue'
import sorryMessage from './components/sorryMessage.vue'

const { setExecutorWithdrawn } = userProfileMutationsTypes
const { toggleModals } = quizzesMutationsTypes
const { quizzes, answers } = quizzesGettersTypes
const { acceptExecutor, getExecutorUser } = authActionTypes

export default {
  name: 'Obligation',
  components: {
    changeStatus,
    sorryMessage,
    ConstructorModal,
  },
  data() {
    return {
      modals: ['changeStatus', 'sorryMessage'],
      showModal: false,
      currModal: '',
      check: false,
      user: { name: '', email: '', status: '' },
    }
  },
  mounted() {
    if (!this.$store.state.auth.executorUserInfo) {
      this.$store
        .dispatch(getExecutorUser)
        /* .then(res => { */
        /*   const { name, email, executor_status } = res */
        /*   this.user.name = name */
        /*   this.user.email = email */
        /*   this.user.status = executor_status */
        /* }) */
        .catch(console.error)
    }
  },
  computed: {
    ...mapGetters({
      executorWithdrawn: userProfileGetterTypes.getExecutorWithdrawn,
      quizzes,
      answers,
    }),
    ...mapState({
      isShown: state => state.quizzesModals.isShown,
      currentUser: state => state.auth.currentUser,
      executorUser: state => state.auth.executorUserInfo,
    }),
    isExecutorDeclined() {
      return this.executorUser?.executor_status === 'declined'
    },
    hasQiuzPassed() {
      return this.answers.length >= 7
    },
    executorUserName() {
      return this.executorUser?.name
    },
    executorUserEmail() {
      return this.executorUser?.email
    },
  },
  methods: {
    toggleModal_C() {
      this.$store.commit(toggleModals, true)
    },
    toggleModal() {
      this.showModal = !this.showModal
      this.currModal = 'changeStatus'
    },
    close() {
      this.showModal = false
      this.check = false
    },
    setRequestModal(data) {
      this.currModal = data
      this.updateExecutorStatus(true)
    },
    withdraw(data) {
      this.showModal = false
      this.$store.commit(setExecutorWithdrawn, data)
      this.updateExecutorStatus(false)
    },
    updateExecutorStatus(value) {
      this.$store
        .dispatch(acceptExecutor, {
          id: this.currentUser.id,
          value,
        })
        .catch(console.error)
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  margin: 2% auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  flex: 1 1 70%;

  h3 {
    font-size: 19px;
    margin-bottom: 5%;
  }

  .executorList {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 7%;

    &-lable {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      h5 {
        font-weight: bold;
      }
      p {
        margin: 5%;
        margin-left: 0;

        text-align: left;
      }
    }
    &-list {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      span {
        font-weight: bold;
        color: #945dc7;
      }
      &-item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        span {
          text-decoration: underline;
        }
      }
    }
  }

  button {
    /* width: 20%; */
    margin: 5%;
    margin-left: 0;
    border: 1px solid rgba(88, 59, 117, 0.33);
    box-shadow: 5px 5px 5px rgba(88, 59, 117, 0.3);
    border-radius: 2px;
    padding: 2%;
    text-align: center;
    &:hover {
      background-color: #583b75;
      color: #fff;
    }
  }

  .obligation-body {
    width: 100%;
    display: flex;
    justify-content: space-between;

    margin-bottom: 15%;

    &-item {
      width: 40%;
      height: 60px;

      &:first-child {
        width: 20%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }

      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      div:first-child {
        width: 50%;
      }
      div:last-child {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 2%;
        width: 50%;
      }
    }

    #myPlan {
      span {
        color: #a7a7a7;
      }
      input:hover {
        cursor: auto;
      }
    }
  }

  .obligation-footer {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div:first-child {
      width: 90%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      div:first-child {
        width: 10%;
        filter: drop-shadow(12px 12px 25px rgba(0, 0, 0, 0.5));
        margin-left: -1.5%;
      }
      div:last-child {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 90%;
      }
    }
    div:last-child {
      width: 10%;
      filter: drop-shadow(12px 12px 25px rgba(0, 0, 0, 0.5));
      &:hover {
        cursor: pointer;
      }
    }
  }

  .margin-btn {
    margin-bottom: 40%;
  }
}

@media (max-width: 1260px) {
  .wrapper {
    flex: 1 1 60%;
  }
}

@media (max-width: 1130px) {
  .wrapper .executorList {
    width: 115%;
  }

  .wrapper .executorList-list {
    width: 90%;
  }

  .wrapper .obligation-body {
    width: 115%;
  }

  .wrapper .obligation-footer {
    width: 106%;
  }
}

@media (max-width: 1030px) {
  .wrapper .executorList {
    width: 145%;
  }

  .wrapper .obligation-body {
    width: 135%;
  }

  .wrapper .obligation-footer {
    width: 122%;
  }
}

@media (max-width: 945px) {
  .wrapper {
    flex: 1 1 55%;
  }
}

@media (max-width: 903px) {
  .wrapper {
    flex: 1 1 50%;
    font-size: 13px;
  }

  .wrapper .executorList {
    width: 155%;
  }

  .wrapper .obligation-body {
    width: 150%;
  }

  .wrapper .obligation-footer {
    width: 137%;
  }
}
</style>
