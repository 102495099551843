<template>
  <div class="total_price">
    <div class="total_price-container">
      <div class="total_price-label">
        <span>Total</span>
      </div>
      <div class="total_price-cost">
        <span>£{{totalCost}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TotalPrice',
  props: ['totalCost'],
}
</script>

<style lang="scss" scope>
.total_price {
  border-bottom: 2px solid #583b75;
  border-top: 2px solid #583b75;
  background: rgba(88, 59, 117, 0.06);
  color: #583b75;
  padding: .7%;

  .total_price-container {
    display: flex;
    justify-content: flex-end;
    font-weight: 550;
    width: 100%;

    .total_price-label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      align-self: center;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 19px;
      width: 71.5%;
      margin-right: 2%;
    }

    .total_price-cost {
      width: 26.5%;
      height: 100%;

      span {
        border: 1px solid #464646;
        border-radius: 5%;
        background-color: #ffff;
        padding: 2px 15px;
      }

      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
</style>
