<template>
    <main class="container">
        <section class="intro">
            <img src="./../../assets/Articles/InspireMe/banner.png" alt="banner">
        </section>
        <section class="description">
            <div v-for="(description, index) in descriptions" :key="index">
                <p>{{description}}</p>
            </div>
        </section>
        <section class="content">
            <div class="content-item-1-ceremonies_before">
                <img src="./../../assets/Articles/InspireMe/ceremony1.png" alt="ceremony1">
                <div>
                    <h5>ceremonies before</h5>
                    <h3>Fusce eu rutrum risus. Nunc sit massa quis ligula sodales</h3>
                    <p>Praesent pretium facilisis velit. Pellentesque porta odio ante, vel interdum eros semper in. Mauris pretium sapien quis metus facilisis, fermentum hendrerit tortor dapibus.</p>
                </div>
            </div>
            <div class="content-item-2-ceremonies_before">
                <img src="./../../assets/Articles/InspireMe/ceremony2.png" alt="ceremony2">
                <div>
                    <h5>ceremonies before</h5>
                    <h3>Fusce eu rutrum risus. Nunc sit massa quis ligula sodales</h3>
                    <p>Praesent pretium facilisis velit. Pellentesque porta odio ante, vel interdum eros semper in. Mauris pretium sapien quis metus facilisis, fermentum hendrerit tortor dapibus.</p>
                </div>
            </div>
            <div class="content-item-ceremonies_before-slide">
                <div class="content-item-ceremonies_before-slide-item">
                    <img src="./../../assets/Articles/InspireMe/slideCeremonies/step1.png" alt="step1">
                    <h6>ceremonies before</h6>
                    <p>Fusce eu rutrum risus. Nunc sit massa quis ligula sodales</p>
                </div>
                <div class="content-item-ceremonies_before-slide-item">
                    <img src="./../../assets/Articles/InspireMe/slideCeremonies/step2.png" alt="step2">
                    <h6>ceremonies before</h6>
                    <p>Fusce eu rutrum risus. Nunc sit massa quis ligula sodales</p>
                </div>
                <div class="content-item-ceremonies_before-slide-item">
                    <img src="./../../assets/Articles/InspireMe/slideCeremonies/step3.png" alt="step3">
                    <h6>ceremonies before</h6>
                    <p>Fusce eu rutrum risus. Nunc sit massa quis ligula sodales</p>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
    export default {
        name: 'BpArticleInspireMe',
        data() {
            return {
                descriptions: [
                    'Short description about ceremonies before. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    'Short description about ceremonies during funerals. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    'Short description about ceremonies afterwards, over a long time. Lorem ipsum dolor sit amet, consectetur.',
                ],
            }
        },
    }
</script>

<style lang="scss" scoped>
    .container {
        width: 75%;
        max-width: 1920px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 0 auto;
    }
    .content {
        font-family: 'Noto Sans';
        display: flex;
        flex-direction: column;
        margin-bottom: 7%;
        .content-item-1-ceremonies_before {
            position: relative;
            margin-bottom: 5%;
            div {
                background: #FFFFFF;
                box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.1);
                border-radius: 2px;
                width: 45%;
                position: absolute;
                top: 25%;
                left: -1%;

                text-align: center;

                padding: 3% 5%;
                h5 {
                    text-transform: uppercase;
                    color: #583B75;
                    font-weight: 600;
                }
                h3 {
                    text-transform: uppercase;
                    color: #000000;
                    font-size: 24px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
        .content-item-2-ceremonies_before {
            position: relative;
            margin-bottom: 5%;
            div {
                background: #FFFFFF;
                box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.1);
                border-radius: 2px;
                width: 45%;
                position: absolute;
                top: 25%;
                right: -1%;

                text-align: center;

                padding: 3% 5%;
                h5 {
                    text-transform: uppercase;
                    color: #583B75;
                    font-weight: 600;
                }
                h3 {
                    text-transform: uppercase;
                    color: #000000;
                    font-size: 24px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
        .content-item-ceremonies_before-slide {
            display: flex;
            margin-left: -3%;
            .content-item-ceremonies_before-slide-item {
                font-family: 'Noto Sans';
                display: flex;
                flex-direction: column;
                
                width: 33.3%;
                margin-left: 3%;

                background: #FFFFFF;
                box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.1);
                border-radius: 2px;

                text-align: center;
                text-transform: uppercase;

                h6 {
                    font-size: 14px;
                    font-weight: 600;
                    color: #583B75;
                    margin: 5% 0;
                }
                p {
                    font-family: 'Roboto';
                    text-align: center;
                    color: #000000;
                    font-size: 18px;
                    font-weight: 500;
                    margin: 0 7% 5% 7%;
                }
            }
        }
    }
    .intro {
        width: 100%;
        margin: 2% 0 7% 0;
        img {
            width: 100%;
        }
    }
    .description {
        display: flex;
        width: 105%;
        margin-left: -5%;
        margin-bottom: 7%;
        div {
            width: 33.3%;
            margin-left: 5%;
            background: #FFFFFF;
            box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.1);
            border-radius: 2px;
            border-left: 5px solid #583b75;
            padding: 2%;
            p {
                text-align: center;
                font-size: 14px;
            }
        }
    }
</style>