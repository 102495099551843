<template>
  <main class="body container">
    <div class="row">
      <div class="col-12 col-md-4">
        <bp-main-sidebar
            class="modal__sidebar"
            :menu="actualMenu"
            :submenu="submenu"
            :toggleMenu="toggleMenu"
            :toggleSubmenu="toggleSubmenu"
        />
      </div>
      <div class="col-12 col-md-8">
        <Obligation v-if="activeMenuID == 4" />
        <bp-main-content
            v-else
            class="modal__content"
            :contents="contents"
            :open="open"
            :close="close"
        />
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from 'vuex'

import BpMainSidebar from './Sidebar.vue'
import BpMainContent from './Content.vue'
import Obligation from './../../Modals/ExecutorModals/Obligation.vue'

export default {
  name: 'BpAccaunt',
  props: ['closeModal'],
  components: {
    BpMainSidebar,
    BpMainContent,
    Obligation,
  },
  computed: {
    ...mapState({
      isExecutor: state => state.auth.currentUser?.is_executor,
    }),
    actualMenu() {
      const filteredMenu = this.menu.filter(m => m.id !== 4)
      return this.isExecutor ? this.menu : filteredMenu
    },
    actualSubMenu() {
      return this.submenu.filter(m => m.menuID === this.activeMenuID)
    },
    contents() {
      return {
        actualSubmenu: this.actualSubMenu,
        activeMenuID: this.activeMenuID,
        activeSubmenuID: this.activeSubmenuID,
      }
    },
  },
    data() {
    return {
      activeMenuID: 0,
      activeSubmenuID: 0,
      menu: [
        { title: 'Settings', id: 0 },
        { title: 'My Executor roles', id: 4 },
        { title: 'Support & help', id: 5 },
        { title: 'About us', id: 6 },
        { title: 'Logout', id: 7 },
      ],
      submenu: [
        {
          title: 'Personal info',
          subtitle: 'Set up reminders to update your regularly',
          id: 0,
          isShown: false,
          menuID: 0,
          type: 'info',
        },
        /* { */
        /*   title: 'Notifications', */
        /*   subtitle: 'Set up reminders to update your regularly', */
        /*   id: 1, */
        /*   isShown: false, */
        /*   menuID: 0, */
        /*   type: 'notifications', */
        /* }, */
        /* { */
        /*   title: 'Two-step verification', */
        /*   subtitle: 'Set up reminders to update your regularly', */
        /*   id: 2, */
        /*   isShown: false, */
        /*   menuID: 0, */
        /*   type: 'verification', */
        /* }, */
        /* { */
        /*   title: 'Add extra security', */
        /*   subtitle: 'Set up reminders to update your regularly', */
        /*   id: 3, */
        /*   isShown: false, */
        /*   menuID: 0, */
        /*   type: 'security', */
        /* }, */
        {
          title: 'Delete User',
          subtitle: 'Set up reminders to update your regularly',
          id: 4,
          isShown: false,
          menuID: 0,
          type: 'delete',
        },
      ],
    }
  },
  methods: {
    toggleMenu(id) {
      this.activeMenuID = id
    },
    toggleSubmenu(id) {
      this.activeSubmenu = id
      this.open(id)
    },
    open(id) {
      const curr = this.submenu.find(s => s.id === id)
      curr.isShown = true
    },
    close(id) {
      const curr = this.submenu.find(s => s.id === id)
      curr.isShown = false
    },
  },
}
</script>

<style lang="scss" scoped>
.modal__sidebar {
  flex-basis: 16rem;
  flex-grow: 1;
}
.modal__content {
  width: 100%;
  flex-basis: 0;
  flex-grow: 999;
}
</style>
