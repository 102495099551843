<template>
  <div class="search container">
    <div class="row">
      <div class="col-12">
        <p class="search__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu neque a nisl dignissim fermentum in et ipsum. Maecenas eu aliquet justo. Sed at dictum nunc, eget vestibulum elit. Curabitur blandit nec turpis eu aliquet. Donec leo ex, dapibus eu laoreet vel, mattis quis odio. Donec malesuada.</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <input
            class="search__input"
            type="text"
            placeholder="How can we help you?"
        />
      </div>
    </div>
    <bp-faq :data="faqData1" :title="faqTitle1" />
    <bp-faq :data="faqData2" :title="faqTitle2" />
    <bp-faq :data="faqData3" :title="faqTitle3" />
    <bp-faq :data="faqData4" :title="faqTitle4" />
  </div>
</template>

<script>
import BpFaq from '../../components/Faq.vue'

export default {
  name: 'BpFour',
  components: { BpFaq },
  data() {
    return {
      faqTitle1: 'Pre-planning a funeral',
      faqData1: [
        {
          ask: 'Which types of payment can I make?',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
          id: 0,
          isShown: false,
        },
        {
          ask: 'Which types of payment can I make?',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
          id: 1,
          isShown: false,
        },
        {
          ask: 'Which types of payment can I make?',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
          id: 2,
          isShown: false,
        },
        {
          ask: 'Which types of payment can I make?',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
          id: 3,
          isShown: false,
        },
      ],
      faqTitle2: 'About Wills and Probate',
      faqData2: [
        {
          ask: 'Which types of payment can I make?',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
          id: 0,
          isShown: false,
        },
        {
          ask: 'Which types of payment can I make?',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
          id: 1,
          isShown: false,
        },
        {
          ask: 'Which types of payment can I make?',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
          id: 2,
          isShown: false,
        },
        {
          ask: 'Which types of payment can I make?',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
          id: 3,
          isShown: false,
        },
      ],
      faqTitle3: 'Payment options',
      faqData3: [
        {
          ask: 'Which types of payment can I make?',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
          id: 0,
          isShown: false,
        },
        {
          ask: 'Which types of payment can I make?',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
          id: 1,
          isShown: false,
        },
        {
          ask: 'Which types of payment can I make?',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
          id: 2,
          isShown: false,
        },
        {
          ask: 'Which types of payment can I make?',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
          id: 3,
          isShown: false,
        },
      ],
      faqTitle4: 'My Legacy',
      faqData4: [
        {
          ask: 'Which types of payment can I make?',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
          id: 0,
          isShown: false,
        },
        {
          ask: 'Which types of payment can I make?',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
          id: 1,
          isShown: false,
        },
        {
          ask: 'Which types of payment can I make?',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
          id: 2,
          isShown: false,
        },
        {
          ask: 'Which types of payment can I make?',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
          id: 3,
          isShown: false,
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.search {
  .search__text {
    margin-top: 8%;
    font-size: 20px;
    text-align: justify;
  }
  .search__input {
    width: 100%;
    padding: 1%;
    margin: 3% 0 15% 0;
    border: 1px solid rgba(70, 70, 69, 0.3);
    border-radius: 2px;
    &::placeholder {
      color: #a7a7a7;
    }
    background-image: url('../../assets/search-input.png');
    background-repeat: no-repeat;
    background-position: 98% 50%;
  }
}
</style>
