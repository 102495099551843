<template>
  <div data-vue-name="addExecutorModal">
    <p class="mb-4">
      Info text what this is and why it i really important to do. Make it two
      line of text. Lorem ipsum dolor sit amet, consectetur adipiscing elit
    </p>
    <template v-if="executor.status">
      <h3 :style="{ color: '#583b75' }">{{ currentExecutorStatus }}</h3>
      <h5>{{ user.fname }}</h5>
      <h5>{{ user.lname }}</h5>
      <h5>{{ user.email }}</h5>
      <input
        type="button"
        class="send"
        value="Remove this Executor"
        @click="handleRemove"
      />
    </template>
    <template v-else>
      <form @submit.prevent="handleSubmit">
        <!-- <p v-if="errors">
    <b>Please correct the following error(s):</b>
    <ul>
      <li v-for="(error,i) in errors" :key='i'>{{ error }}</li>
    </ul>
  </p> -->

        <input
          type="text"
          v-model="user.fname"
          placeholder="First name"
          required
        />
        <input type="text" v-model="user.lname" placeholder="Last name" />
        <input type="email" v-model="user.email" placeholder="Email address" />
        <small :style="{ color: 'red' }" v-if="messege">{{ messege }}</small>
        <input type="submit" class="send" value="Send" :disabled="isValid" />
      </form>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { legalActionsTypes } from '@/store/modules/legal'
import { authActionTypes } from '@/store/modules/auth'

const { getExecutorInfo } = legalActionsTypes
const { registerExecutor, removeExecutor } = authActionTypes
export default {
  name: 'BpExecutor',
  props: ['setExecutorStatus', 'close'],
  mounted() {
    this.$store.dispatch(getExecutorInfo, this.currentUserId).then(res => {
      console.log('res ->', res) // eslint-disable-line no-console
      this.user.fname = res.fname
      this.user.lname = res.lname
      this.user.email = res.email
      if (res.status) {
        this.executor.status = res.status
        this.executor.id = res.id
        this.setExecutorStatus(res.status)
      }
    })
  },
  computed: {
    ...mapState({
      currentUserId: state => state.auth.currentUser.id,
      errors: state => state.auth.errors,
    }),
    isInvited() {
      return this.executor.status == 'invited'
    },
    currentExecutorStatus() {
      const statuses = {
        default: 'No request sent yet',
        invited: 'Not Accepted yet',
        accepted: 'Accepted',
        decline: 'Request declined or timed out',
      }
      const actualStatus = this.executor.status ?? statuses.default
      return statuses[actualStatus]
    },
    isValidate() {
      return !!this.messege.length
    },
  },
  data() {
    return {
      user: {
        fname: null,
        lname: null,
        email: null,
      },
      executor: {
        status: null,
        id: null,
      },
      messege: null,
      isValid: false,
    }
  },
  methods: {
    handleSubmit() {
      const isChecked = this.checkForm()
      if (!isChecked) {
        return
      }
      this.$store
        .dispatch(registerExecutor, {
          ...this.user,
          userId: this.currentUserId,
        })
        .then(() => {
          this.messege = null
          this.close()
        })
        .catch(Error)
    },
    handleRemove() {
      this.$store.dispatch(removeExecutor, this.executor.id).then(() => {
        this.close()
      })
    },
    checkForm() {
      if (!this.user.email) {
        this.messege = 'Email required.'
        return false
      }
      if (!this.user.fname || !this.user.lname) {
        this.messege = 'Name required.'
        return false
      }
      if (!this.validEmail(this.user.email)) {
        this.messege = 'Valid email required.'
        return false
      }
      this.isValid = true
      return true
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
  },
}
</script>

<style lang="scss" scoped>
div {
  h3 {
    font-size: 20px;
    text-align: center;
  }
}

form {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
h5 {
  font-weight: bold;
  font-size: 20px;
  padding: 3%;
  width: 100%;
  text-align: left;
}
input {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 25px;
  border: 1px solid rgba(70, 70, 69, 0.3);
  border-radius: 2px;
  &::placeholder {
    color: #a7a7a7;
  }
}
.send {
  width: 224px;
  height: 52px;
  margin-top: 5%;
  background: #583b75;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  box-shadow: 0px 7px 14px rgba(88, 59, 117, 0.2);
  border-radius: 2px;
  text-align: center;
  transition: color 0.5s, background-color 0.5s;
  &:hover {
    background-color: #fff;
    color: #583b75;
  }
}
</style>
