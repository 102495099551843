<template>
  <div class="question">
    <h4 v-show="order !== 0">{{ order }}. {{ question }}</h4>
    <div class="box">
      {{ description }}
    </div>
    <div class="buttons">
      <button
        v-for="option in options"
        :key="option.optionsID"
        @click="handleChoose(option)"
      >
        {{ option.text }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BpQuestion',
  props: ['data', 'moveForward', 'updateAnsw'],
  computed: {
    question() {
      return this.data.quizzData.question
    },
    description() {
      return this.data.quizzData.description
    },
    options() {
      return this.data.quizzData.options
    },
    order() {
      return this.data.order
    },
    isCurrentQuestion() {
      return this.data.quizzData.control_question
    },
  },
  methods: {
    handleChoose(answ) {
      this.updateAnsw(answ)
      this.moveForward()
    },
  },
}
</script>

<style lang="scss" scoped>
.question {
  width: 70%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  padding: 1%;
  margin: 4% auto;
  flex-wrap: wrap;
  display: flex;
  align-content: space-between;
  flex-direction: column;
}
.box {
  width: 100%;
  padding: 4%;
  display: flex;
  align-items: center;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);
  margin: 12% auto;
}
.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7%;
}
h4 {
  font-weight: bold;
}
button {
  width: 25%;
  border: 1px solid rgba(88, 59, 117, 0.33);
  /* box-shadow: 5px 5px 5px rgba(88, 59, 117, 0.3); */
  border-radius: 2px;
  padding: 2% 0;
  text-align: center;
  &:hover {
    background-color: #583b75;
    color: #fff;
  }
}
</style>
