import todoApi from '@/api/todo'

const state = {
  tabs: {
    activeTabID: 0,
    items: [
      { title: 'To-Do', id: 0, type: 'wishlist' },
      { title: 'Funeral', id: 1, type: 'plan' },
      { title: 'Account', id: 3, type: 'account' },
    ],
  },

  account: {
    activeMenuID: 6,
    activeSubmenuID: -1,
    executorWithdrawn: false,

    menu: [
      { title: 'Settings', id: 0 },
      // { title: 'Support & help', id: 4 },
      { title: 'About us', id: 5 },
      { title: 'Logout', id: 6 },
    ],

    submenu: [
      {
        title: 'Personal info',
        subtitle: 'Set up reminders to update your regularly',
        id: 0,
        isShown: false,
        menuID: 0,
        type: 'info',
      },
      {
        title: 'Notifications',
        subtitle: 'Set up reminders to update your regularly',
        id: 1,
        isShown: false,
        menuID: 0,
      },
      {
        title: 'Two-step verification',
        subtitle: 'Set up reminders to update your regularly',
        id: 2,
        isShown: false,
        menuID: 0,
      },
      {
        title: 'Add extra security',
        subtitle: 'Set up reminders to update your regularly',
        id: 3,
        isShown: false,
        menuID: 0,
      },
    ],
  },
  ToDo: {
    items: null,
  },
}

export const diedProfileMutationsTypes = {
  // tabs
  toggleActiveTabID: 'diedProfile/toggleActiveTabID',
  // account
  toggleActiveMenuID: 'diedProfile/toggleActiveMenuID',
  toggleActiveSubMenuID: 'diedProfile/toggleActiveSubMenuID',

  getToDoStart: 'diedProfile/getToDoStart',
  getToDoSuccess: 'diedProfile/getToDoSuccess',
  getToDoFailed: 'diedProfile/getToDoFailure',

  updateToDoStart: 'diedProfile/updateToDoStart',
  updateToDoSuccess: 'diedProfile/updateToDoSuccess',
  updateToDoFailed: 'diedProfile/updateToDoFailure',
}

const mutations = {
  //tabs
  [diedProfileMutationsTypes.toggleActiveTabID](state, payload) {
    state.tabs.activeTabID = payload
  },
  // account
  [diedProfileMutationsTypes.toggleActiveMenuID](state, payload) {
    state.account.activeMenuID = payload
  },
  [diedProfileMutationsTypes.toggleActiveSubMenuID](state, payload) {
    state.account.activeSubmenuID = payload
  },

  [diedProfileMutationsTypes.getToDoStart](state) {
    state.isLoading = true
  },
  [diedProfileMutationsTypes.getToDoSuccess](state, payload) {
    state.isLoading = false
    state.ToDo.items = payload
    state.errors = null
  },
  [diedProfileMutationsTypes.getToDoFailed](state, payload) {
    state.isLoading = false
    state.ToDo.items = null
    state.errors = payload
  },

  [diedProfileMutationsTypes.updateToDoStart](state) {
    state.isLoading = true
  },
  [diedProfileMutationsTypes.updateToDoSuccess](state, payload) {
    state.isLoading = false
    state.ToDo.items = payload
    state.errors = null
  },
  [diedProfileMutationsTypes.updateToDoFailed](state, payload) {
    state.isLoading = false
    state.ToDo.items = null
    state.errors = payload
  },
}

export const diedProfileActionsTypes = {
  getToDo: 'diedProfile/getToDo',
  updateToDo: 'diedProfile/updateToDo',
}

const actions = {
  [diedProfileActionsTypes.getToDo]({ commit }, id) {
    return new Promise(resolve => {
      commit(diedProfileMutationsTypes.getToDoStart)
      todoApi
        .getToDo(id)
        .then(response => {
          commit(diedProfileMutationsTypes.getToDoSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(diedProfileMutationsTypes.getToDoFailed)
        })
    })
  },
  [diedProfileActionsTypes.updateToDo]({ commit }, info) {
    return new Promise(resolve => {
      commit(diedProfileMutationsTypes.updateToDoStart)
      todoApi
        .updateToDo(info)
        .then(response => {
          commit(diedProfileMutationsTypes.updateToDoSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(diedProfileMutationsTypes.updateToDoInfoFailure)
        })
    })
  },
}

export const diedProfileGetterTypes = {
  // tobs
  tabs: 'diedProfile/tabs',
  activeTabID: 'diedProfile/activeTabID',
  // account
  menus: 'diedProfile/menus',
  subMenus: 'diedProfile/subMenus',
  activeMenu: 'diedProfile/activeMenu',
  activeSubMenu: 'diedProfile/subMenus',
  activeWishlistID: 'diedProfile/activeWishlistID',
}

const getters = {
  // tobs
  [diedProfileGetterTypes.activeTabID]: state => {
    return state.tabs.activeTabID
  },
  [diedProfileGetterTypes.tabs]: state => {
    return state.tabs.items
  },
  // account
  [diedProfileGetterTypes.activeMenuID]: state => {
    return state.account.activeMenuID
  },
  [diedProfileGetterTypes.activeSubmenuID]: state => {
    return state.account.activeSubmenuID
  },
  [diedProfileGetterTypes.menus]: state => {
    return state.account.menu
  },
  [diedProfileGetterTypes.subMenus]: state => {
    return state.account.submenu
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
