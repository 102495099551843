<template>
  <div
      class="arrow-up"
      :class="{'arrow-up--show': isScroll}"
      data-vue-name="ArrowUp"
      @click="scrollUp"
  >
    up
  </div>
</template>

<script>
export default {
  name: "ArrowUp",
  data() {
    return {
      isScroll:  false
    }
  },
  created () {
    this.handleScroll()
    window.addEventListener('scroll', () => {
      this.handleScroll()
    });
  },
  methods: {
    handleScroll() {
      pageYOffset > 150 ? this.isScroll = true : this.isScroll = false
    },
    scrollUp() {
      const el = document.getElementById('app')
      el.scrollIntoView({
        behavior: "smooth"
      })
    }
  },
}
</script>

<style lang="scss">
  .arrow-up {
    width: 30px;
    height: 30px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    background: #583B75;
    box-shadow: 0 7px 14px rgba(88, 59, 117, 0.2);
    border-radius: 50%;
    color: #fff;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    opacity: 0;
    transition: opacity .5s;
  }
  .arrow-up--show {
    opacity: 1;
    z-index: 1;
  }
</style>