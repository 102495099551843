import quizzApi from '@/api/quizzes'

const state = {
  isShown: false,
  process: 'none',
  errors: null,
  activeQuizID: 0,
  answers: [],
  defaultAnswers: null,
  quizzes: {},
}

export const quizzesMutationsTypes = {
  updateProcess: 'quizzesModal/updateProcess',
  toggleActiveQuiz: 'quizzesModal/toggleActiveQuiz',
  toggleModals: 'quizzesModal/toggleModals',
  getFullAnswers: 'quizzesModal/getFullAnswers',

  //get quizz
  getQuizzStart: 'quizzes/getCurrentQuizzStart',
  getQuizzSuccess: 'quizzes/getCurrentQuizzSuccess',
  getQuizzFailure: 'quizzes/getCurrentQuizzFailure',

  // answer
  writeAnswer: 'quizzesModal/writeAnswer',
  rewriteAnswer: 'quizzesModal/rewriteAnswer',
  clearAnswer: 'quizzesModal/clearAnswer',

  getAnswersStart: 'quizzes/getAnswersStart',
  getAnswersSuccess: 'quizzes/getAnswersSuccess',
  getAnswersFailure: 'quizzes/getAnswersFailure',

  getDefaultAnswersStart: 'quizzes/getDefaultAnswersStart',
  getDefaultAnswersSuccess: 'quizzes/getDefaultAnswersSuccess',
  getDefaultAnswersFailure: 'quizzes/getDefaultAnswersFailure',

  changeAnswerStart: 'quizzes/changeAnswerStart',
  changeAnswerSuccess: 'quizzes/changeAnswerSuccess',
  changeAnswerFailure: 'quizzes/changeAnswerFailure',

  sendAnswersStart: 'quizzes/sendAnswersStart',
  sendAnswersSuccess: 'quizzes/sendAnswersSuccess',
  sendAnswersFailure: 'quizzes/sendAnswersFailure',
}

const mutations = {
  //  quizzes
  [quizzesMutationsTypes.getQuizzStart](state) {
    state.isLoading = true
  },
  [quizzesMutationsTypes.getQuizzSuccess](state, payload) {
    state.isLoading = false
    state.quizzes = payload
    state.errors = null
  },
  [quizzesMutationsTypes.getQuizzFailure](state, payload) {
    state.isLoading = false
    state.quizzes = null
    state.errors = payload
  },

  //  answers
  [quizzesMutationsTypes.getAnswersStart](state) {
    state.isLoading = true
  },
  [quizzesMutationsTypes.getAnswersSuccess](state, payload) {
    state.isLoading = false
    state.answers = payload.answers || []
    state.errors = null
  },
  [quizzesMutationsTypes.getAnswersFailure](state, payload) {
    state.isLoading = false
    state.answers = []
    state.errors = payload
  },

  [quizzesMutationsTypes.getDefaultAnswersStart](state) {
    state.isLoading = true
  },
  [quizzesMutationsTypes.getDefaultAnswersSuccess](state, payload) {
    state.isLoading = false
    state.defaultAnswers = payload.answers
    state.errors = null
  },
  [quizzesMutationsTypes.getDefaultAnswersFailure](state, payload) {
    state.isLoading = false
    state.defaultAnswers = null
    state.errors = payload
  },

  [quizzesMutationsTypes.changeAnswerStart](state) {
    state.isLoading = true
  },
  [quizzesMutationsTypes.changeAnswerSuccess](state, payload) {
    state.isLoading = false
    state.errors = null
    const currentAnswer = state.answers.filter(
      a => a.questionID == payload.questionID,
    )
    currentAnswer[0].answer = payload
  },
  [quizzesMutationsTypes.changeAnswerFailure](state, payload) {
    state.isLoading = false
    state.errors = payload
  },

  [quizzesMutationsTypes.sendAnswersStart](state) {
    state.isLoading = true
  },
  [quizzesMutationsTypes.sendAnswersSuccess](state) {
    state.isLoading = false
    // state.quizzes = payload
    state.errors = null
  },
  [quizzesMutationsTypes.sendAnswersFailure](state, payload) {
    state.isLoading = false
    // state.quizzes = null
    state.errors = payload
  },

  // modals
  [quizzesMutationsTypes.toggleModals](state, payload) {
    if (!payload) {
      state.isShown = !state.isShown
    }
    state.isShown = payload
  },
  [quizzesMutationsTypes.updateProcess](state, payload) {
    state.procces = payload
  },
  [quizzesMutationsTypes.toggleActiveQuiz](state, id) {
    state.activeQuizID = id
  },
  [quizzesMutationsTypes.writeAnswer](state, payload) {
    state.answers.push(payload)
  },
  [quizzesMutationsTypes.rewriteAnswer](state, { questionID, answer }) {
    const [currentAnswer] = state.answers.filter(
      a => a.questionID == questionID,
    )
    currentAnswer.answer = answer
  },
  [quizzesMutationsTypes.clearAnswers](state) {
    state.answers = []
  },
  [quizzesMutationsTypes.getFullAnswers](state) {
    if (state.quizzes.items.length == state.answers.length) {
      return
    }
    const controleAnswerIdx = state.answers.findIndex(a => a.control_question)
    const lastIdx = state.defaultAnswers.length - 1
    const preparedAnswer = state.defaultAnswers.slice(
      controleAnswerIdx,
      lastIdx,
    )
    state.answers = [...state.answers, ...preparedAnswer]
  },
}

export const quizzesActionsTypes = {
  getQuizz: 'quizzes/getQuizz',
  updateAnswer: 'quizzes/updateAnswer',
  sendAnswers: 'quizzes/sendAnswers',
  getAnswers: 'quizzes/getAnswers',
  getDefaultAnswers: 'quizzes/getDefaultAnswers',
  changeAnswer: 'quizzes/changeAnswer',
}

const actions = {
  [quizzesActionsTypes.getQuizz]({ commit }, quizzName) {
    return new Promise(resolve => {
      commit(quizzesMutationsTypes.getQuizzStart)
      quizzApi
        .getQuizz(quizzName)
        .then(response => {
          commit(quizzesMutationsTypes.getQuizzSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(quizzesMutationsTypes.getQuizzFailure)
        })
    })
  },
  [quizzesActionsTypes.getAnswers]({ commit }, quizzName) {
    return new Promise(resolve => {
      commit(quizzesMutationsTypes.getAnswersStart)
      quizzApi
        .getAnswers(quizzName)
        .then(response => {
          commit(quizzesMutationsTypes.getAnswersSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(quizzesMutationsTypes.getAnswersFailure)
        })
    })
  },

  [quizzesActionsTypes.getDefaultAnswers]({ commit }, quizzName) {
    return new Promise(resolve => {
      console.log('resolve ->', resolve) // eslint-disable-line no-console
      commit(quizzesMutationsTypes.getDefaultAnswersStart)
      quizzApi
        .getDefaultAnswers(quizzName)
        .then(response => {
          commit(quizzesMutationsTypes.getDefaultAnswersSuccess, response.data)
          commit(quizzesMutationsTypes.getFullAnswers)
          resolve(response.data)
        })
        .catch(() => {
          commit(quizzesMutationsTypes.getDefaultAnswersFailure)
        })
    })
  },
  [quizzesActionsTypes.changeAnswer]({ commit }, answer) {
    return new Promise(resolve => {
      commit(quizzesMutationsTypes.changeAnswerStart)
      quizzApi
        .changeAnswer(answer)
        .then(response => {
          commit(quizzesMutationsTypes.changeAnswerSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(quizzesMutationsTypes.changeAnswerFailure)
        })
    })
  },
  [quizzesActionsTypes.sendAnswers]({ commit }, answers) {
    return new Promise(resolve => {
      commit(quizzesMutationsTypes.sendAnswersStart)
      quizzApi
        .sendResult(answers)
        .then(response => {
          commit(quizzesMutationsTypes.sendAnswersSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(quizzesMutationsTypes.sendAnswersFailure)
        })
    })
  },
  [quizzesActionsTypes.updateAnswer]({ commit, getters }, answer) {
    const currentAnswer = getters[quizzesGettersTypes.answers]?.some(
      a => a.questionID === answer.questionID,
    )

    if (currentAnswer) {
      commit(quizzesMutationsTypes.rewriteAnswer, answer)
    } else {
      commit(quizzesMutationsTypes.writeAnswer, answer)
    }
  },
}

export const quizzesGettersTypes = {
  quizzesIsShown: 'quizzesModal/isShown',
  quizzesProcess: 'quizzesModal/process',
  quizzesActiveID: 'quizzesModal/activeID',
  quizzes: 'quizzesModal/quizzes',
  answers: 'quizzesModal/answers',
  myWishes: 'quizzesModal/myWishes',
  tableFiteredBy: 'quizzesModal/tableFiteredBy',
  currentQuiz: 'quizzesModal/currentQuiz',
}

const getters = {
  [quizzesGettersTypes.isShown]: state => {
    return state.isShown
  },
  [quizzesGettersTypes.process]: state => {
    return state.process
  },
  [quizzesGettersTypes.activeQuizID]: state => {
    return state.activeQuizID
  },
  [quizzesGettersTypes.quizzes]: state => {
    return state.quizzes
  },
  [quizzesGettersTypes.answers]: state => {
    return state.answers
  },
  [quizzesGettersTypes.myWishes]: state => {
    return state.quizzes?.items?.filter(q => q.quizzTable === 'My Wishes')
  },
  [quizzesGettersTypes.tableFiteredBy]: state => table => {
    return state.quizzes?.items?.filter(q => q.quizzTable === table)
  },
  [quizzesGettersTypes.currentQuiz]: state => {
    return state.quizzes?.items?.filter(
      q => q.quizzID === state.activeQuizID,
    )[0]
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
