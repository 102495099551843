<template>
  <main class="new-main-page">
    <bp-notification v-if="notificationIsShown" />
    <bp-banner/>
    <div class="container">
      <bp-usp/>
      <bp-promo-card :cards="cards" :toggleModal="toggleModal"/>
      <bp-info :info="info"/>
      <bp-promo-card :cards="cards2" :toggleModal="toggleModal"/>
      <bp-info :info="info2"/>
      <ConstructorModal
          v-show="quizzModalIsShown"
          :toggleModal="toggleModal"
          :data="quizzes"
      />
    </div>
  </main>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { notificationGettersTypes } from '@/store/modules/notificationModals'

import BpBanner from '../../components/Banner.vue'
import BpUsp from '../../components/Usp.vue'
import BpPromoCard from '../../components/PromoCard.vue'
import BpInfo from '../../components/Info.vue'
import ConstructorModal from '../../components/QiuzModals/ConstructorModal.vue'
import BpNotification from '../../components/Modals/NotificationModal.vue'

export default {
  name: 'BpMain',
  components: {
    BpBanner,
    BpUsp,
    BpPromoCard,
    BpInfo,
    ConstructorModal,
    BpNotification,
  },

  computed: {
    ...mapState({
      loginModalProcces: ({ loginModals }) => loginModals.procces,
      quizzModalIsShown: state => state.quizzesModals.isShown,
      quizzes: state => state.quizzesModals.quizzes,
    }),
    ...mapGetters({
      notificationIsShown: notificationGettersTypes.notificationModalsIsShown,
    }),
  },

  data() {
    return {
      cards: [
        {
          text:
            'Someone has passed away and you need help to organise and arrange a funeral',
          button: 'Click for info',
          path: '#',
        },
        {
          text:
            'Do you want to make plans for the future and prepare everything yourself',
          button: 'Click for info',
          path: '#',
        },
      ],
      cards2: [
        {
          text:
            'Do you want to know more about the legal issues and clarify them?',
          button: 'Click for info',
          path: '#',
        },
        {
          text:
            'Do you need information about prepaid funeral plans and what is important?',
          button: 'Click for info',
          path: '#',
        },
      ],
      info: {
        title: 'Controlling the costs',
        text:
          'According to Sunlife, an insurance company, the average cost of someone dying in the UK 2019 was £9.400. That includes the costs of a basic funeral, the sending-off ceremony such as the memorial, flowers, venue and catering for the wake, etc., and the fees for hiring professionals to administer the estate. Good planning will not only help you create a funeral you can be proud of, but will also help you to manage the costs.',
      },
      info2: {
        title: 'more text about something important',
        text:
          'According to Sunlife, an insurance company, the average cost of someone dying in the UK 2019 was £9.400. That includes the costs of a basic funeral, the sending-off ceremony such as the memorial, flowers, venue and catering for the wake, etc., and the fees for hiring professionals to administer the estate. Good planning will not only help you create a funeral you can be proud of, but will also help you to manage the costs.',
      },
    }
  },
  methods: {
    toggleModal() {
      /* this.showModal = !this.showModal */
      this.$router.push({ name: 'funeral' })

      /* this.$store.commit(quizzesMutationsTypes.toggleModals) */
    },
  },
}
</script>
