<template>
  <div id="app" :class="{ noScroll: showNav }">
    <!--  <template >
      <bh-died-profile v-if="isDied" />
      <bh-user-profile v-if="!isDied" />
    </template> -->
    <component v-if="isLoggedIn" :is="actualProfile" />
    <template v-else>
      <bh-header v-if="!isLandingPage" />
      <bh-main-menu v-if="!isLandingPage" />
      <router-view />
      <bh-footer />
    </template>
  </div>
</template>

<script>
import BhFooter from '../src/components/Footer.vue'
import BhHeader from '../src/components/Header.vue'
import BhMainMenu from './components/NavBar.vue'
import BhUserProfile from './views/Profiles/UserProfile.vue'
import BhDiedProfile from './views/Profiles/DiedProfile.vue'

import {
  authActionTypes,
  authMutationsTypes,
  authGetterTypes,
} from '@/store/modules/auth'
import { getItem } from '@/helpers/persistanceStorage.js'
import { mapGetters, mapState } from 'vuex'

const { isAnonymous, isFirstLogin, isLoggedIn } = authGetterTypes
const { getCurrentUser } = authActionTypes
export default {
  name: 'App',
  components: {
    BhFooter,
    BhHeader,
    BhMainMenu,
    BhUserProfile,
    BhDiedProfile,
  },
  computed: {
    ...mapGetters({
      isAnonymous,
      isFirstLogin,
      isLoggedIn,
    }),
    ...mapState({
      showNav: state => state.toggleNavbar.isShowNav,
    }),
    actualProfile() {
      const profiles = { default: 'bh-user-profile', died: 'bh-died-profile' }
      return profiles[this.role]
    },
    isDied() {
      return this.role === 'died'
    },
    routeName() {
      return this.$route.name
    },
    isLandingPage() {
      return this.routeName?.includes('Landing')
    },
  },
  /* mounted() { */
  beforeMount() {
    console.log('beforeMount ->') // eslint-disable-line no-console
    if (getItem('accessToken')) {
      this.$store
        .dispatch(getCurrentUser)
        .then(res => {
          this.role = res.roles[0].name
        })
        .catch(Error)
      this.$store.commit(authMutationsTypes.isLoggedIn)
    }
  },
  mounted() {
    window.intercomSettings = {
      app_id: "j9iunenk"
    };
    // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/j9iunenk'
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/j9iunenk';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

  },
  data() {
    return {
      role: null,
    }
  },
}
</script>
<style lang="scss">
#app {
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  overflow-x: hidden;
}
.noScroll {
  overflow: hidden !important;
  padding-right: 17px;
  height: 100vh;
}
main {
  position: relative;
  z-index: 5;
  background: #fff;
}
</style>
