<template>
  <div>
    <p class="text-block">{{ description }}</p>
    <textarea
      name="petwishes"
      cols="125"
      rows="5"
      placeholder="Insert your text here"
      v-model="text"
    />
    <button class="save mx-auto d-block" @click="handleSave">Save</button>
  </div>
</template>

<script>
import { legalActionsTypes } from '@/store/modules/legal'

const { getUserInfo, updateUserInfo } = legalActionsTypes

export default {
  name: 'BhPets',
  props: ['close', 'description', 'quizzID', 'setFilled'],
  mounted() {
    this.$store.dispatch(getUserInfo, this.quizzID).then(res => {
      this.text = res.data.text
    })
  },
  computed: {},
  data() {
    return {
      text: '',
    }
  },
  methods: {
    handleSave() {
      const data = {
        filled: !!this.text.length,
        quizzID: this.quizzID,
        data: { text: this.text },
      }

      this.$store
        .dispatch(updateUserInfo, data)
        .then(() => {
          /* const modalProps = { */
          /*   isShown: true, */
          /*   procces: 'toast', */
          /*   message: 'Saved!', */
          /* } */

          /* this.$store.commit(showModals, modalProps) */
          this.setFilled(this.text)
          this.close()
        })
        .catch(Error)
    },
  },
  watch: {},
}
</script>

<style lang="scss" scoped>
.text-block {
  padding: 4%;
  border: 1px solid rgba($color: black, $alpha: 0.3);
}
.save {
  width: 224px;
  height: 52px;
  margin-top: 5%;
  background: #583B75;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  box-shadow: 0px 7px 14px rgba(88, 59, 117, 0.2);
  border-radius: 2px;
  text-align: center;
  transition: color .5s, background-color .5s;
  &:hover {
    background-color: #fff;
    color: #583b75;
  }
}

textarea {
  width: 100%;
  min-height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.33);
  padding: 2%;

  &::placeholder {
    color: #a7a7a7;
  }
}
</style>
