<template>
    <div class="modal__place-line">
        <div :style="{ width: width + '%' }" class="modal__place-line-active"></div>
    </div>
</template>

<script>
export default {
    name: 'LineProgress',
    props: {
        width: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
        }
    }
}
</script>

<style lang="scss" scoped>
.modal__place-line {
    width: 100%;
    height: 5px;
    background: #f3f3f3;
    .modal__place-line-active {
        width: 25%;
        height: 5px;
        background: #583b75;
    }
}
</style>