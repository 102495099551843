const state = {
  isShown: false,
  procces: 'none',
  message: 'Done!',
}

export const notificationMutationsTypes = {
  updateNotificationProcces: 'notificationModal/updateProcces',
  updateNotificationMessage: 'notificationModal/updateMessage',
  toggleNotificationModals: 'notificationModal/toggleModals',
  showModals: 'notificationModal/showModals',
  closeModals: 'notificationModal/closeModals',
}

const mutations = {
  [notificationMutationsTypes.toggleNotificationModals](state) {
    state.isShown = !state.isShown
  },
  [notificationMutationsTypes.closeModals](state) {
    state.isShown = Boolean(false)
  },
  [notificationMutationsTypes.updateNotificationProcces](state, payload) {
    state.procces = payload
  },
  [notificationMutationsTypes.updateNotificationMessage](state, payload) {
    state.message = payload
  },
  [notificationMutationsTypes.showModals](
    state,
    { isShown, procces, message },
  ) {
    state.isShown = isShown
    state.procces = procces
    state.message = message
  },
}

export const modalsActionsTypes = {}

const actions = {}

export const notificationGettersTypes = {
  notificationModalsIsShown: 'notificationModal/isShown',
  notificationModalsProcces: 'notificationModal/procces',
  notificationModalsMessage: 'notificationModal/message',
}

const getters = {
  [notificationGettersTypes.notificationModalsIsShown]: state => {
    return state.isShown
  },
  [notificationGettersTypes.notificationModalsProcces]: state => {
    return state.procces
  },
  [notificationGettersTypes.notificationModalsMessage]: state => {
    return state.message
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
