<template>
  <div class="modal" v-show="showModal">
    <transition name="modal">
      <div class="modal__mask d-flex justify-content-center align-items-start" @click="close">
        <div class="modal__container" @click.stop>
          <div class="modal__header">
            <span @click.prevent="close" class="modal__close">&#x2715;</span>
          </div>
          <div class="modal__body">
            <h4 class="text">Are you sure want to delete this ?!</h4>
            <div class="modal__buttons">
              <button class="modal__button" @click="callback">Yes</button>
              <button class="modal__button" @click="close">No</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'BpDeleteModal',
  props: ['callback', 'showModal', 'close'],
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.modal {
  width: 100%;
  height: 100%;
  display: flex;

  .modal__mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;

    .modal__container {
      width: 460px;
      min-height: 100px;
      height: auto;
      padding: 3%;
      display: inline-block;
      top: 28%;
      vertical-align: middle;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;

      position: relative;

      .modal__header {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        margin: 1% 0;
      }

      .modal__close {
        position: absolute;
        top: 10px;
        right: 13px;
        cursor: pointer;
      }
      .modal__buttons {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10% auto 0;
      }
      .modal__button {
        margin: 2% 4%;
        padding: 4%;
        width: 100px;
        box-shadow: 0 10px 10px rgba(88, 59, 117, 0.3);

        font-weight: 600;
        font-size: 16px;
        text-align: center;
        border-radius: 2px;
        border: 1px solid #583b75;
        color: #583b75;
        &.active {
          background: #583b75;
          color: #ffffff;
        }
      }
      .modal__body {
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        h4 {
          width: 100%;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
        }

        .modal-enter {
          opacity: 0;
        }

        .modal-leave-active {
          opacity: 0;
        }

        .modal-enter .modal-container,
        .modal-leave-active .modal-container {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
    }
  }
}
</style>
