import authApi from '@/api/auth'
import { setItem } from '@/helpers/persistanceStorage'
import { quizzesMutationsTypes } from './quizzesModals.js'
const state = {
  isSubmitting: false,
  isLoggedIn: null,
  isLoading: false,
  currentUser: null,
  executorUserInfo: null,
  validationErrors: null,
  validationErrorsModal: null,
  isFirstLogin: false,
}

export const authMutationsTypes = {
  registerStart: 'auth/registerStart',
  registerSuccess: 'auth/registerSuccess',
  registerFailure: 'auth/registerFailure',
  registerModalFailure: 'auth/registerModalFailure',

  confirmRegisterStart: 'auth/registerStart',
  confirmRegisterSuccess: 'auth/registerSuccess',
  confirmRegisterFailure: 'auth/registerFailure',

  loginStart: 'auth/loginStart',
  loginSuccess: 'auth/loginSuccess',
  loginFailure: 'auth/loginFailure',

  getCurrentUserStart: 'auth/getCurrentUserStart',
  getCurrentUserSuccess: 'auth/getCurrentUserSuccess',
  getCurrentUserFailure: 'auth/getCurrentUserFailure',

  forgotPassStart: 'auth/forgotPassStart',
  forgotPassSuccess: 'auth/forgotPassSuccess',
  forgotPassFailure: 'auth/forgotPassFailure',

  changePassStart: 'auth/changePassStart',
  changePassSuccess: 'auth/changePassSuccess',
  changePassFailure: 'auth/changePassFailure',

  updateCurrentUserStart: 'auth/updateCurrentUserStart',
  updateCurrentUserSuccess: 'auth/updateCurrentUserSuccess',
  updateCurrentUserFailure: 'auth/updateCurrentUserFailure',

  deleteUserStart: 'auth/deleteUserStart',
  deleteUserSuccess: 'auth/deleteUserSuccess',
  deleteUserFailure: 'auth/deleteUserFailure',

  logoutStart: 'auth/logoutStart',
  logoutSuccess: 'auth/logoutSuccess',
  logoutFailure: 'auth/logoutFailure',

  checkConfirmByMailStart: 'auth/checkConfirmByMailStart',
  checkConfirmByMailSuccess: 'auth/checkConfirmByMailSuccess',
  checkConfirmByMailFailure: 'auth/checkConfirmByMailFailure',

  checkConfirmExecutorStart: 'auth/checkConfirmExecutorStart',
  checkConfirmExecutorSuccess: 'auth/checkConfirmExecutorSuccess',
  checkConfirmExecutorFailure: 'auth/checkConfirmExecutorFailure',

  loginExecutorStart: 'auth/loginExecutorStart',
  loginExecutorSuccess: 'auth/loginExecutorSuccess',
  loginExecutorFailure: 'auth/loginExecutorFailure',

  registerExecutorStart: 'auth/registerExecutorStart',
  registerExecutorSuccess: 'auth/registerExecutorSuccess',
  registerExecutorFailure: 'auth/registerExecutorFailure',

  acceptExecutorStart: 'auth/acceptExecutorStart',
  acceptExecutorSuccess: 'auth/acceptExecutorSuccess',
  acceptExecutorFailure: 'auth/acceptExecutorFailure',

  removeExecutorStart: 'auth/removeExecutorStart',
  removeExecutorSuccess: 'auth/removeExecutorSuccess',
  removeExecutorFailure: 'auth/removeExecutorFailure',

  getExecutorUserStart: 'auth/getExecutorUserStart',
  getExecutorUserSuccess: 'auth/getExecutorUserSuccess',
  getExecutorUserFailure: 'auth/getExecutorUserFailure',

  changePersonalInfoStart: 'auth/changePersonalInfoStart',
  changePersonalInfoSuccess: 'auth/changePersonalInfoSuccess',
  changePersonalInfoFailure: 'auth/changePersonalInfoFailure',

  isLoggedIn: 'auth/isLoggedIn',
  isFirstLogin: 'auth/isFirstLogin',
}

export const authActionTypes = {
  register: 'auth/register',
  registerModal: 'auth/registerModal',
  confirmRegister: 'auth/confirmRegister',
  login: 'auth/login',
  forgotPass: 'auth/forgotPass',
  changePass: 'auth/changePass',
  getCurrentUser: 'auth/getCurrentUser',
  updateCurrentUser: 'auth/updateCurrentUser',
  logout: 'auth/logout',
  deleteUser: 'auth/deleteUser',
  checkConfirmByMail: 'auth/checkConfirmByMail',
  checkConfirmExecutor: 'auth/checkConfirmExecutor',
  loginExecutor: 'auth/loginExecutor',
  registerExecutor: 'auth/registerExecutor',
  acceptExecutor: 'auth/acceptExecutor',
  removeExecutor: 'auth/removeExecutor',
  getExecutorUser: 'auth/getExecutorUser',
  changePersonalInfo: 'auth/changePersonalInfo',
}

export const authGetterTypes = {
  currentUser: 'auth/currentUser',
  isLoggedIn: 'auth/isLoggedIn',
  isAnonymous: 'auth/isAnonymous',
  isDead: 'auth/isDead',
  isFirstLogin: 'auth/isFirstLogin',
}

const getters = {
  [authGetterTypes.currentUser]: state => {
    return state.currentUser
  },
  [authGetterTypes.isLoggedIn]: state => {
    return Boolean(state.isLoggedIn)
  },
  [authGetterTypes.isAnonymous]: state => {
    return Boolean(!state.currentUser)
  },
  [authGetterTypes.isFirstLogin]: state => {
    return Boolean(state.isFirstLogin)
  },
  [authGetterTypes.isDead]: state => {
    const { name } = state.currentUser.roles[0]
    return name === 'died'
  },
}

const mutations = {
  // register
  [authMutationsTypes.registerStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [authMutationsTypes.registerSuccess](state) {
    state.isSubmitting = false
    // state.currentUser = payload
    // state.isLoggedIn = true
  },
  [authMutationsTypes.registerFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },
  [authMutationsTypes.registerModalFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrorsModal = payload
  },
  // register confirm
  [authMutationsTypes.confirmRegisterStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [authMutationsTypes.confirmRegisterSuccess](state) {
    state.isSubmitting = false
    // state.currentUser = payload
    // state.isLoggedIn = true
  },
  [authMutationsTypes.confirmRegisterFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },
  [authMutationsTypes.isLoggedIn](state) {
    state.isLoggedIn = true
  },

  [authMutationsTypes.isFirstLogin](state, payload) {
    state.isFirstLogin = Boolean(payload)
  },
  // login
  [authMutationsTypes.loginStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [authMutationsTypes.loginSuccess](state) {
    state.isSubmitting = false
    state.isLoggedIn = true
    // state.currentUser = payload
  },
  [authMutationsTypes.loginFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },

  // user detail
  [authMutationsTypes.getCurrentUserStart](state) {
    state.isLoading = true
  },
  [authMutationsTypes.getCurrentUserSuccess](state, payload) {
    state.isLoading = false
    // state.isLoggedIn = true
    state.currentUser = payload
  },
  [authMutationsTypes.getCurrentUserFailure](state) {
    state.isLoading = false
    state.isLoggedIn = false
    state.currentUser = null
  },

  // change user personal info
  [authMutationsTypes.changePersonalInfoStart](state) {
    state.isLoading = true
  },
  [authMutationsTypes.changePersonalInfoSuccess](state, payload) {
    state.isLoading = false
    // state.isLoggedIn = true
    state.currentUser = payload
  },
  [authMutationsTypes.changePersonalInfoFailure](state) {
    state.isLoading = false
    state.currentUser = null
  },

  // update user bio
  [authMutationsTypes.updateCurrentUserStart]() {},
  [authMutationsTypes.updateCurrentUserSuccess](state, payload) {
    state.currentUser = payload
  },
  [authMutationsTypes.updateCurrentUserFailure]() {},

  // user logout
  [authMutationsTypes.logoutStart](state) {
    state.isLoading = true
  },
  [authMutationsTypes.logoutSuccess](state) {
    state.isLoading = false
    state.isLoggedIn = false
    state.currentUser = null
  },
  [authMutationsTypes.logoutFailure](state, payload) {
    state.isLoading = false
    state.isLoggedIn = false
    state.errors = payload
  },
  // user delete
  [authMutationsTypes.deleteUserStart](state) {
    state.isLoading = true
  },
  [authMutationsTypes.deleteUserSuccess](state) {
    state.isLoading = false
    state.isLoggedIn = false
    state.currentUser = null
  },
  [authMutationsTypes.deleteUserFailure](state, payload) {
    state.isLoading = false
    state.isLoggedIn = false
    state.errors = payload
  },

  // Restore password
  [authMutationsTypes.forgotPassStart]() {
    state.isLoading = true
  },
  [authMutationsTypes.forgotPassSuccess]() {},
  [authMutationsTypes.forgotPassFailure](state, payload) {
    state.currentUser = null
    state.isLoggedIn = false
    state.validationErrors = payload
  },
  [authMutationsTypes.changePassStart]() {
    state.isLoading = true
  },
  [authMutationsTypes.changePassSuccess]() {},
  [authMutationsTypes.changePassFailure](state, payload) {
    state.currentUser = null
    state.isLoggedIn = false
    state.validationErrors = payload
  },
  // check by mail
  [authMutationsTypes.checkConfirmByMailStart](state) {
    state.isLoading = true
  },
  [authMutationsTypes.checkConfirmByMailSuccess](state) {
    state.isLoading = false
    state.isLoggedIn = false
  },
  [authMutationsTypes.checkConfirmByMailFailure](state, payload) {
    state.isLoading = false
    state.isLoggedIn = false
    state.errors = payload
  },
  [authMutationsTypes.checkConfirmExecutorStart](state) {
    state.isLoading = true
  },
  [authMutationsTypes.checkConfirmExecutorSuccess](state) {
    state.isLoading = false
    // state.isLoggedIn = false
  },
  [authMutationsTypes.checkConfirmExecutorFailure](state, payload) {
    state.isLoading = false
    // state.isLoggedIn = false
    state.errors = payload
  },
  [authMutationsTypes.registerExecutorStart](state) {
    state.isLoading = true
  },
  [authMutationsTypes.registerExecutorSuccess](state) {
    state.isLoading = false
    // state.isLoggedIn = false
  },
  [authMutationsTypes.registerExecutorFailure](state, payload) {
    state.isLoading = false
    // state.isLoggedIn = false
    state.errors = payload
  },

  [authMutationsTypes.loginExecutorStart](state) {
    state.isLoading = true
  },
  [authMutationsTypes.loginExecutorSuccess](state) {
    state.isLoading = false
    // state.isLoggedIn = false
  },
  [authMutationsTypes.loginExecutorFailure](state, payload) {
    state.isLoading = false
    // state.isLoggedIn = false
    state.errors = payload
  },

  [authMutationsTypes.acceptExecutorStart]() {
    state.isLoading = true
  },
  [authMutationsTypes.acceptExecutorSuccess](state, { executor_status }) {
    state.executorUserInfo.executor_status = executor_status
  },
  [authMutationsTypes.acceptExecutorFailure](state, payload) {
    state.errors = payload
  },

  [authMutationsTypes.removeExecutorStart]() {
    state.isLoading = true
  },
  [authMutationsTypes.removeExecutorSuccess]() {},
  [authMutationsTypes.removeExecutorFailure](state, payload) {
    state.errors = payload
  },

  [authMutationsTypes.getExecutorUserStart](state) {
    state.isLoading = true
  },
  [authMutationsTypes.getExecutorUserSuccess](state, payload) {
    state.isLoading = false
    state.executorUserInfo = payload
  },
  [authMutationsTypes.getExecutorUserFailure](state, payload) {
    state.isLoading = false
    state.executorUserInfo = null
    state.errors = payload
  },
}

const actions = {
  [authActionTypes.register](context, credentials) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.registerStart)
      authApi
        .register(credentials)
        .then(response => {
          context.commit(authMutationsTypes.registerSuccess, response.data.user)
          setItem('accessToken', response.data.token)
          resolve(response.data.user)
        })
        .catch(result => {
          context.commit(
            authMutationsTypes.registerFailure,
            result.response.data.errors,
          )
        })
    })
  },

  [authActionTypes.registerModal](context, credentials) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.registerStart)
      authApi
        .register(credentials)
        .then(response => {
          context.commit(authMutationsTypes.registerSuccess, response.data.user)
          setItem('accessToken', response.data.token)
          resolve(response.data.user)
        })
        .catch(result => {
          context.commit(
            authMutationsTypes.registerModalFailure,
            result.response.data.errors,
          )
        })
    })
  },

  [authActionTypes.confirmRegister](context, code) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.confirmRegisterStart)
      authApi
        .confirmRegister(code)
        .then(response => {
          context.commit(
            authMutationsTypes.confirmRegisterSuccess,
            response.data,
          )
          resolve(response.data)
        })
        .catch(() => {
          context.commit(authMutationsTypes.confirmRegisterFailure)
        })
    })
  },

  [authActionTypes.login](context, credentials) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.loginStart)
      authApi
        .login(credentials)
        .then(response => {
          context.commit(authMutationsTypes.loginSuccess)
          setItem('accessToken', response.data.success.token)
          resolve(response.data)
        })
        .catch(result => {
          context.commit(authMutationsTypes.loginFailure, result)
        })
    })
  },

  [authActionTypes.getCurrentUser](context) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.getCurrentUserStart)
      authApi
        .getCurrentUser()
        .then(response => {
          context.commit(
            authMutationsTypes.getCurrentUserSuccess,
            response.data,
          )
          resolve(response.data)
        })
        .catch(() => {
          context.commit(authMutationsTypes.getCurrentUserFailure)
        })
    })
  },

  [authActionTypes.updateCurrentUser](context, { currentUserInput }) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.updateCurrentUserStart)
      authApi
        .updateCurrentUser(currentUserInput)
        .then(user => {
          context.commit(authMutationsTypes.updateCurrentUserSuccess, user)
          resolve(user)
        })
        .catch(result => {
          context.commit(
            authMutationsTypes.updateCurrentUserFailure,
            result.response.data.errors,
          )
        })
    })
  },

  [authActionTypes.forgotPass](context, credentials) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.forgotPassStart)
      authApi
        .forgotPass(credentials)
        .then(response => {
          context.commit(authMutationsTypes.forgotPassSuccess, response)
          resolve(response)
        })
        .catch(result => {
          context.commit(
            authMutationsTypes.forgotPassFailure,
            result.response.data.message,
          )
        })
    })
  },

  [authActionTypes.changePass](context, credentials) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.changePassStart)
      authApi
        .changePass(credentials)
        .then(response => {
          context.commit(authMutationsTypes.changePassSuccess, response)
          setItem('accessToken', response.data.token)
          resolve(response)
        })
        .catch(result => {
          context.commit(
            authMutationsTypes.changePassFailure,
            result.response.data.message,
          )
        })
    })
  },

  [authActionTypes.logout](context) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.logoutStart)
      authApi
        .logout()
        .then(response => {
          setItem('accessToken', '')
          context.commit(authMutationsTypes.logoutSuccess)
          context.commit(quizzesMutationsTypes.logoutSuccess)
          resolve(response)
        })
        .catch(() => {
          context.commit(authMutationsTypes.logoutFailure)
        })
    })
  },

  [authActionTypes.deleteUser](context, id) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.deleteUserStart)
      authApi
        .removeUser(id)
        .then(response => {
          setItem('accessToken', '')
          context.commit(authMutationsTypes.deleteUserSuccess)
          resolve(response)
        })
        .catch(() => {
          context.commit(authMutationsTypes.deleteUserFailure)
        })
    })
  },

  [authActionTypes.checkConfirmByMail](context, data) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.checkConfirmByMailStart)
      authApi
        .checkConfirmByMail(data)
        .then(response => {
          context.commit(
            authMutationsTypes.checkConfirmByMailSuccess,
            response.data,
          )
          resolve(response.data)
        })
        .catch(() => {
          context.commit(authMutationsTypes.checkConfirmByMailFailure)
        })
    })
  },

  [authActionTypes.checkConfirmExecutor](context, data) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.checkConfirmExecutorStart)
      authApi
        .checkConfirmExecutor(data)
        .then(response => {
          context.commit(
            authMutationsTypes.checkConfirmExecutorSuccess,
            response.data,
          )
          resolve(response.data)
        })
        .catch(() => {
          context.commit(authMutationsTypes.checkConfirmExecutorFailure)
        })
    })
  },

  [authActionTypes.registerExecutor](context, credentials) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.registerExecutorStart)
      authApi
        .registerExecutor(credentials)
        .then(response => {
          context.commit(
            authMutationsTypes.registerExecutorSuccess,
            response.data.user,
          )
          resolve(response.data.user)
        })
        .catch(result => {
          context.commit(
            authMutationsTypes.registerExecutorFailure,
            result.response.data.errors,
          )
        })
    })
  },

  [authActionTypes.loginExecutor](context, credentials) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.loginExecutorStart)
      authApi
        .loginExecutor(credentials)
        .then(response => {
          context.commit(authMutationsTypes.loginExecutorSuccess)
          setItem('accessToken', response.data.success.token)
          resolve(response.data)
        })
        .catch(result => {
          context.commit(authMutationsTypes.loginExecutorFailure, result)
        })
    })
  },

  [authActionTypes.acceptExecutor](context, id) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.acceptExecutorStart)
      authApi
        .acceptExecutor(id)
        .then(response => {
          context.commit(
            authMutationsTypes.acceptExecutorSuccess,
            response.data,
          )
          resolve(response.data)
        })
        .catch(result => {
          context.commit(authMutationsTypes.acceptExecutorFailure, result)
        })
    })
  },

  [authActionTypes.removeExecutor](context, id) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.removeExecutorStart)
      authApi
        .removeExecutor(id)
        .then(response => {
          context.commit(authMutationsTypes.removeExecutorSuccess)
          resolve(response.data)
        })
        .catch(result => {
          context.commit(authMutationsTypes.removeExecutorFailure, result)
        })
    })
  },
  [authActionTypes.getExecutorUser](context) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.getExecutorUserStart)
      authApi
        .getExecutorUser()
        .then(response => {
          context.commit(
            authMutationsTypes.getExecutorUserSuccess,
            response.data,
          )
          resolve(response.data)
        })
        .catch(result => {
          context.commit(authMutationsTypes.getExecutorUserFailure, result)
        })
    })
  },
  [authActionTypes.changePersonalInfo](context, id) {
    return new Promise(resolve => {
      context.commit(authMutationsTypes.changePersonalInfoStart)
      authApi
        .changePersonalInfo(id)
        .then(response => {
          context.commit(
            authMutationsTypes.changePersonalInfoSuccess,
            response.data,
          )
          resolve(response.data)
        })
        .catch(result => {
          context.commit(authMutationsTypes.changePersonalInfoFailure, result)
        })
    })
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
