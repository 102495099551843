<template>
  <div
      class="card"
      data-vue-name="card"
  >
    <h3 v-if="card.title">{{ card.title }}</h3>
    <p v-if="card.text">
      {{ card.text }}
    </p>
    <div class="card__arrow-wrap">
      <transition name="slide" v-on:enter="leave" v-on:after-leave="enter">
        <img
          class="shadow"
          v-show="show"
          src="../assets/arrow.png"
          alt="arrowShadow"
        />
      </transition>
      <img
          class="mt-auto"
          :class="{ arrow: arrow }"
          src="../assets/arrow.png"
          alt="arrow"
      />
    </div>
    <button
      @mouseover="show = !show"
      @mouseleave="show = !show"
      @click="toggle"
    >
      {{ card.button }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: ['card', 'toggleModal'],
  data() {
    return {
      cardText:
        'You want to make plans for the future and prepare everything yourself',
      show: true,
      arrow: false,
    }
  },
  methods: {
    toggle() {
      if (this.$props.toggleModal) {
        this.$props.toggleModal()
      }
    },
    leave: function () {
      this.arrow = false
    },
    enter: function () {
      this.arrow = true
    },
  },
}
</script>

<style lang="scss" scoped>
.card__arrow-wrap {
  display: flex;
  flex-direction: column;
  height: 22px;
  margin-top: auto;
}
h3 {
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  margin-top: 4%;
}

p {
  padding: 5% 10% 0 10%;
  font-size: 16px;
  text-align: center;
}

button {
  margin: 5% auto;
  background: #583b75;
  color: #ffffff;
  padding: 15px 50px;

  font-weight: 600;
  font-size: 16px;
  text-align: center;
  border-radius: 2px;
}

img {
  margin: 0 auto;
}

.shadow {
  opacity: 0.3;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s;
}
.slide-enter,
.slide-leave-to {
  transform: translate3d(0, 10px, 0);
}
</style>
