<template>
  <div v-if="!isUnregistered" class="ml-tooltip-box">
    <span class="ml-triangle"></span>
    <div class="ml-tooltip">
      <slot> </slot>
    </div>
  </div>
  <div v-else class="ml-tooltip-box">
    <div class="ml-tooltip-un">
      <slot> </slot>
    </div>
    <span class="ml-triangle-un"></span>
  </div>
</template>

<script>
export default {
  name: 'BpTooltip',
  props: ['isUnregistered'],
}
</script>

<style lang="scss" scoped>
.ml-tooltip-box {
  position: relative;
  .ml-tooltip {
    width: 250px;
    background: #ffffff;
    color: #000000;
    text-align: center;
    padding: 10px 20px 10px 20px;
    border-radius: 2px;
    box-shadow: 0px 10px 40px rgba(88, 59, 117, 0.16);
    top: 50%;
    left: calc(100% + 20px);
    transform: translateY(-50%);
    position: absolute;
    z-index: 33;
  }

  .ml-tooltip-un {
    width: 250px;
    background: #ffffff;
    color: #000000;
    text-align: center;
    padding: 10px 20px 10px 20px;
    border-radius: 2px;
    box-shadow: 0px 10px 40px rgba(88, 59, 117, 0.16);
    top: 50%;
    left: -300px;
    transform: translateY(-50%);
    position: absolute;
    z-index: 33;
  }
}
.ml-triangle {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border-width: 0 16px 16px;
  border-color: transparent;
  border-bottom-color: #ffffff;
  /* box-shadow: 0px 10px 40px rgba(88, 59, 117, 0.16); */

  /* border: 1px solid #000; */
  position: absolute;
  left: 6px;
  top: calc(50% - 4px);
  transform: rotate(45deg) translateY(-50%);
  z-index: 35;
}

.ml-triangle-un {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border-width: 0 16px 16px;
  border-color: transparent;
  border-bottom-color: #ffffff;
  /* box-shadow: 0px 10px 40px rgba(88, 59, 117, 0.16); */

  /* border: 1px solid #000; */
  position: absolute;
  left: -65px;
  top: calc(50% - 4px);
  transform: rotate(45deg) translateY(-50%);
  z-index: 35;
}
</style>
