<template>
  <div class="modal__place" v-if="!isShownDeleteModal">
      <div class="modal__place-mask" @click="close">
        <div class="modal__place-container" @click.stop>
          <span 
          @click.stop="showDeleteModal" 
          class="modal__remove"
          v-if="title != 'Type of funeral' && title != 'Type of ceremony'">Remove</span>

          <h4 class="modal__place-header">
            {{ title }}
            <!-- <span v-if="currentQuiz.title">{{ currentQuiz.title }}</span>-->
            <span @click="close" class="modal__place-close">&#x2715;</span>
          </h4>
          <div class="cards">
            <div class="box">
              <div
                class="card"
                v-for="card in cards"
                :key="card.dataID"
                :class="{ active: isActive(card) }"
                @click="handleClick(card)"
              >
                <img :src="url + card.img" alt="img" />
                <h5>{{ card.title }}</h5>
                <p>{{ card.text }}</p>
                <div class="price">
                  <span>Price: £{{ card.price }}</span>
                </div>
                <div class="like">
                  <img
                    :class="{
                      activeBtn: !isActive(card),
                      notActiveBtn: isActive(card),
                    }"
                    src="./../../../../assets/MyPlanTable/button_like.png"
                    alt="button_like"
                  />
                  <img
                    :class="{
                      activeBtn: isActive(card),
                      notActiveBtn: !isActive(card),
                    }"
                    src="./../../../../assets/MyPlanTable/button_done.png"
                    alt="button_like"
                  />
                </div>
              </div>
            </div>
            <button @click="handleChoose()">Choose</button>
          </div>
        </div>
      </div>
  </div>
  <bp-delete-modal
    v-else
    :callback="sendNO"
    :showModal="isShownDeleteModal"
    :close="close"
  />
</template>

<script>
import { mapState } from 'vuex'
import { quizzesActionsTypes } from '@/store/modules/quizzesModals'

import BpDeleteModal from '../../Modals/ConfirmDeleteModal.vue'

const { changeAnswer } = quizzesActionsTypes

export default {
  name: 'ModalWindow',
  props: ['show', 'cards', 'title'],
  computed: {
    ...mapState({
      quizzes: state => state.quizzesModals.quizzes?.items,
    }),
    isShownDeleteModal() {
      return this.deleteModalIsShown
    },
  },
  components: {
    BpDeleteModal,
  },
  data() {
    return {
      activeID: null,
      url: 'http://efter-b.pandateam.net/',
      deleteModalIsShown: false,
    }
  },
  methods: {
    showDeleteModal() {
      this.deleteModalIsShown = true;
    },
    close() {
      this.$emit('toggle', this.show)
    },
    handleClick(card) {
      this.activeID = card.optionsID || card.dataID
      this.quizzID = card.quizzID
    },
    sendNO() {
      const res = this.quizzes.filter(
        q => q.quizzID === this.cards[0].quizzID,
      )
      const answer = res[0].quizzData.options.find(a => a.text === 'NO')
      this.updateAnsw(answer)
      this.close()
    },
    updateAnsw(answer) {
      if (!answer) {
        return;
      }
      this.$store.dispatch(changeAnswer, {
        questionID: answer.quizzID,
        answer,
      })
    },
    handleChoose() {
      const answ = this.cards.find(card => {
        const activeID = card.optionsID || card.dataID;
        return activeID === this.activeID;
      })
      this.updateAnsw(answ);
      this.close();
    },
    isActive(card) {
      const actualID = card.dataID || card.optionsID
      return actualID === this.activeID
    },
  },
}
</script>

<style lang="scss" scoped>
.modal__place {
  .modal__place-mask {
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
  }

  .modal__place-container {
    width: 940px;
    height: 600px;
    max-width: 60%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;

    position: relative;

    .status {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-self: flex-start;
      width: 37%;
      margin-left: 8.7%;

      font-size: 13px;

      .status-text {
        font-weight: 550;
        font-size: 15px;
      }

      .activeBtnStatus {
        background-color: #583b75;
        color: #fff;
      }

      button {
        width: 37%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1% 7%;
        img {
          margin-right: 10%;
          width: 27%;
        }
      }
    }

    .cards {
      width: 85%;
      height: 90%;
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;

      padding: 1%;
      margin: 0 auto;
      flex-wrap: wrap;
    }
    h4 {
      font-weight: bold;
    }
    .box {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
      flex-direction: row;
      gap: 2%;
      margin: 2% auto;
    }

    .card {
      display: flex;
      height: 18rem;
      width: 25%;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.33);
      text-align: left;

      overflow: visible;
      text-overflow: ellipsis;

      &.active {
        box-shadow: 0 10px 10px rgba(88, 59, 117, 0.7);
        border: 1px solid #583b75;
      }
      &:hover {
        box-shadow: 0 10px 10px rgba(88, 59, 117, 0.3);
        /* border: 1px solid #583b75; */
      }
      img {
        margin-bottom: 5%;
        max-height: 115px;
      }
      h5 {
        padding: 4%;
        font-weight: bold;
        font-size: 15px;
        margin: 0;
      }
      p {
        padding: 0 4%;
        font-size: 14px;
        height: 50%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        line-height: 1.3em;
        height: 6.4em;
      }
      .price {
        font-size: 14px;
        font-weight: 550;
        padding: 0 4%;
        margin-top: auto;
        position: absolute;
        bottom: 10px;
      }
      .like {
        position: absolute;
        left: 35%;
        right: 25%;
        top: 105%;

        .notActiveBtn {
          display: none;
        }
        .activeBtn {
          display: block;
        }

        img {
          width: 80%;
        }
      }
    }
    button {
      width: 20%;
      border: 1px solid rgba(88, 59, 117, 0.33);
      /* box-shadow: 5px 5px 5px rgba(88, 59, 117, 0.3); */
      border-radius: 2px;
      padding: 1% 0;
      margin: 12% auto;
      text-align: center;
      &:hover {
        background-color: #583b75;
        color: #fff;
      }
    }

    .modal__place-header {
      width: 100%;
      margin: 3% 0;
      font-weight: 500;
      font-size: 24px;
      text-align: center;
    }
    .modal__place-close {
      position: absolute;
      top: 10px;
      right: 13px;
      cursor: pointer;
    }
    .modal__remove {
      cursor: pointer;
      text-align: center;
      &:hover {
        color: #583b75;
      }

      position: absolute;
      top: 10px;
      left: 13px;
    }
  }
}
</style>
