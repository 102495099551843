<template>
  <div class="modal" v-show="showModal">
    <transition name="modal">
      <div class="modal__mask" @click="close">
        <div class="modal__container" @click.stop>
          <div class="modal__header">
            <bp-tab-bar :tabs="tabs" :toggleTab="toggleTab" />
            <span @click.prevent="close" class="modal__close">&#x2715;</span>
          </div>
          <component :is="currentTab" :closeModal="close" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  userProfileGetterTypes,
  userProfileMutationsTypes,
} from '@/store/modules/userProfile'

import BpTabBar from './UserProfileTabs.vue'
import BpAccBody from './Account/Body.vue'
import BpLorem from './Lorem.vue'
import BpToDo from './Wishlist/WishListMain.vue'
import BpGuests from './Guests/Guests.vue'
const { toggleActiveTabID } = userProfileMutationsTypes
export default {
  name: 'BpMainModal',
  components: {
    BpTabBar,
    BpAccBody,
    BpToDo,
    BpLorem,
    BpGuests,
  },
  computed: {
    ...mapGetters({
      tabs: userProfileGetterTypes.tabs,
      showModal: userProfileGetterTypes.isShown,
      activeTabID: userProfileGetterTypes.activeTabID,
    }),
    currentTab() {
      const components = {
        account: 'bp-acc-body',
        todo: 'bp-to-do',
        guests: 'bp-guests',
        list: 'bp-lorem',
      }
      const { type } = this.tabs.filter(t => t.id === this.activeTabID)[0]
      console.log('type ->', type) // eslint-disable-line no-console

      return components[type]
    },
  },
  data() {
    return {}
  },

  methods: {
    close() {
      this.$emit('close')
    },
    toggleTab(id) {
      console.log('id ->', id) // eslint-disable-line no-console
      this.$store.commit(toggleActiveTabID, id)
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  width: 100%;
  height: 100%;
  display: flex;

  .modal__mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;

    .modal__container {
      width: 80%;
      height: 80%;
      overflow: auto;

      display: inline-block;
      top: 10%;
      vertical-align: middle;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;

      position: relative;

      .modal__header {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        margin: 1% 0;
      }

      .modal__close {
        position: absolute;
        top: 10px;
        right: 13px;
        cursor: pointer;
      }

      .modal__button {
        margin: 2% 4%;
        padding: 1% 4%;

        box-shadow: 0 10px 10px rgba(88, 59, 117, 0.3);

        font-weight: 600;
        font-size: 16px;
        text-align: center;
        border-radius: 2px;
        border: 1px solid #583b75;
        color: #583b75;
        &.active {
          background: #583b75;
          color: #ffffff;
        }
      }
      .modal__body {
        width: 90%;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        .modal-enter {
          opacity: 0;
        }

        .modal-leave-active {
          opacity: 0;
        }

        .modal-enter .modal-container,
        .modal-leave-active .modal-container {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
    }
  }
}
</style>
