import guestsApi from '@/api/guests'

const state = {
  guests: [{ id: 0, name: 'Alex', comment: '' }],
  isLoading: null,
  errors: null,
}

export const guestsMutationsTypes = {
  getGuestsStart: 'guests/getGuestsStart',
  getGuestsSuccess: 'guests/getGuestsSuccess',
  getGuestsFailure: 'guests/getGuestsFailure',

  addGuestStart: 'guests/addGuestStart',
  addGuestSuccess: 'guests/addGuestSuccess',
  addGuestFailure: 'guests/addGuestFailure',

  updateGuestStart: 'guests/updateGuestStart',
  updateGuestSuccess: 'guests/updateGuestSuccess',
  updateGuestFailure: 'guests/updateFailure',

  deleteGuestStart: 'guests/deleteGuestStart',
  deleteGuestSuccess: 'guests/deleteGuestSuccess',
  deleteGuestFailure: 'guests/deleteGuestFailure',
}

const mutations = {
  [guestsMutationsTypes.getGuestsStart](state) {
    state.isLoading = true
  },
  [guestsMutationsTypes.getGuestsSuccess](state, payload) {
    state.isLoading = false
    state.guests = payload
    state.errors = null
  },
  [guestsMutationsTypes.getGuestsFailure](state, payload) {
    state.isLoading = false
    state.guests = null
    state.errors = payload
  },

  [guestsMutationsTypes.addGuestStart](state) {
    state.isLoading = true
  },
  [guestsMutationsTypes.addGuestSuccess](state, payload) {
    state.isLoading = false
    state.guests.push(payload)
    state.errors = null
  },
  [guestsMutationsTypes.addGuestFailure](state, payload) {
    state.isLoading = false
    state.errors = payload
  },

  [guestsMutationsTypes.updateGuestStart](state) {
    state.isLoading = true
  },
  [guestsMutationsTypes.updateGuestSuccess](state, payload) {
    state.isLoading = false
    state.errors = null
    const idx = state.guests.findIndex(p => p.id === payload.id)
    state.guests[idx] = payload
  },
  [guestsMutationsTypes.updateGuestFailure](state, payload) {
    state.isLoading = false
    state.errors = payload
  },

  [guestsMutationsTypes.deleteGuestStart](state) {
    state.isLoading = true
  },
  [guestsMutationsTypes.deleteGuestSuccess](state, payload) {
    console.log('payload ->', payload) // eslint-disable-line no-console
    state.isLoading = false
    state.errors = null
    const filtered = state.guests.filter(p => p.id !== payload.id)
    state.guests = filtered
  },
  [guestsMutationsTypes.deleteGuestFailure](state, payload) {
    state.isLoading = false
    state.errors = payload
  },
}

export const guestsActionsTypes = {
  getGuests: 'guests/getGuest',
  addGuest: 'guests/addGuest',
  updateGuest: 'guests/updateGuest',
  deleteGuest: 'guests/deleteGuest',
}

const actions = {
  [guestsActionsTypes.getGuests]({ commit }) {
    return new Promise(resolve => {
      commit(guestsMutationsTypes.getGuestsStart)
      guestsApi
        .getGuests()
        .then(response => {
          commit(guestsMutationsTypes.getGuestsSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(guestsMutationsTypes.getGuestsFailure)
        })
    })
  },
  [guestsActionsTypes.addGuest]({ commit }, guest) {
    return new Promise(resolve => {
      commit(guestsMutationsTypes.addGuestStart)
      guestsApi
        .addGuest(guest)
        .then(response => {
          commit(guestsMutationsTypes.addGuestSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(guestsMutationsTypes.addGuestFailure)
        })
    })
  },
  [guestsActionsTypes.updateGuest]({ commit }, guest) {
    return new Promise(resolve => {
      commit(guestsMutationsTypes.updateGuestStart)
      guestsApi
        .updateGuest(guest)
        .then(response => {
          commit(guestsMutationsTypes.updateGuestSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(guestsMutationsTypes.updateGuestFailure)
        })
    })
  },
  [guestsActionsTypes.deleteGuest]({ commit }, id) {
    return new Promise(resolve => {
      commit(guestsMutationsTypes.deleteGuestStart)
      guestsApi
        .deleteGuest(id)
        .then(response => {
          console.log('response ->', response) // eslint-disable-line no-console
          commit(guestsMutationsTypes.deleteGuestSuccess, response.data)
          resolve(response.data)
        })
        .catch(response => {
          console.log('response ->', response) // eslint-disable-line no-console
          commit(guestsMutationsTypes.deleteGuestFailure, response.error)
        })
    })
  },
}

export const guestsGetterTypes = {
  guests: 'guests/guests',
}

const getters = {
  [guestsGetterTypes.guests]: state => {
    return state.guests.guests
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
