<template>
  <div
      data-vue-name="belonging"
      class="container belonging"
  >
    <div class="row">
      <div class="col-12">
        <p class="text-block">{{ text }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div
            class="belonging__scroll"
            :class="heightBoxInfo > 180 ?'belonging__scroll--isScroll' : ''"
        >
          <div class="position-relative">
            <ul class="belonging__list-belonging col-12">
              <li class="belonging__title-head">
                <div class="row">
                  <div class="col-5">Belonging</div>
                  <div class="col-5">Beneficiary</div>
                </div>
              </li>
              <li
                  v-for="item in belong" :key="item.id"
              >
                <div class="row align-items-start">
                  <div class="col-5">
                    <input
                        v-if="item.id === updateCurrentBelongId"
                        class="update-field-input"
                        type="text"
                        v-model="updateAppurtenant.belonging"
                        @keyup.enter="handleSave(item)"
                    />
                    <span v-else>{{ item.belonging }}</span>
                  </div>
                  <div class="col-5">
                    <input
                        v-if="item.id === updateCurrentBelongId"
                        class="update-field-input"
                        type="text"
                        v-model="updateAppurtenant.beneficiary"
                        @keyup.enter="handleSave(item)"
                    />
                    <span v-else>{{ item.beneficiary }}</span>
                  </div>
                  <div class="col-2">
                    <div class="belonging__controls">
                      <img
                          v-if="item.id === updateCurrentBelongId"
                          class="icon-confirm btn-save"
                          alt=""
                          title="Done"
                          src="../../../../assets/button_done.png"
                          @click="handleSave()"
                      />
                      <img
                          v-else
                          class="icon-confirm btn-edit"
                          alt=""
                          title="Edit"
                          src="../../../../assets/button_edit.png"
                          @click="handleUpdate(item)"
                      />
                      <img
                          class="icon-confirm btn-wrong"
                          alt=""
                          title="Remove"
                          src="../../../../assets/button_wrong.png"
                          @click="handleDelete(item)"
                      />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <resize-observer @notify="handleResize" />
          </div>
        </div>
      </div>
    </div>
    <form class="belonging__add-item row mt-3" @submit.prevent="handleSubmit">
      <div class="col-12 col-sm-5 mb-4 mb-sm-0"><input
          class="belonging__input-item"
          type="text"
          placeholder="Insert text"
          v-model="belonging"
          required
      /></div>
      <div class="col-12 col-sm-5 mb-4 mb-sm-0"><input
          type="text"
          placeholder="Insert name"
          v-model="beneficiary"
          required
      /></div>
      <div class="col-12 col-sm-2 mb-4 mb-sm-0">
        <button type="submit" class="icon mx-auto d-block">
          <v-icon name="fa-plus-square" scale="2" fill="#583b75"/>
        </button>
      </div>
    </form>

    <bp-delete-modal
        :callback="deleteBelong"
        :showModal="showModal"
        :close="close"
    />
  </div>
</template>

<script>
import {belongingActionsTypes} from '@/store/modules/belonging'
const {addBelonging, getBelonging, deleteBelonging, updateBelonging} = belongingActionsTypes
import BpDeleteModal from '../../Modals/ConfirmDeleteModal.vue'

export default {
  name: 'BpPets',
  props: ['text', 'quizzID', 'setFilled'],
  components: {BpDeleteModal},
  data() {
    return {
      activeID: null,
      appurtenant: {
        questionId: '',
        id: '',
        belonging: '',
        beneficiary: '',
        filled: true,
      },
      belonging: '',
      beneficiary: '',
      belong: [],
      updateCurrentBelongId: null,
      deleteCurrentBelongId: null,
      showModal: false,
      updateAppurtenant: {
        questionId: null,
        id: null,
        belonging: '',
        beneficiary: '',
      },
      heightBoxInfo: null,
    }
  },
  mounted() {
    this.getBelong()
  },
  methods: {
    handleResize ({ height }) {
      this.heightBoxInfo = height
      console.log(this.heightBoxInfo);
    },
    async getBelong() {
      const belong = await this.$store.dispatch(getBelonging, this.quizzID)
      this.belong = belong.data.belong
    },
    handleSubmit() {
      if (this.belonging && this.beneficiary) {
        const id = (this.belong && this.belong.length !== 0) ? this.belong[this.belong.length - 1].id + 1 : 1
        this.appurtenant = {
          questionId: this.quizzID,
          id,
          belonging: this.belonging,
          beneficiary: this.beneficiary,
          filled: true,
        }
        this.$store.dispatch(addBelonging, this.appurtenant).then((res) => {
          this.belonging = this.beneficiary = ''
          this.belong = res.content.belong
        })
      }
    },
    handleSave() {
      const data = {
        questionId: this.quizzID,
        id: this.updateCurrentBelongId,
        belonging: this.updateAppurtenant.belonging,
        beneficiary: this.updateAppurtenant.beneficiary,
        filled: true,
      }
      this.$store.dispatch(updateBelonging, data)
        .then((res) => {
          this.updateCurrentBelongId = null
          this.belong = res.content.belong
        })
        .catch(console.log)
    },
    handleUpdate(item) {
      this.updateCurrentBelongId = item.id
      this.updateAppurtenant.belonging = item.belonging
      this.updateAppurtenant.beneficiary = item.beneficiary

    },
    handleDelete(item) {
      this.deleteCurrentBelongId = item.id
      this.showModal = true
    },
    deleteBelong() {
      const data = {
        questionId: this.quizzID,
        id: +this.deleteCurrentBelongId
      }
      this.$store
          .dispatch(deleteBelonging, data)
          .then((res) => {
            this.deleteCurrentBelongId = null
            this.belong = res.content.belong
            this.close()
          })
          .catch(Error)
    },
    close() {
      this.showModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.container.modal__body {
  display: block !important;
}
.container.belonging {
  text-align: left;
  word-break: break-word;
}
.belonging__scroll {
  height: 180px;
}
.belonging__scroll--isScroll {
  margin-right: -15px;
}
.belonging__scroll ul {
  margin: 0;
}
.belonging__title-head {
  font-size: 18px;
}
.belonging__controls {
  min-width: 64px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.belonging img {
      width: 32px;
      cursor: pointer;
}
.belonging__btn-save {
  width: 117px;
  height: 38px;

  margin: 5% auto 0;
  border: 1px solid rgba(88, 59, 117, 0.33);
  box-shadow: 5px 5px 5px rgba(88, 59, 117, 0.3);
  border-radius: 2px;
  padding: 1%;
  text-align: center;
  display: block;
  transition: background-color .5s, color .5s;

  &:hover {
    background-color: #583b75;
    color: #fff;
  }
}
.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 5% auto;
}

p {
  padding: 3%;
  margin: 0 auto;
}

input {
  height: 100%;
  width: 100%;
  padding: 2%;
  box-sizing: border-box;
  border: 1px solid rgba(70, 70, 69, 0.3);
  border-radius: 2px;

  &::placeholder {
    color: #a7a7a7;
  }
}

.icon {
  display: flex;
  align-items: center;
}

</style>
