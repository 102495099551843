import axios from '@/api/axios'

const getGuests = () => {
  return axios.get('/guests')
}

const addGuest = guest => {
  return axios.post('/guests/create', guest)
}

const updateGuest = guest => {
  return axios.patch(`/guests/update/${guest.id}`, guest.user)
}

const deleteGuest = id => {
  return axios.delete(`/guests/delete/${id}`)
}
export default {
  getGuests,
  addGuest,
  updateGuest,
  deleteGuest,
}
