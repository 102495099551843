var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal__place-body"},[_c('div',{staticClass:"modal__place-yesNo"},[_c('div',{staticClass:"modal__place-yesNo-content"},_vm._l((_vm.data),function(question,index){return _c('div',{key:index,staticClass:"modal__place-yesNo-content-item"},[_c('h4',[_c('span',[_vm._v(_vm._s(_vm.currentIndex + 1)+". "+_vm._s(question.question))]),(question.description)?_c('div',{staticClass:"modal__place-yesNo-content-item-description"},[_c('p',[_vm._v(_vm._s(question.description))])]):_vm._e()]),_c('div',{staticClass:"modal__place_yesNo_content_item_btns"},_vm._l((question.head),function(btnObj,index){return _c('div',{key:index,ref:'div' + index,refInFor:true,class:[
              {
                modal__place_yesNo_content_item_btns_text:
                  btnObj.imgDown || btnObj.imgUp || btnObj.imgCenter
                    ? false
                    : true,
              },
              {
                modal__place_yesNo_content_item_btns_imgUp: btnObj.imgUp
                  ? true
                  : false,
              },
              {
                modal__place_yesNo_content_item_btns_imgDown: btnObj.imgDown
                  ? true
                  : false,
              },
              {
                modal__place_yesNo_content_item_btns_imgCenter: btnObj.imgCenter
                  ? true
                  : false,
              } ],on:{"click":_vm.moveForward}},[(btnObj.imgUp || btnObj.imgCenter)?_c('img',{attrs:{"src":btnObj.imgUp || btnObj.imgCenter,"alt":index}}):_vm._e(),(btnObj.imgUp || btnObj.imgDown)?_c('h3',[_vm._v(_vm._s(btnObj.head))]):_vm._e(),_c('p',[_vm._v(" "+_vm._s(btnObj.text)+" "),(btnObj.imgUp)?_c('a',{attrs:{"href":"#"}},[_vm._v("Discover more")]):_vm._e()]),(btnObj.imgDown)?_c('img',{attrs:{"src":btnObj.imgDown,"alt":index}}):_vm._e()])}),0)])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }