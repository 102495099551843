<template>
  <div class="container">
    <section class="singup">
      <h5>Sign up</h5>
      <form class="singup__form" @submit.prevent="handleSubmit">
        <div class="singup__name_block">
          <input
            class="singup__input"
            type="text"
            placeholder="Name"
            v-model="user.name"
          />
          <input
            class="singup__input"
            type="text"
            placeholder="Surname"
            v-model="user.surname"
          />
        </div>
        <small
          v-show="!!errors.name"
          v-for="error in errors.name"
          :key="error"
          >{{ error }}</small
        >

        <input
          class="singup__input"
          type="email"
          placeholder="Email address"
          v-model="user.email"
        />
        <small
          v-show="!!errors.email"
          v-for="error in errors.email"
          :key="error"
          >{{ error }}</small
        >

        <input
          class="singup__input"
          type="password"
          placeholder="New password"
          v-model="user.password"
        />
        <small
          v-show="!!errors.password"
          v-for="error in errors.password"
          :key="error"
          >{{ error }}</small
        >

        <p class="singup__terms">
          By clicking Sign Up, you agree to our <a href="#">Terms</a>. Learn how
          we collect, use and share your data in your
          <a href="#">Data Policy </a>and how we use cookies and similar
          technology in our <a href="#">Cookie Policy</a>. You may receive SMS
          notifications from us and can opt out at any time.
        </p>
        <input
          class="singup__button"
          type="submit"
          value="Sign up"
          :disabled="isSubmitting"
        />
      </form>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authActionTypes } from '@/store/modules/auth'
import { loginModalsMutationTypes } from '@/store/modules/loginModals'

export default {
  name: 'BpSignUpForm',

  props: ['closeModal', 'toggleModalType'],
  computed: {
    ...mapState({
      isSubmitting: ({ auth }) => auth.isSubmitting,
      validationErrorsModal: ({ auth }) => auth.validationErrorsModal,
    }),
  },

  data() {
    return {
      user: {
        name: '',
        surname: '',
        email: '',
        password: '',
      },
      errors: {
        name: '',
        email: '',
        password: '',
      },
    }
  },

  methods: {
    handleSubmit() {
      this.$store
        .dispatch(authActionTypes.registerModal, {
          name: `${this.user.name} ${this.user.surname}`,
          email: this.user.email,
          password: this.user.password,
          password_confirmation: this.user.password,
        })
        .then(() => {
          if (this.toggleModalType) {
            this.toggleModalType('confirm')
          }
          this.$store.commit(
            loginModalsMutationTypes.updateLoginProcces,
            'confirm',
          )
        })
    },
    close() {
      this.$emit('close')
    },
  },

  watch: {
    validationErrorsModal(value) {
      if (!value) {
        return
      }
      const { name, email, password } = value
      this.errors.name = name
      this.errors.email = email
      this.errors.password = password
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  .singup {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 9% 0;

    small {
      color: red;
      width: 100%;
      text-align: left;
    }
    h5 {
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      margin-bottom: 3%;
      margin-top: -0%;
    }
    p {
      margin: 2% auto;
      font-size: 11px;
      text-align: center;
      color: #6a6a6a;
      a {
        font-size: 12px;
        color: #536dcb;
      }
    }
    .singup__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .singup__name_block {
      width: 100%;
      display: flex;
      margin: 0 auto;
      &:first-child {
        margin-right: 2%;
      }
    }

    .singup__input {
      width: 100%;
      padding: 2%;
      margin: 3% 0;
      border: 1px solid rgba(70, 70, 69, 0.3);
      border-radius: 2px;
      &:first-child {
        margin-right: 2%;
      }
      &::placeholder {
        color: #a7a7a7;
      }
    }
    .singup__button {
      margin: 5% auto;
      background: #583b75;
      color: #ffffff;
      padding: 13px 79px;

      font-weight: 600;
      font-size: 16px;
      text-align: center;
      border-radius: 2px;
    }
  }
}
</style>
