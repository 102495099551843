<template>
  <div class="modal__menu">
    <ul class="modal__menu-list">
      <li
        class="modal__menu-item"
        v-for="item in menu"
        :key="item.id"
        @click="item.title == 'Logout' ? logout() : setActiveMenu(item.id)"
        :itemid="item.id"
        :class="{ active: activeMenuID === item.id }"
      >
        {{ item.title }}
        <ul class="modal__submenu-list" v-show="item.id === activeMenuID">
          <li
            v-for="subItem in actualSubMenu"
            class="modal__submenu-item"
            :class="{ active: activeSubmenuID === subItem.id }"
            :key="subItem.id"
            @click="setActiveSubmenu(subItem.id)"
          >
            {{ subItem.title }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { authActionTypes } from '@/store/modules/auth'

export default {
  name: 'BpSidebarMenu',
  props: ['menu', 'submenu', 'toggleMenu', 'toggleSubmenu'],
  mounted() {
    if (this.isExecutor) {
      this.setActiveMenu(4)
    }
  },
  computed: {
    ...mapState({
      isExecutor: state => state.auth.currentUser?.is_executor,
    }),
    actualSubMenu() {
      return this.submenu.filter(m => m.menuID === this.activeMenuID)
    },
  },
  data() {
    return {
      activeMenuID: 0,
      activeSubmenuID: 0,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch(authActionTypes.logout)
      this.$router.push({ name: 'main' })
    },
    setActiveMenu(id) {
      this.toggleMenu(id)
      this.activeMenuID = id
    },
    setActiveSubmenu(id) {
      this.toggleSubmenu(id)
      this.activeSubmenuID = id
    },
  },
}
</script>

<style lang="scss" scoped>
.modal__menu {
  display: flex;
  align-items: flex-start;

  &-list {
    text-align: left;
    margin-bottom: 15%;
  }

  &-item {
    width: 100%;
    margin: 10% 0;
    cursor: pointer;
    color: rgba(70, 70, 70, 0.3);
    font-weight: 600;
    font-size: 20px;
    white-space: nowrap;

    &:hover {
      color: #583b75;
    }
    &.active {
      color: #583b75;
    }
  }
}

.modal__submenu {
  &-list {
    text-align: left;
    margin-left: 9%;
  }
  &-item {
    padding: 2%;
    margin: 5% 0;

    cursor: pointer;
    color: rgba(70, 70, 70, 0.7);
    font-weight: 600;
    font-size: 16px;

    &:hover {
      color: #945dc7;
    }
    &.active {
      color: #945dc7;
    }
  }
}
</style>
