import axios from '@/api/axios'

const getContent = title => {
  return axios.get(`/contents?title=${title}`)
}

const getContents = () => {
  return axios.get(`/contents`)
}
export default {
  getContent,
  getContents
}
