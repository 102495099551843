<template>
  <section class="cost mb-5 mx-auto">
    <h4>{{ info.title }}</h4>
    <p>{{ info.text }}</p>
  </section>
</template>

<script>
export default {
  name: 'BpInfo',
  props: ['info'],
  data() {
    return {
      infoTitle: 'Controlling the costs',
      infoText: `According to Sunlife, an insurance company, the average cost of someone
      dying in the UK 2019 was £9.400. That includes the costs of a basic
      funeral, the sending-off ceremony such as the memorial, flowers, venue and
      catering for the wake, etc., and the fees for hiring professionals to
      administer the estate. Good planning will not only help you create a
      funeral you can be proud of, but will also help you to manage the costs.`,
    }
  },
}
</script>

<style lang="scss" scoped>
.cost {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);

  h4 {
    font-weight: bold;
    font-size: 32px;

    text-align: center;
    text-transform: uppercase;
    padding: 6% 10% 0;
    margin-bottom: 3%;
  }

  p {
    font-size: 18px;
    line-height: 150%;
    padding: 0 10% 4% 6%;
    text-align: center;
  }
}
</style>
