<template>
    <main class="about">
      <bp-banner :title="banner.title" :subtitle="banner.subtitle" />
      <div class="container">
            <bp-info :info="info" />
            <bp-usp class="indent" />
        </div>
    </main>
</template>

<script>
import BpBanner from '../../components/Banner.vue';
import BpInfo from '../../components/Info.vue';
import BpUsp from '../../components/Usp.vue';

export default {
    name: 'BpAbout',
    components: {
        BpInfo,
        BpBanner,
        BpUsp
    },
    data() {
        return {
            info: {
                title: 'About efter',
                text:
                'When you plan ahead, it takes a lot of the stress out of planning for a death. You can control the costs and make sure everyone’s wishes are respected when you make arrangements with plenty of time to spare. Start exploring your options today.',
            },
            banner: {
                title: 'about us',
                subtitle: 'Planning for the future brings peace of mind - whether you’re preparing for your own death or that of someone close to you. Answer some simple questions about what you have in mind. Our comparison tool will then show you all the options within your budget for the perfect send-off.',
            },
        }
    },
}
</script>
