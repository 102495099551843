<template>
  <main class="lagal">
    <bp-banner :title="banner.title" :subtitle="banner.subtitle"/>
    <div class="container">
      <bp-info :info="info"/>
      <bp-table/>
      <div class="sing-up-form row">
        <div class="col-12">
          <bp-sign-up-form v-if="loginModalProcces !== 'confirm'"/>
          <bp-confirm-form v-if="loginModalProcces === 'confirm'"/>
          <bp-confirm-done v-if="loginModalProcces === 'done'"/>
        </div>
      </div>
      <bp-usp/>
      <bp-faq :title="faq.title" :data="faq.data"/>
    </div>
  </main>
</template>

<script>
import {mapState} from 'vuex'

import BpBanner from '../../components/Banner.vue';
import BpInfo from '../../components/Info.vue';
import BpUsp from '../../components/Usp.vue';
import BpTable from '../../components/BpTable.vue';
import BpSignUpForm from '../../components/Forms/SignUpForm.vue'
import BpConfirmForm from '../../components/Modals/Login/ConfirmModal.vue'
import BpConfirmDone from '../../components/Modals/Login/DoneModal.vue'
import BpFaq from '../../components/Faq.vue'

export default {
  name: 'BpLegal',
  components: {
    BpInfo,
    BpBanner,
    BpUsp,
    BpTable,
    BpSignUpForm,
    BpConfirmForm,
    BpConfirmDone,
    BpFaq
  },
  computed: {
    ...mapState({
      loginModalProcces: ({loginModals}) => loginModals.procces,
    }),
  },
  data() {
    return {
      info: {
        title: 'About efter',
        text:
            'When you plan ahead, it takes a lot of the stress out of planning for a death. You can control the costs and make sure everyone’s wishes are respected when you make arrangements with plenty of time to spare. Start exploring your options today.',
      },
      banner: {
        title: 'legal issues',
        subtitle: 'Planning for the future brings peace of mind - whether you’re preparing for your own death or that of someone close to you. Answer some simple questions about what you have in mind. Our comparison tool will then show you all the options within your budget for the perfect send-off.',
      },
      faq: {
        title: 'About Wills and Probate',
        data: [
          {
            ask: 'Which types of payment can I make?',
            answer:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
            id: 0,
            isShown: false,
          },
          {
            ask: 'Which types of payment can I make?',
            answer:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
            id: 1,
            isShown: false,
          },
          {
            ask: 'Which types of payment can I make?',
            answer:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
            id: 2,
            isShown: false,
          },
          {
            ask: 'Which types of payment can I make?',
            answer:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
            id: 3,
            isShown: false,
          },
        ],
      },
    }
  },
}
</script>
