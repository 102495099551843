<template>
  <div class="container">
    <header class="header">
      <button
          v-if="!showNav"
          class="header__toggle-menu"
          @click="openNav"
      >
        <svg class="header__hamburger" width="22" height="14" viewBox="0 0 22 14" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <rect width="22" height="1.6" fill="black"/>
          <rect y="6.1001" width="22" height="1.6" fill="black"/>
          <rect y="12.2002" width="22" height="1.6" fill="black"/>
        </svg>
      </button>
      <button
          v-else
          class="header__toggle-menu"
          @click="closeNav"
      >
        <svg class="header__close-menu" width="16" height="16" viewBox="0 0 16 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <rect x="1.13086" y="0.14209" width="20" height="1.6" transform="rotate(45 1.13086 0.14209)" fill="black"/>
          <rect y="14.1421" width="20" height="1.6" transform="rotate(-45 0 14.1421)" fill="black"/>
        </svg>
      </button>
      <h1 class="header__title">
        <router-link to="/">{{ header }}</router-link>
      </h1>
      <div class="header__user" @click.stop="toggleModal">
        <a v-show="isLoggedIn" class="header__username" href="#">{{ name }}</a>
        <img src="../assets/user icon.png" alt=""/>
      </div>

      <bp-login-modal v-if="modalIsShown" @close="close"/>
    </header>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { notificationGettersTypes } from '@/store/modules/notificationModals'
import BpLoginModal from './Modals/Login/Modals.vue'
import {toggleNavbarMutationsTypes} from "@/store/modules/toggleNavbar";

export default {
  name: 'BhHeader',
  components: { BpLoginModal },

  data() {
    return {
      header: 'efter',
      modalIsShown: false,
      name: '',
    }
  },

  computed: {
    ...mapState({
      currentUser: ({ auth }) => auth.currentUser,
      isLoggedIn: ({ auth }) => auth.isLoggedIn,
    }),
    ...mapGetters({
      notificationModalsIsShown:
        notificationGettersTypes.notificationModalsIsShown,
      notificationModalsProcces:
        notificationGettersTypes.notificationModalsProcces,
      notificationModalsMessage:
        notificationGettersTypes.notificationModalsMessage,
    }),
    ...mapState({
      showNav: state => state.toggleNavbar.isShowNav,
    }),
  },

  methods: {
    ...mapMutations({
      openNavbar: toggleNavbarMutationsTypes.openNavbar,
      closeNavbar: toggleNavbarMutationsTypes.closeNavbar,
    }),
    openNav() {
      this.openNavbar()
    },
    closeNav() {
      this.closeNavbar()
    },
    toggleModal() {
      if (this.isLoggedIn) {
        return
      }

      this.modalIsShown = !this.modalIsShown
    },

    close() {
      this.modalIsShown = false
    },
  },
  watch: {
    currentUser(value) {
      if (!value) {
        return
      }
      this.name = value.name
    },
  },
}
</script>

<style lang="scss">
.header__toggle-menu {
  width: 22px;
  flex-shrink: 0;
  position: relative;
  z-index: -10;
  opacity: 0;
  transition: opacity .5s;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0;

  .header__title {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    text-shadow: 0 5px 5px rgba($color: #000000, $alpha: 0.5);

    a {
      text-decoration: none;
      &:hover {
        color: #000000;
      }
    }
  }
  .header__user {
    flex-shrink: 0;
    width: 16px;
  }
}
@media (max-width: 992px) {
  .header__toggle-menu {
    z-index: 10;
    opacity: 1;
  }
}
</style>
