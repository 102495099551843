<template>
    <main class="container">
        <section class="intro">
            <img src="./../../assets/Articles/Area/banner.png" alt="banner">
        </section>
        <bp-sign-up-form />
        <bp-usp />
        <section class="content">
            <div class="content-item-1-ceremonies_before">
                <img src="./../../assets/Articles/InspireMe/ceremony1.png" alt="ceremony1">
                <div>
                    <h5>ceremonies before</h5>
                    <h3>Fusce eu rutrum risus. Nunc sit massa quis ligula sodales</h3>
                    <p>Praesent pretium facilisis velit. Pellentesque porta odio ante, vel interdum eros semper in. Mauris pretium sapien quis metus facilisis, fermentum hendrerit tortor dapibus.</p>
                </div>
            </div>
            <div class="content-item-2-ceremonies_before">
                <img src="./../../assets/Articles/InspireMe/ceremony2.png" alt="ceremony2">
                <div>
                    <h5>ceremonies before</h5>
                    <h3>Fusce eu rutrum risus. Nunc sit massa quis ligula sodales</h3>
                    <p>Praesent pretium facilisis velit. Pellentesque porta odio ante, vel interdum eros semper in. Mauris pretium sapien quis metus facilisis, fermentum hendrerit tortor dapibus.</p>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
    import BpUsp from './../Usp.vue'
    import bpSignUpForm from '../Forms/SignUpForm.vue'
    export default {
        name: 'BpArticleArea',
        components: { BpUsp, bpSignUpForm },
    }
</script>

<style lang="scss" scoped>
    .container {
        width: 75%;
        max-width: 1920px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 0 auto;
    }
    .intro {
        margin: 2% 0 0 0;
    }
    .content {
        font-family: 'Noto Sans';
        display: flex;
        flex-direction: column;
        margin-bottom: 7%;
        margin-top: 7%;
        .content-item-1-ceremonies_before {
            position: relative;
            margin-bottom: 5%;
            div {
                background: #FFFFFF;
                box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.1);
                border-radius: 2px;
                width: 45%;
                position: absolute;
                top: 25%;
                left: -1%;

                text-align: center;

                padding: 3% 5%;
                h5 {
                    text-transform: uppercase;
                    color: #583B75;
                    font-weight: 600;
                }
                h3 {
                    text-transform: uppercase;
                    color: #000000;
                    font-size: 24px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
        .content-item-2-ceremonies_before {
            position: relative;
            margin-bottom: 5%;
            div {
                background: #FFFFFF;
                box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.1);
                border-radius: 2px;
                width: 45%;
                position: absolute;
                top: 25%;
                right: -1%;

                text-align: center;

                padding: 3% 5%;
                h5 {
                    text-transform: uppercase;
                    color: #583B75;
                    font-weight: 600;
                }
                h3 {
                    text-transform: uppercase;
                    color: #000000;
                    font-size: 24px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
    }
</style>
