<template>
  <section class="usp row mb-5">
    <div class="usp__card col-12 col-md-6 col-lg-4 mb-4">
      <img class="usp__card-img" src="../assets/light.png" alt="light" />
      <h4>Expert advice on arrangements</h4>
      <p>
        Get help organising everything, from choosing a funeral director to when
        and how to book the service and reception
      </p>
    </div>
    <div class="usp__card col-12 col-md-6 col-lg-4 mb-4">
      <img class="usp__card-img" src="../assets/bird.png" alt="bird" />
      <h4>Free with no obligations</h4>
      <p>
        Use our tool to help you plan, with no obligation. If you choose one of
        the funeral directors or services listed here they pay us, so there is
        no extra cost for you
      </p>
    </div>
    <div class="usp__card col-12 col-md-6 col-lg-4 mx-auto mt-0 mt-md-4 m-lg-0">
      <img class="usp__card-img" src="../assets/search.png" alt="search" />
      <h4>Overview of all options</h4>
      <p>
        We will show you options within your budget based on your preferences.
        You can explore all the options and compare a variety of services at
        once
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BpUrp',
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.usp {
  .usp__card {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 5%;

    &:not(:last-child) {
      border-right: 1px solid rgba(70, 70, 69, 0.3);
    }

    .usp__card-img {
      padding-bottom: 10%;
    }
    h4 {
      font-size: 20px;
      font-style: normal;
      text-align: center;
      margin-bottom: 5%;
    }
    p {
      font-size: 14px;
      text-align: center;
      margin-bottom: 0;
    }
  }
}
@media (max-width: 992px) {
  .usp .usp__card {
    border-right: none !important;
  }
}
</style>
