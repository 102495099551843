<template>
  <div>
    <img src="../../../assets/Done.png" alt="done" width="50%" />
    <h5>{{ message }}</h5>
    <button @click="toggleModalType('signIn')">OK</button>
  </div>
</template>

<script>
export default {
  name: 'BpDoneModal',
  props: ['message', 'toggleModalType'],
}
</script>

<style lang="scss" scoped>
div {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0;
}
h5 {
  width: 70%;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: #583b75;

  margin: 10% auto;
}
button {
  margin-top: 15%;
  margin-bottom: 5%;
  background: #583b75;
  color: #ffffff;
  padding: 3% 18%;

  box-shadow: 0px 10px 10px rgba(88, 59, 117, 0.3);

  font-weight: 600;
  font-size: 16px;
  text-align: center;
  border-radius: 2px;
}
</style>
