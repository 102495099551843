<template>
  <div class="body">
    <div
      class="body-item"
      v-for="item in inTable"
      :key="item.questionID"
      @click="showModal(item.questionID)"
    >
      <div class="body-item-left">
        <div class="body-item-left-question">
          <span>{{ getDesc(item.questionID) }}: </span>
          <b v-if="item.answer.title">{{ item.answer.title }}</b>
        </div>
      </div>
      <div class="body-item-right">
        <div class="body-item-right-cost">
          <span>£{{ item.answer.price || 0 }}</span>
        </div>
        <div class="my_plan-body-item-right-icon">
          <img
            v-if="!item.answer.title"
            src='../../../../assets/MyPlanTable/button.png'
            alt="button"
          />
          <img
            class="like"
            v-if="item.answer.title"
            src='../../../../assets/MyPlanTable/button_done.png'
            alt="button"
          />
        </div>
      </div>
    </div>
    <bh-my-table-modal
      v-if="modalIsShown"
      :cards="cards"
      @toggle="toggleModal"
      :title="title"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { quizzesGettersTypes } from '@/store/modules/quizzesModals'
import BhMyTableModal from '@/components/UserProfile/MyPlan/components/ModalWindow'
export default {
  name: 'BodyTable',
  props: ['answers'],
  components: { BhMyTableModal },
  computed: {
    ...mapGetters({
      quizzes: quizzesGettersTypes.quizzes,
    }),
    inTable() {
      return this.answers?.filter(
        a => a.answer.text === 'YES' || a.answer.text.length > 3,
      )
    },
  },
  data() {
    return {
      modalIsShown: false,
      cards: [],
      title: '',
    }
  },
  methods: {
    toggleModal() {
      this.modalIsShown = !this.modalIsShown
    },
    showModal(id) {
      this.toggleModal()
      const [curr] = this.quizzes?.items.filter(q => q.quizzID === id)
      this.title = curr.quizzDesc
      this.cards = curr.quizzData.data.length
        ? curr.quizzData.data
        : curr.quizzData.options
    },

    getDesc(id) {
      const { quizzDesc } = this.quizzes?.items.find(q => q.quizzID === id)
      return quizzDesc
    },

    getAntwer(id) {
      const { quizzData } = this.quizzes?.items.find(q => q.quizzID === id)
      /* const {  } = quizzData?.data.find(d => d.id === id) */
      return quizzData.data[0]
    },
  },
}
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border-bottom: 1px solid #464646;

    padding: 1% 0;

    &:last-child {
      border-bottom: 0;
    }

    &-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 75%;

      &-answer {
        font-weight: 550;
      }
      b {
        font-weight: bold;
      }
    }

    &-right {
      width: 25%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .like {
        width: 40px;
        margin-left: 9%;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
