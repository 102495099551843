<template>
  <div>
    <section class="my_plan-title my-5">
      <h2>my plan</h2>
    </section>
    <section class="my_plan-table row mb-5 justify-content-center">
      <div v-for="(img, index) in images" :key="index"
           class="col-12 col-sm-6 col-md-4 col-lg-3"
           @mouseover="mouseOver(img.id)"
           @mouseleave="mouseLeave"
      >
        <div class="position-relative">
          <img class="mb-4" :src="url + img.img" :alt="img.rowText"/>
          <div :class="['mask-hover', {'showBg': activeHover === img.id}]"></div>
          <span :class="['rowText', {'show': activeHover === img.id}]">{{ img.rowText }}</span>
        </div>
      </div>
    </section>
    <bh-total-price :totalCost="totalCost"/>
    <MainTable
        :heading="'The Funeral'"
        :footer="'Funeral cost'"
        :answers="funerals"
        :cost="funeralsCosts"
    />
    <MainTable
        :heading="'The Ceremonies'"
        :footer="'Ceremony cost'"
        :answers="ceremonys"
        :cost="ceremonysCosts"
    />
    <section class="legalTable mb-5">
      <div class="head">
        <h3>legal costs</h3>
      </div>
      <div class="body-row">
        <div>Doctors fee</div>
        <div class="price">£{{ legalObj.doctorsFee }}</div>
      </div>
      <div class="body-row">
        <div v-show="!cremationFee">Burial fee</div>
        <div v-show="cremationFee">Cremation fee</div>
        <div class="price">£{{ legalObj.funeralFee }}</div>
      </div>
      <div class="footer-row">
        <div>Legal costs</div>
        <div class="price">£{{ legalCosts }}</div>
      </div>
    </section>
    <section class="list-not-apply mb-5">
      <h3 class="not-apply">Does not Apply</h3>
      <ul class="apply-list not-apply">
        <li v-for="item in notInTable" :key="item.questionID">
          <span class="not-apply-text">{{ getDesc(item.questionID) }}</span>
          <div class="not-apply-img">
            <img
                src="../../../assets/not-apply-plus.png"
                class="icon-right"
                alt=""
                @click="toggleTooltip(item.questionID)"
            />
          </div>
          <bh-tooltip v-if="activeTooltip == item.questionID">
            <span class="text"
            >Are you sure you want to add this to the To-Do-list?!
            </span>
            <button
                class="tooltip-btn"
                v-for="option in getAnswers(item.questionID)"
                :key="option.optionsID"
                @click.stop="handleClick(option)"
            >
              {{ option.text }}
            </button>
          </bh-tooltip>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import MainTable from './components/Table/MainTable.vue'
import BhTotalPrice from './components/Table/components/TotalPrice'
import BhTooltip from '../Wishlist/Modals/Tooltip.vue'

import {
  quizzesGettersTypes,
  quizzesActionsTypes,
} from '@/store/modules/quizzesModals'

const {changeAnswer} = quizzesActionsTypes
export default {
  name: 'Main',
  components: {
    MainTable,
    BhTotalPrice,
    BhTooltip,
  },
  data() {
    // FIXME: url must be in backend
    return {
      url: 'http://efter-b.pandateam.net/',
      showModal: false,
      activeTooltip: null,
      activeHover: null,
      legalObj: {
        doctorsFee: 120,
        funeralFee: 80,
      },
    }
  },
  computed: {
    ...mapState({
      quizzes: state => state.quizzesModals.quizzes.items,
    }),
    ...mapGetters({
      answers: quizzesGettersTypes.answers,
      tableFiteredBy: quizzesGettersTypes.tableFiteredBy,
    }),
    images() {
      return this.answers
          ?.map(({answer}) => {
            if (!answer?.img && !answer?.title) {
              return
            }
            return {
              img: answer?.img,
              rowText: answer?.title,
              id: answer?.dataID ? answer?.dataID : answer?.optionsID ? answer?.optionsID : answer?.questionID
            };
          })
          .filter(Boolean)
    },
    ceremonys() {
      const tableIDs = this.tableFiteredBy('The Ceremonies')?.map(
          c => c.quizzID,
      )
      return this.answers?.filter(a => tableIDs.includes(a.questionID))
    },
    funerals() {
      const tableIDs = this.tableFiteredBy('The Funeral')?.map(c => c.quizzID)
      return this.answers?.filter(a => tableIDs.includes(a.questionID))
    },
    notInTable() {
      const tables = [...this.ceremonys, ...this.funerals]
      return tables.filter(a => a.answer.text === 'NO')
    },
    ceremonysCosts() {
      return this.ceremonys?.reduce(
          (acc, {answer}) => (answer.price ? acc + answer.price : acc),
          0,
      )
    },
    funeralsCosts() {
      return this.funerals?.reduce(
          (acc, {answer}) => (answer.price ? acc + answer.price : acc),
          0,
      )
    },
    cremationFee() {
      let cremation = false;

      for (let item of this.funerals) {
        if (item.answer.text !== "NO") {
          cremation = item.answer.title?.toLowerCase().includes('cremation');
        }

        if (cremation) {
          return cremation;
        }
      }

      return cremation;
    },
    legalCosts() {
      let sum = 0;

      if (this.cremationFee) {
        this.setFuneralFee(100);
      } else {
        this.setFuneralFee(80);
      }

      for (let val in this.legalObj) {
        sum += this.legalObj[val];
      }
      return sum;
    },
    totalCost() {
      return this.funeralsCosts + this.ceremonysCosts + this.legalCosts;
    },
  },
  methods: {
    setFuneralFee(fee) {
      this.legalObj['funeralFee'] = +fee;
    },
    mouseOver(id) {
      this.activeHover = id;
    },
    mouseLeave() {
      this.activeHover = null;
    },
    toggleTooltip(id) {
      this.activeTooltip = this.activeTooltip !== id ? id : null
    },
    handleClick(answer) {
      if (answer.text === 'YES') {
        this.$store.dispatch(changeAnswer, {
          questionID: this.activeTooltip,
          answer,
        })
      }
      this.activeTooltip = null
    },
    getDesc(id) {
      const {quizzDesc} = this.quizzes?.find(q => q.quizzID === id)
      return quizzDesc
    },
    getAnswers(id) {
      const {quizzData} = this.quizzes?.find(q => q.quizzID === id)
      return quizzData.options
    },
    close() {
      this.showModal = false;
      this.deleteModalIsShown = false;
      this.activeTooltip = null;
    },
  },
}
</script>

<style lang="scss" scoped>
.red {
  color: red;
}

.list-not-apply {
  text-align: left;
  font-size: 24px;

  .not-apply {
    color: #9b9b9b;

    &-text {
      flex-basis: 87%;
    }

    li {
      font-size: 17px;
      margin: 2% 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      span {
        flex-basis: 100%;
        text-align: start;
      }

      .not-apply-img {
      }
    }
  }
}

.tooltip-btn {
  width: 25%;
  border: 1px solid rgba(88, 59, 117, 0.33);
  border-radius: 2px;
  margin: 5%;
  text-align: center;

  &:hover {
    background-color: #583b75;
    box-shadow: 5px 5px 5px rgba(88, 59, 117, 0.3);

    color: #fff;
  }
}

.legalTable {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .price {
    width: 25%;
    display: flex;
    justify-content: flex-start;
  }

  div {
    padding: 0.5% 0;
  }

  .head {
    h3 {
      text-transform: capitalize;
      font-weight: 550;
      font-size: 24px;
    }
  }

  .body-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footer-row {
    font-weight: 550;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid #cccccc;
    border-bottom: 2px solid #cccccc;
  }
}

.my_plan-title h2 {
  text-align: center;
  text-transform: uppercase;
  font-weight: 550;
}

.my_plan-table {
  div {
    .mask-hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #583b75;
      opacity: 0;
      transition: all .5s;
    }

    &:nth-child(4n) {
      margin: 0 0 1% 0;
    }

    img {
      width: 100%;
      height: auto;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      opacity: 0;
      transition: all .5s;
      font-weight: 550;
      text-align: center;
    }

    .show {
      opacity: 1;
    }

    .showBg {
      opacity: .7;
    }
  }
}
</style>
