<template>
  <div class="modal__container" @click.stop>
    <v-icon name="fa-check-circle" scale="2" fill="#583b75" />
    <h5>{{ message }}</h5>
    <span @click.prevent="close" class="modal__close">&#x2715;</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  notificationGettersTypes,
  notificationMutationsTypes,
} from '@/store/modules/notificationModals'

const { toggleNotificationModals } = notificationMutationsTypes

export default {
  name: 'BpToast',
  components: {},
  computed: {
    ...mapGetters({
      message: notificationGettersTypes.notificationModalsMessage,
      isShown: notificationGettersTypes.notificationModalsIsShown,
    }),
  },

  data() {
    return {}
  },

  methods: {
    close() {
      this.$store.commit(toggleNotificationModals)
    },
  },
  watch: {
    isShown(value) {
      console.log('value ->', value) // eslint-disable-line no-console
    },
  },
}
</script>

<style lang="scss" scoped>
.modal__container {
  width: 370px;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-left: 3px solid #583b75;
  position: fixed;
  top: 4rem;
  right: 2rem;

  .modal__close {
    /* position: absolute; */
    /* top: 10px; */
    /* right: 13px; */
    cursor: pointer;
  }
  h5 {
    width: 70%;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: #583b75;
    margin: 4% auto;
  }
}
</style>
