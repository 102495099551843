import legalApi from '@/api/legal'

const state = {
  activeWishlistID: -1,
  wishlist: [],
  filled: [],
  errors: null,
}

export const legalMutationsTypes = {
  toggleActiveWishlistID: 'legal/toggleWishlistID',

  updateUserInfoStart: 'legal/updateUserInfoStart',
  updateUserInfoSuccess: 'legal/updateUserInfoSuccess',
  updateUserInfoFailure: 'legal/updateUserInfoFailure',

  updateExecutorInfoStart: 'legal/updateExecutorInfoStart',
  updateExecutorInfoSuccess: 'legal/updateExecutorInfoSuccess',
  updateExecutorInfoFailure: 'legal/updateExecutorInfoFailure',

  getUserInfoStart: 'legal/getUserInfoStart',
  getUserInfoSuccess: 'legal/getUserInfoSuccess',
  getUserInfoFailure: 'legal/getUserInfoFailure',

  getExecutorInfoStart: 'legal/getExecutorInfoStart',
  getExecutorInfoSuccess: 'legal/getExecutorInfoSuccess',
  getExecutorInfoFailure: 'legal/getExecutorInfoFailure',

  getNotesStart: 'legal/getNotesStart',
  getNotesSuccess: 'legal/getNotesSuccess',
  getNotesFailed: 'legal/getNotesFailure',

  updateNotesStart: 'legal/updateNotesStart',
  updateNotesSuccess: 'legal/updateNotesSuccess',
  updateNotesFailed: 'legal/updateNotesFailure',

  getFilledInfoStart: 'legal/getFilledInfoStart',
  getFilledInfoSuccess: 'legal/getFilledInfoSuccess',
  getFilledInfoFailure: 'legal/getFilledInfoFailure',
}
const mutations = {
  [legalMutationsTypes.toggleWishlistID](state, payload) {
    state.activeWishlistID = payload
  },
  [legalMutationsTypes.setFilled](state, payload) {
    state.filled = payload
  },
  [legalMutationsTypes.updateFilled](state, payload) {
    state.filled.push(payload)
  },

  // update
  [legalMutationsTypes.updateUserInfoStart](state) {
    state.isLoading = true
  },
  [legalMutationsTypes.updateUserInfoSuccess](state) {
    state.isLoading = false
    state.errors = null
  },
  [legalMutationsTypes.updateUserInfoFailure](state, payload) {
    state.isLoading = false
    state.errors = payload
  },
  [legalMutationsTypes.updateExecutorInfoStart](state) {
    state.isLoading = true
  },
  [legalMutationsTypes.updateExecutorInfoSuccess](state) {
    state.isLoading = false
    state.errors = null
  },
  [legalMutationsTypes.updateExecutorInfoFailure](state, payload) {
    state.isLoading = false
    state.errors = payload
  },

  [legalMutationsTypes.updateNotesStart](state) {
    state.isLoading = true
  },
  [legalMutationsTypes.updateNotesSuccess](state) {
    state.isLoading = false
    state.errors = null
  },
  [legalMutationsTypes.updateNotesFailed](state, payload) {
    state.isLoading = false
    state.errors = payload
  },
  [legalMutationsTypes.getNotesStart](state) {
    state.isLoading = true
  },
  [legalMutationsTypes.getNotesSuccess](state) {
    state.isLoading = false
    state.errors = null
  },
  [legalMutationsTypes.getNotesFailed](state, payload) {
    state.isLoading = false
    state.errors = payload
  },

  // get
  [legalMutationsTypes.getUserInfoStart](state) {
    state.isLoading = true
  },
  [legalMutationsTypes.getUserInfoSuccess](state) {
    state.isLoading = false
    state.errors = null
  },
  [legalMutationsTypes.getUserInfoFailure](state, payload) {
    state.isLoading = false
    state.errors = payload
  },

  [legalMutationsTypes.getExecutorInfoStart](state) {
    state.isLoading = true
  },
  [legalMutationsTypes.getExecutorInfoSuccess](state) {
    state.isLoading = false
    state.errors = null
  },
  [legalMutationsTypes.getExecutorInfoFailure](state, payload) {
    state.isLoading = false
    state.errors = payload
  },
  [legalMutationsTypes.getFilledInfoStart](state) {
    state.isLoading = true
  },
  [legalMutationsTypes.getFilledInfoSuccess](state, payload) {
    state.isLoading = false
    state.errors = null
    state.filled = payload
  },
  [legalMutationsTypes.getFilledInfoFailure](state, payload) {
    state.isLoading = false
    state.errors = payload
  },
}

export const legalActionsTypes = {
  updateUserInfo: 'legal/updateUserInfo',
  getUserInfo: 'legal/getUserInfo',
  updateExecutorInfo: 'legal/updateExecutorInfo',
  getExecutorInfo: 'legal/getExecutorInfo',
  getFilledInfo: 'legal/getFilledInfo',
  getNotes: 'legal/getNotes',
  updateNotes: 'legal/updateNotes',
}
const actions = {
  [legalActionsTypes.getUserInfo]({ commit }, id) {
    return new Promise(resolve => {
      commit(legalMutationsTypes.getUserInfoStart)
      legalApi
        .getUserInfo(id)
        .then(response => {
          commit(legalMutationsTypes.getUserInfoSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(legalMutationsTypes.getUserInfoFailure)
        })
    })
  },
  [legalActionsTypes.updateUserInfo]({ commit }, info) {
    return new Promise(resolve => {
      commit(legalMutationsTypes.updateUserInfoStart)
      legalApi
        .updateUserInfo(info)
        .then(response => {
          commit(legalMutationsTypes.updateUserInfoSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(legalMutationsTypes.updateUserInfoFailure)
        })
    })
  },
  [legalActionsTypes.getExecutorInfo]({ commit }, id) {
    return new Promise(resolve => {
      commit(legalMutationsTypes.getExecutorInfoStart)
      legalApi
        .getExecutorInfo(id)
        .then(response => {
          commit(legalMutationsTypes.getExecutorInfoSuccess, response.data)
          resolve(response.data)
        })
        .catch(response => {
          commit(legalMutationsTypes.getExecutorInfoFailure, response.errors)
        })
    })
  },
  [legalActionsTypes.updateExecutorInfo]({ commit }, info) {
    return new Promise(resolve => {
      commit(legalMutationsTypes.updateExecutorInfoStart)
      legalApi
        .updateExecutorInfo(info)
        .then(response => {
          commit(legalMutationsTypes.updateExecutorInfoSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(legalMutationsTypes.updateExecutorInfoFailure)
        })
    })
  },
  [legalActionsTypes.getNotes]({ commit }, id) {
    return new Promise(resolve => {
      commit(legalMutationsTypes.getNotesStart)
      legalApi
        .getNotes(id)
        .then(response => {
          commit(legalMutationsTypes.getNotesSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(legalMutationsTypes.getNotesFailed)
        })
    })
  },
  [legalActionsTypes.updateNotes]({ commit }, info) {
    return new Promise(resolve => {
      commit(legalMutationsTypes.updateNotesStart)
      legalApi
        .updateNotes(info)
        .then(response => {
          commit(legalMutationsTypes.updateNotesSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(legalMutationsTypes.updateNotesFailed)
        })
    })
  },

  [legalActionsTypes.getFilledInfo]({ commit }, ids) {
    return new Promise(resolve => {
      commit(legalMutationsTypes.getFilledInfoStart)
      legalApi
        .getFilledInfo(ids)
        .then(response => {
          commit(legalMutationsTypes.getFilledInfoSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(legalMutationsTypes.getFilledInfoFailure)
        })
    })
  },
}

export const legalGetterTypes = {
  wishlist: 'legal/wishlist',
  filled: 'legal/filled',
  wishlistAplly: 'legal/wishlistAplly',
  wishlistNotApply: 'legal/wishlistNotApply',
  currentWishlistItem: 'legal/currentWishlistItem',
}

const getters = {
  [legalGetterTypes.activeWishlistID]: state => {
    return state.activeWishlistID
  },
  [legalGetterTypes.wishlist]: state => {
    return state.wishlist
  },
  [legalGetterTypes.filled]: state => {
    const arr = state.filled.questions_filled
    if (state.filled.executor_filled) {
      arr.unshift(0)
    }
    return arr
  },
  [legalGetterTypes.currentWishlistItem]: state => {
    return state.wishlist.filter(i => i.id === state.activeWishlistID)
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
