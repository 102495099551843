import axios from '@/api/axios'

const getBelonging = (id) => {
    return axios.get(`/quizes/legal/get-modal?question_id=${id}`)
}

const addBelonging = data => {
    return axios.post('/quizes/legal/create', data)
}

const updateBelonging = data => {
    return axios.patch('/quizes/legal/update', data)
}

const deleteBelonging = data => {
    return axios.delete('/quizes/legal/delete', {data})
}
export default {
    getBelonging,
    addBelonging,
    updateBelonging,
    deleteBelonging,
}
