<template>
  <div class="footer">
    <div class="footer-container">
      <div class="footer-container-label">
        <span>{{ footer }}</span>
      </div>
      <div class="footer-container-cost">
        <span>£{{ cost }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterTable',
  props: ['footer', 'cost'],
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;

  border-bottom: 2px solid #583b75;
  border-top: 2px solid #583b75;

  padding: 1% 0;

  &-container {
    display: flex;
    justify-content: flex-end;
    font-weight: 550;
    width: 100%;

    &-label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 72%;
      margin-right: 3%;
    }

    &-cost {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 25%;
    }
  }
}
</style>
