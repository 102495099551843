<template>
  <div class="wrapper container">
    <section class="you-choices row">
      <div class="col-12"><h3>Your choices</h3></div>
      <div class="col-12">
        <div
          class="you-choices-item"
          v-show="item.type"
          v-for="(item, index) in answers"
          :key="index"
        >
          <div>{{ item.type }}:</div>
          <div>{{ item.title }}</div>
        </div>
      </div>
    </section>
    <section class="row">
      <div class="col-12">
        <div class="table-body">
          <div
            class="table-body-item"
            v-show="item.type !== 'Location' && item.type"
            v-for="(item, index) in answers"
            :key="index"
          >
            <div class="table-body-item-left">
              <div class="table-body-item-left-question">
                <span>{{ item.typeCost }}</span>
              </div>
              <div class="table-body-item-left-cost">
                <span>£{{ item.price ? item.price : 0 }}</span>
              </div>
            </div>
            <div @click="toggleTooltip(item.id)" class="table-body-item-right">
              <div class="table-body-item-right-icon">
                <div :class="['icon', { unshow: activeToolTip == item.id }]">
                  <img src="./../assets/info-unclicked.png" alt="button" />
                </div>
                <div v-show="activeToolTip == item.id" class="icon">
                  <img src="./../assets/info-clicked.png" alt="icon" />
                </div>
              </div>
              <bh-tooltip
                :isUnregistered="true"
                v-if="activeToolTip == item.id"
              >
                <span class="text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum volutpat vitae massa vitae imperdiet. Quisque
                  varius risus at faucibus laoreet. Integer quis lorem a metus
                  tristique rhoncus. Etiam aliquet quis orci eu vulputate.
                </span>
              </bh-tooltip>
            </div>
          </div>
          <div class="table-body-item">
            <div class="table-body-item-left">
              <div class="table-body-item-left-question">
                <span>Legal costs</span>
              </div>
              <div class="table-body-item-left-cost">
                <span>£{{ legalCost }}</span>
              </div>
            </div>
            <div @click="toggleTooltip(idLegal)" class="table-body-item-right">
              <div class="table-body-item-right-icon">
                <div :class="['icon', { unshow: activeToolTip == idLegal }]">
                  <img src="./../assets/info-unclicked.png" alt="button" />
                </div>
                <div v-show="activeToolTip == idLegal" class="icon">
                  <img src="./../assets/info-clicked.png" alt="icon" />
                </div>
              </div>
              <bh-tooltip
                :isUnregistered="true"
                v-if="activeToolTip == idLegal"
              >
                <span class="text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum volutpat vitae massa vitae imperdiet. Quisque
                  varius risus at faucibus laoreet. Integer quis lorem a metus
                  tristique rhoncus. Etiam aliquet quis orci eu vulputate.
                </span>
              </bh-tooltip>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="row mb-5">
      <div class="col-12">
        <div class="table-footer">
          <div class="table-footer-container">
            <div class="table-footer-container-label">
              <span>TOTAL COST:</span>
              <div class="table-footer-container-label-cost">
                <span>£{{ totalPrice + legalCost }}</span>
              </div>
            </div>
            <div class="table-footer-container-icon">
              <img src="./../assets/Vector.png" alt="icon" />
            </div>
          </div>
          <div class="table-footer-desc">
            <p>
              The prices shown are general in your area. Please note that they
              are only indicative. Sign up and create an account to do a more
              detailed comparison and to save and update your choices.
            </p>
          </div>
          <div class="table-footer-desc">
            <p>
              You can lock in the costs by setting up a pre-paid funeral plan.
              Click here in order to learn more about what you need and about
              options available.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="sign-up row mb-5">
      <div class="col-12">
        <div class="sign-up-head">Sign up and create a more detailed plan</div>
      </div>
      <div class="col-12">
        <div class="sign-up-body row">
          <div class="col-12 col-md-4 mb-4 mb-md-0">
            <div class="sign-up-body-item">
              Go through checklist of what needs to be taken care of
            </div>
          </div>
          <div class="col-12 col-md-4 mb-4 mb-md-0">
            <div class="sign-up-body-item">
              Deal with immediate practicalities
            </div>
          </div>
          <div class="col-12 col-md-4 mb-4 mb-md-0">
            <div class="sign-up-body-item">
              Find a suitable funeral director and arrange the funeral
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/** Как юзать конструктор модалок & запись ответов, стор? */
import BhTooltip from './UserProfile/Wishlist/Modals/Tooltip.vue'

export default {
  name: 'unregisteredComponent',
  props: ['answers'],
  components: {
    BhTooltip,
  },
  created() {
    this.$emit('setLoader', false)
  },
  mounted() {
    this.idLegal = this.answers[this.answers.length - 1].id + 1
  },
  data() {
    return {
      /** FIXME: I do now know where I can get it from */
      legalCost: 450,
      activeToolTip: null,
      idLegal: 1,
    }
  },
  computed: {
    totalPrice() {
      let sum = 0
      this.answers.forEach(item => {
        sum += item.price ? item.price : 0
      })
      return sum
    },
  },
  methods: {
    toggleTooltip(id) {
      if (id == this.activeToolTip) {
        this.activeToolTip = null
        return
      } else {
        this.activeToolTip = id
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  .you-choices {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;

    h3 {
      font-size: 20px;
      font-weight: 550;
    }

    &-item {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0.5% 0;

      div:last-child {
        margin-left: 1%;
        font-weight: 550;
      }
    }
  }

  .table-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      border-top: 1px solid #464646;

      padding: 1% 0;

      &:last-child {
        border-bottom: 0;
      }

      &-left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        &-cost {
          margin-right: 3%;
        }
      }

      &-right {
        &-icon {
          cursor: pointer;
          .icon {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 50px;
              height: 50px;
              object-fit: cover;
            }
          }

          .unshow {
            display: none;
          }
        }
      }
    }
  }

  .table-footer {
    padding: 1% 15px;
    color: #583b75;
    font-weight: 600;
    background: rgba(88, 59, 117, 0.06);
    border-top: 1px solid #464646;
    border-bottom: 1px solid #464646;

    &-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      &-label {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin-right: 4.1%;

        &-cost {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: 5%;
        }
      }

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.33%;
      }
    }

    &-desc {
      background: #ffffff;
      margin: 10px 0;

      p {
        padding: 2% 30px;
        color: #000;
        font-weight: 400;
        text-align: center;
      }
    }
  }

  .sign-up {
    margin-top: 4%;

    &-head {
      border-top: 1px solid #583b75;
      border-bottom: 1px solid #583b75;
      padding: 0.5% 0;
    }

    &-body {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-top: 3%;

      &-item {
        height: 100%;
        padding: 15px;
        border-left: 5px solid #583b75;
        background: #ffffff;
        border-radius: 2px;
        box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.1);
      }
    }
  }
}
</style>
