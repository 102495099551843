import axios from '@/api/axios'

const updateUserInfo = info => {
  return axios.post(`/quizes/legal/send`, info)
}

const getUserInfo = id => {
  return axios.get(`/quizes/legal/get-modal?question_id=${id}`)
}

const getExecutorInfo = id => {
  return axios.get(`/executor?user_id=${id}`)
}

const updateExecutorInfo = info => {
  return axios.post(`/executor`, info)
}

const getNotes = id => {
  return axios.get(`/notes?user_id=${id}`)
}

const updateNotes = notes => {
  return axios.post(`/notes`, notes)
}

const getFilledInfo = ids => {
  return axios.post(`/get-filled-modals`, ids)
}
export default {
  updateUserInfo,
  getUserInfo,
  getExecutorInfo,
  updateExecutorInfo,
  getFilledInfo,
  getNotes,
  updateNotes,
}
