<template>
    <div class="loader-mask">
        <img class="loader" src="./../../assets/Loading/loading.png" alt="loader">
    </div>
</template>

<script>
export default {
    name: 'Loader',
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
.loader-mask {
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    background: #9B9B9B;

    opacity: 0.8;
    z-index: 9999;

    width: 100%;
    height: 100%;
    .loader {
        opacity: 1;
        animation: rotation 1.5s infinite linear;
    }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>