<template>
  <ul class="modal__content-setting_list">
    <li class="modal__content-setting_list_item">
      <div class="modal__content-setting_user">
        <p class="key">Name:</p>
        <p class="value">{{ currentUser.name }}</p>
        <button @click="showDeleteModal">Delete</button>
      </div>
    </li>

    <bp-delete-modal
      :callback="handleDelete"
      :showModal="deleteModalIsShown"
      :close="close"
    />
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import { authGetterTypes, authActionTypes } from '@/store/modules/auth'

import BpDeleteModal from '../../Modals/ConfirmDeleteModal.vue'

const { deleteUser } = authActionTypes
export default {
  name: 'BpUserInfo',
  components: { BpDeleteModal },
  computed: {
    ...mapGetters({
      currentUser: authGetterTypes.currentUser,
    }),
  },
  data() {
    return {
      deleteModalIsShown: false,
    }
  },
  methods: {
    handleDelete() {
      this.$store.dispatch(deleteUser, this.currentUser.id).catch(Error)
    },
    showDeleteModal() {
      this.deleteModalIsShown = true
    },
    close() {
      this.deleteModalIsShown = false
    },
  },
}
</script>

<style lang="scss" scoped>
.modal__content-setting_user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  margin: 1% auto;

  .key {
    width: 15%;
    font-size: 18px;
    text-align: left;
  }

  .value {
    text-align: left;
    flex-grow: 1;
    font-weight: bold;
    color: blue;
  }

  button {
    color: #583b75;
  }
}

.modal__content-setting_edit {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin: 5% auto;
  .content-setting-form {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;
  }

  .content-setting-input {
    width: 50%;
    padding: 1%;

    margin: 1%;
    border: 1px solid rgba(70, 70, 69, 0.3);

    border-radius: 2px;
    &::placeholder {
      color: #a7a7a7;
    }
  }

  .content-setting-buttons {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .content-setting-cancel {
    margin: 2% 4%;
    padding: 1% 4%;

    box-shadow: 0px 10px 10px rgba(88, 59, 117, 0.3);

    font-weight: 600;
    font-size: 16px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid #a7a7a7;
    color: #a7a7a7;
    &:hover {
      background: #a7a7a7;
      color: #ffffff;
    }
  }

  .content-setting-save {
    margin: 2% 4%;
    padding: 1% 5%;

    box-shadow: 0px 10px 10px rgba(88, 59, 117, 0.3);

    font-weight: 600;
    font-size: 16px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid #583b75;
    color: #583b75;
    &:hover {
      background: #583b75;
      color: #ffffff;
    }
  }
}
</style>
