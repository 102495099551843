<template>
    <div class="modal__place-body">
        <div class="modal__place-denomination">
            <div class="modal__place-denomination-content">
                <div :class="['modal__place-denomination-content-item', 
                    {activeType: type.activeType}, {activeOpacity: type.activeOpacity}]" 
                    @mouseover="showDetails(type, data[currentIndex].modalData)"
                    @mouseleave="hideDetails(type, data[currentIndex].modalData)"
                    v-for="(type, index) in data[currentIndex].modalData" 
                    :key="index"
                >
                    <p>{{type}}</p>
                </div>
            </div>
            </div>
        <button @click="moveForward" class="modal__place-button">Choose</button>
    </div>
</template>

<script>
export default {
    name: 'TableBody',
    props: {
        moveForward: Function,
        data: Array,
        currentIndex: Number,
    },
    methods: {
        showDetails(obj, massOfTheObjs) {
            obj.activeType = true;

            massOfTheObjs.forEach((item) => {
                item.activeOpacity = true;
            });

            obj.activeOpacity = false;
        },
        hideDetails(obj, massOfTheObjs) {
            obj.activeType = false;

            massOfTheObjs.forEach((item) => {
                item.activeOpacity = false;
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.activeType {
  border: 1px solid #583b75 !important;
  box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.3);
}
.activeOpacity {
  opacity: .5;
}

.modal__place-body {
    width: 90%;

    .modal__place-button {
        margin: 15% auto 5% auto;
        background: #583b75;
        color: #ffffff;
        padding: 15px 80px;

        box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.3);

        font-weight: 600;
        font-size: 16px;
        text-align: center;
        border-radius: 2px;
    }

    .modal__place-denomination {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .modal__place-denomination-input {
            width: 87%;
            margin-right: 1%;
            margin-bottom: -7%;
            margin-top: 7%;
            align-self: center;
            border: 1px solid rgba(139, 135, 143, 0.3);
            font-size: 13px;
            padding: 1% 0 1% 1%;
        }
        .modal__place-denomination-input::placeholder {
            color: rgba(32, 32, 32, 0.3);
        }
        .modal__place-denomination-content {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: -7%;
            margin-top: 7%;
            .modal__place-denomination-content-item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25%;
                margin-left: 6%;
                border: 1px solid rgba(139, 135, 143, 0.3);
                border-radius: 3px;
                padding: 2.5% 2.5% 0 2.5%;
                margin-bottom: 4%;
                p {
                    text-align: center;
                    font-size: 12px;
                }
            }
        }
    }
}
</style>