<template>
  <div class="cards">
    <h4>{{ currentIndex + 1 }}. {{ question }}</h4>
    <div class="box">
      <div
        class="card"
        v-for="(card, i) in cards"
        :key="i"
        :class="{ active: card.optionsID == activeID }"
        @click="handleClick(card.optionsID)"
      >
        {{ card.text }}
      </div>
    </div>
    <button @click="handleChoose">Choose</button>
  </div>
</template>

<script>
export default {
  name: 'BpCardUp',
  props: ['data', 'currentIndex', 'moveForward', 'updateAnsw'],
  computed: {
    question() {
      return this.data.quizzData.question
    },
    cards() {
      return this.data.quizzData.options
    },
  },
  data() {
    return { activeID: null }
  },
  methods: {
    handleClick(id) {
      this.activeID = id
    },
    handleChoose() {
      this.updateAnsw(this.activeID)
      this.moveForward()
      this.activeID = null
    },
  },
}
</script>

<style lang="scss" scoped>
.cards {
  width: 85%;

  height: 90%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  padding: 1%;
  margin: 2% auto;
  flex-wrap: wrap;
}
h4 {
  font-weight: bold;
}
.box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 2%;
  margin: 10% auto;
}

.card {
  display: flex;
  width: 28%;
  height: 20%;
  border-radius: 5px;
  border: 1px solid rgba(88, 59, 117, 0.33);
  padding: 2%;
  white-space: nowrap;
  margin-top: 2%;
  &:hover {
    box-shadow: 0 10px 10px rgba(88, 59, 117, 0.3);
    border: 1px solid #583b75;
  }
  &.active {
    box-shadow: 0 10px 10px rgba(88, 59, 117, 0.3);
    border: 1px solid #583b75;
  }

  h5 {
    padding: 4%;
    font-weight: bold;
    font-size: 15px;
    margin: 0;
  }
}
button {
  width: 20%;
  border: 1px solid rgba(88, 59, 117, 0.33);
  /* box-shadow: 5px 5px 5px rgba(88, 59, 117, 0.3); */
  border-radius: 2px;
  padding: 1% 0;
  text-align: center;
  &:hover {
    background-color: #583b75;
    color: #fff;
  }
}
</style>
