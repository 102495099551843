<template>
  <main class="container main-page">
    <bp-notification v-if="notificationModalsIsShown" />
    <template v-if="status !== 'completed'">
      <bp-promo-card :cards="cards" :toggleModal="toggleStartModal" />
      <formPhone />
      <bp-usp />
      <bp-info :info="info" />
      <section class="sing-up-form row">
        <div class="col-12">
          <bp-died-register-form v-if="loginModalProcces != 'confirm'" />
          <bp-confirm-form v-if="loginModalProcces == 'confirm'" />
          <bp-confirm-done v-if="loginModalProcces == 'done'" />
        </div>
      </section>
      <bp-faq :title="faq.title" :data="faq.data" />
    </template>

    <template v-if="status === 'completed'">
      <unregisteredComponent :answers="answers" @setLoader="loading = false" />
      <section class="sing-up-form">
        <bp-sign-up-form v-if="loginModalProcces != 'confirm'" />
        <bp-confirm-form v-if="loginModalProcces == 'confirm'" />
        <bp-confirm-done v-if="loginModalProcces == 'done'" />
      </section>
      <bp-usp />
      <bp-promo-card :cards="card" :toggleModal="toggleStartModal" />
    </template>

    <div class="main-page__steps-box" v-show="status === 'first'">
      <StepOne
        :close="close"
        :cards="firstCards"
        :progressBarWidth="progressBarWidth"
        @setAnswer="setAnswer"
      />
    </div>
    <div v-show="status === 'second'">
      <StepTwo
        :close="close"
        :cards="secondCards"
        :progressBarWidth="progressBarWidth"
        @setAnswer="setAnswer"
      />
    </div>
    <div v-show="status === 'third'">
      <StepThird
        :close="close"
        :progressBarWidth="progressBarWidth"
        @setAnswer="setAnswer"
      />
    </div>

    <ConstructorModal
      v-if="isFirstLogin"
      :toggleModal="toggleModal"
      :data="quizzes"
    />
  </main>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { notificationGettersTypes } from '@/store/modules/notificationModals'
import { authGetterTypes } from '@/store/modules/auth'

import BpFaq from '../../components/Faq.vue'
import unregisteredComponent from '../../components/Unregistered.vue'
import BpUsp from '../../components/Usp.vue'
import BpPromoCard from '../../components/PromoCard.vue'
import BpInfo from '../../components/Info.vue'
import ConstructorModal from '../../components/QiuzModals/ConstructorModal.vue'
import BpSignUpForm from '../../components/Forms/SignUpForm.vue'
import BpDiedRegisterForm from '../../components/Forms/DiedRegisterForm.vue'
import BpConfirmForm from '../../components/Modals/Login/ConfirmModal.vue'
import BpConfirmDone from '../../components/Modals/Login/DoneModal.vue'
import BpNotification from '../../components/Modals/NotificationModal.vue'
import StepOne from '../../components/StartModal/StepOne.vue'
import StepTwo from '../../components/StartModal/StepTwo.vue'
import StepThird from '../../components/StartModal/StepThird.vue'
import formPhone from '../../components/Forms/ContactForm.vue'

const { isFirstLogin } = authGetterTypes
const { notificationModalsIsShown } = notificationGettersTypes
export default {
  name: 'BpMain',
  components: {
    BpUsp,
    BpPromoCard,
    BpInfo,
    BpFaq,
    ConstructorModal,
    BpSignUpForm,
    BpConfirmForm,
    BpConfirmDone,
    BpNotification,
    unregisteredComponent,
    StepOne,
    StepTwo,
    StepThird,
    formPhone,
    BpDiedRegisterForm,
  },

  computed: {
    ...mapState({
      loginModalProcces: ({ loginModals }) => loginModals.procces,
      quizzModalIsShown: state => state.quizzesModals.isShown,
      quizzes: state => state.quizzesModals.quizzes,
    }),
    ...mapGetters({
      notificationModalsIsShown,
      isFirstLogin,
    }),
    progressBarWidth() {
      const fullWidth = 100
      const step = fullWidth / 3
      const progress = Math.ceil((this.answers?.length + 1) * step)
      return progress
    },
  },
  data() {
    return {
      cards: [
        {
          title: 'Need help getting started?',
          text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In malesuada augue sed quam pellentesque, dapibus rutrum',
          button: 'Show me my options',
          path: '#',
        },
      ],
      card: [
        {
          title: 'Do you want to try again?',
          text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In malesuada augue sed quam pellentesque, dapibus rutrum',
          button: 'Start new comparison',
          path: '#',
        },
      ],
      info: {
        title: 'Be in control',
        text:
          'When you plan ahead, it takes a lot of the stress out of planning for a death. You can control the costs and make sure everyone’s wishes are respected when you make arrangements with plenty of time to spare. Start exploring your options today.',
      },
      faq: {
        title: 'Planning for everything',
        data: [
          {
            ask: 'Which types of payment can I make?',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
            id: 0,
            isShown: false,
          },
          {
            ask: 'Which types of payment can I make?',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
            id: 1,
            isShown: false,
          },
          {
            ask: 'Which types of payment can I make?',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
            id: 2,
            isShown: false,
          },
          {
            ask: 'Which types of payment can I make?',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
            id: 3,
            isShown: false,
          },
        ],
      },
      firstCards: {
        order: 7,
        quizzData: {
          modals: null,
          is_modal_filled: false,
          control_question: false,
          question: 'Do you know which kind of funeral you want?',
          options: [
            {
              optionsID: 37,
              quizzID: 15,
              text:
                'Following a funeral service at a place of worship or funeral home, friends and family will usually follow the coffin in a procession to a cemetery. They will often travel in a hearse or limousine.',
              title: 'Classic burial',
              price: 2500,
              img: 'uploads/rd.jpg',
            },
            {
              optionsID: 38,
              quizzID: 15,
              text:
                'In this case, a funeral service will be held at a crematorium, and afterwards a cremation will be carried out. Family or friends may be given the remains, or ashes, afterwards.',
              title: 'Attended Cremation',
              price: 1600,
              img: 'uploads/rd.jpg',
            },
            {
              optionsID: 39,
              quizzID: 15,
              text:
                'A direct cremation is an affordable alternative to a cremation with service, and takes place under the supervision of a qualified attendant without any mourners present.',
              title: 'Direct cremation',
              price: 1000,
              img: 'uploads/rd.jpg',
            },
            {
              optionsID: 40,
              quizzID: 15,
              text:
                'Alternatives to traditional funerals range from DIY funerals to woodland and natural burials where a tree marks the burial spot.',
              title: 'Alternative funeral',
              price: 400,
              img: 'uploads/rd.jpg',
            },
          ],
          data: [
            {
              quizzID: 15,
              dataID: 25,
              type: 'cardUp',
              title: 'Classic burial',
              text:
                'Following a funeral service at a place of worship or funeral home, friends and family will usually follow the coffin in a procession to a cemetery. They will often travel in a hearse or limousine.',
              price: 2500,
              img: 'uploads/rd.jpg',
            },
            {
              quizzID: 15,
              dataID: 26,
              type: 'cardUp',
              title: 'Attended Cremation',
              text:
                'In this case, a funeral service will be held at a crematorium, and afterwards a cremation will be carried out. Family or friends may be given the remains, or ashes, afterwards.',
              price: 1600,
              img: 'uploads/rd.jpg',
            },
            {
              quizzID: 15,
              dataID: 27,
              type: 'cardUp',
              title: 'Direct cremation',
              text:
                'A direct cremation is an affordable alternative to a cremation with service, and takes place under the supervision of a qualified attendant without any mourners present.',
              price: 1000,
              img: 'uploads/rd.jpg',
            },
            {
              quizzID: 15,
              dataID: 28,
              type: 'cardUp',
              title: 'Alternative funeral',
              text:
                'Alternatives to traditional funerals range from DIY funerals to woodland and natural burials where a tree marks the burial spot.',
              price: 400,
              img: 'uploads/rd.jpg',
            },
          ],
        },
        quizzDesc: 'Type of funeral',
        quizzID: 15,
        quizzTable: 'The Funeral',
        quizzType: 'cardUp',
      },
      secondCards: {
        order: 8,
        quizzID: 16,
        quizzType: 'cardUp',
        quizzTable: 'The Ceremonies',
        quizzDesc: 'Type of ceremony',
        quizzData: {
          modals: null,
          is_modal_filled: false,
          control_question: false,
          question: 'Do you know which kind of ceremonies?',
          options: [
            {
              optionsID: 43,
              quizzID: 16,
              text:
                'In a religious ceremony, a religious leader leads mourners through the process of celebrating the life of someone who has died and saying goodbye.',
              title: 'Funeral with a religious ceremony',
              price: 800,
              img: 'uploads/rd.jpg',
            },
            {
              optionsID: 44,
              quizzID: 16,
              text:
                'For those who are not religious, another option is to let a celebrant lead the ceremony. They will pay tribute to the person who has died with no mention of God or religion.',
              title: 'Humanist ceremony with a celebrant',
              price: 600,
              img: 'uploads/rd.jpg',
            },
            {
              optionsID: 45,
              quizzID: 16,
              text:
                'A private ceremony is usually held at someone’s home rather than a funeral home, crematorium or religious space. It is only open to invited guests.',
              title: 'Private ceremony',
              price: 300,
              img: 'uploads/rd.jpg',
            },
            {
              optionsID: 46,
              quizzID: 16,
              text:
                'You wish to have no funeral ceremony at all, or want to plan everything on you own.',
              title: 'No Ceremony',
              img: 'uploads/rd.jpg',
            },
          ],
          data: [
            {
              quizzID: 16,
              dataID: 29,
              type: 'cardUp',
              title: 'Funeral with a religious ceremony',
              text:
                'In a religious ceremony, a religious leader leads mourners through the process of celebrating the life of someone who has died and saying goodbye.',
              price: 800,
              img: 'uploads/rd.jpg',
            },
            {
              quizzID: 16,
              dataID: 30,
              type: 'cardUp',
              title: 'Humanist ceremony with a celebrant',
              text:
                'For those who are not religious, another option is to let a celebrant lead the ceremony. They will pay tribute to the person who has died with no mention of God or religion.',
              price: 600,
              img: 'uploads/rd.jpg',
            },
            {
              quizzID: 16,
              dataID: 31,
              type: 'cardUp',
              title: 'Private ceremony',
              text:
                'A private ceremony is usually held at someone’s home rather than a funeral home, crematorium or religious space. It is only open to invited guests.',
              price: 300,
              img: 'uploads/rd.jpg',
            },
            {
              quizzID: 16,
              dataID: 32,
              type: 'cardUp',
              title: 'No Ceremony',
              text:
                'You wish to have no funeral ceremony at all, or want to plan everything on you own.',
              price: 0,
              img: 'uploads/rd.jpg',
            },
          ],
        },
      },
      status: '',
      answers: [],
      id: 1,
      loading: false,
    }
  },
  updated() {
    const body = document.querySelector('body')

    if (this.status) {
      body.classList.add('showSteps')
    }
    if (this.status === 'completed' || !this.status) {
      body.classList.remove('showSteps')
    }
  },
  methods: {
    toggleModal() {
      // this.showModal = !this.showModal
      // this.$store.commit(quizzesMutationsTypes.toggleModals)
    },
    toggleStartModal() {
      this.status = 'first'
      this.answers = []
    },
    close() {
      this.status = ''
      this.answers = []
    },
    setAnswer(data) {
      if (this.status === 'completed') {
        this.loading = true
      }

      this.status = data.status
      delete data.status
      if (data.direaction !== 'back') {
        delete data.direaction
        data.id = this.id
        this.id++
        this.answers.push(data)
      } else {
        delete data.direaction
        this.id--
        this.answers.pop()
      }
    },
  },
}
</script>

<style lang="scss">
body.showSteps .header__toggle-menu {
  z-index: 0;
}
</style>
