<template>
  <div class="user-profile container">
    <bp-tab-bar class="mb-5" />
    <component :is="currentTab" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
  userProfileGetterTypes,
  userProfileMutationsTypes,
} from '@/store/modules/userProfile'

import {
  quizzesActionsTypes,
  quizzesMutationsTypes,
} from '@/store/modules/quizzesModals'
import { guestsActionsTypes } from '@/store/modules/guests'
import { authGetterTypes } from '@/store/modules/auth'
import BpTabBar from '../../components/UserProfile/UserProfileTabs.vue'
import BpAccBody from '../../components/UserProfile/Account/Body.vue'
import BpLorem from '../../components/UserProfile/Lorem.vue'
import BpMainList from '../../components/UserProfile/Wishlist/WishListMain.vue'
import MainPageMyPlan from '../../components/UserProfile/MyPlan/Main.vue'
import BpGuests from '../../components/UserProfile/Guests/Guests.vue'

const { toggleActiveTabID } = userProfileMutationsTypes
const { getAnswers, getQuizz } = quizzesActionsTypes
const { isFirstLogin } = authGetterTypes
const { toggleActiveQuiz } = quizzesMutationsTypes
export default {
  name: 'BpUserProfile',
  components: {
    BpTabBar,
    BpAccBody,
    BpMainList,
    BpLorem,
    MainPageMyPlan,
    BpGuests,
  },
  mounted() {
    const quizzName = 'logged-in'

    this.$store.dispatch(getQuizz, quizzName).then(({ items }) => {
      this.$store.commit(toggleActiveQuiz, items[0].quizzID)
    })
    if (!this.isFirstLogin) {
      this.$store.dispatch(getAnswers, 'logged-in')
      this.$store.dispatch(guestsActionsTypes.getGuests)
    }
  },
  computed: {
    ...mapState({ isLoggedIn: state => state.auth.isLoggedIn }),
    ...mapGetters({
      tabs: userProfileGetterTypes.tabs,
      showModal: userProfileGetterTypes.isShown,
      activeTabID: userProfileGetterTypes.activeTabID,
      isFirstLogin,
    }),
    currentTab() {
      const components = {
        account: 'bp-acc-body',
        wishlist: 'bp-main-list',
        guests: 'bp-guests',
        plan: 'MainPageMyPlan',
        list: 'bp-lorem',
      }
      const { type } = this.tabs.filter(t => t.id === this.activeTabID)[0]

      return components[type]
    },
  },
  data() {
    return {}
  },

  methods: {
    toggleTab(id) {
      this.$store.commit(toggleActiveTabID, id)
    },
  },
  watch: {},
}
</script>
