<template>
  <div class="modal" v-show="notificationIsShown">
    <transition name="modal">
      <div class="modal__mask" @click="close">
        <component :is="currentModal" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  notificationGettersTypes,
  notificationMutationsTypes,
} from '@/store/modules/notificationModals'
import BpToast from '../Modals/Notifications/Toasts.vue'

const { toggleNotificationModals, closeModals } = notificationMutationsTypes
export default {
  name: 'BpDoneMainModal',
  props: ['message'],
  components: { BpToast },
  computed: {
    ...mapGetters({
      notificationIsShown: notificationGettersTypes.notificationModalsIsShown,
      notificationProcces: notificationGettersTypes.notificationModalsProcces,
    }),
    currentModal() {
      this.closeModal()

      const modals = {
        toast: 'bp-toast',
      }
      return modals[this.notificationProcces]
    },
  },

  data() {
    return {}
  },

  methods: {
    close() {
      this.$store.commit(toggleNotificationModals)
    },
    closeModal() {
      setTimeout(() => {
        this.$store.commit(closeModals)
      }, 2000)
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal__mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
