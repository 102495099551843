import belongingApi from '@/api/belonging'

const state = {
    belonging: {},
    isLoading: null,
    errors: null,
}
export const belongingMutationsTypes = {
    getBelongingStart: 'belonging/getBelongingStart',
    getBelongingSuccess: 'belonging/getBelongingSuccess',
    getBelongingFailure: 'belonging/getBelongingFailure',

    addBelongingStart: 'belonging/addBelongingStart',
    addBelongingSuccess: 'belonging/addBelongingSuccess',
    addBelongingFailure: 'belonging/addBelongingFailure',

    deleteBelongingStart: 'belonging/deleteBelongingStart',
    deleteBelongingSuccess: 'belonging/deleteBelongingSuccess',
    deleteBelongingFailure: 'belonging/deleteBelongingFailure',

    updateBelongingStart: 'belonging/updateBelongingStart',
    updateBelongingSuccess: 'belonging/updateBelongingSuccess',
    updateBelongingFailure: 'belonging/updateBelongingFailure',
}
const mutations = {
    [belongingMutationsTypes.getBelongingStart](state) {
        state.isLoading = true
    },
    [belongingMutationsTypes.getBelongingSuccess](state, payload) {
        state.isLoading = false
        state.belonging = payload
        state.errors = null
    },
    [belongingMutationsTypes.getBelongingFailure](state, payload) {
        state.isLoading = false
        state.belonging = null
        state.errors = payload
    },

    [belongingMutationsTypes.addBelongingStart](state) {
        state.isLoading = true
    },
    [belongingMutationsTypes.addBelongingSuccess](state, payload) {
        state.isLoading = false
        state.belonging = payload
        state.errors = null
    },
    [belongingMutationsTypes.addBelongingFailure](state, payload) {
        state.isLoading = false
        state.belonging = null
        state.errors = payload
    },

    [belongingMutationsTypes.deleteBelongingStart](state) {
        state.isLoading = true
    },
    [belongingMutationsTypes.deleteBelongingSuccess](state, payload) {
        // eslint-disable-line no-console
        state.isLoading = false
        state.errors = null
        // const filtered = state.belonging.filter(p => p.id !== payload.id)
            state.belonging = payload.content
    },
    [belongingMutationsTypes.deleteBelongingFailure](state, payload) {
        state.isLoading = false
        state.errors = payload
    },

    [belongingMutationsTypes.updateBelongingStart](state) {
        state.isLoading = true
    },
    [belongingMutationsTypes.updateBelongingSuccess](state, payload) {
        state.isLoading = false
        state.errors = null
        // const idx = state.belonging.findIndex(p => p.id === payload.id)
        state.belonging = payload.content.belong
    },
    [belongingMutationsTypes.updateBelongingFailure](state, payload) {
        state.isLoading = false
        state.errors = payload
    },
}

export const belongingActionsTypes = {
    getBelonging: 'belonging/getBelonging',
    addBelonging: 'belonging/addBelonging',
    updateBelonging: 'belonging/updateBelonging',
    deleteBelonging: 'belonging/deleteBelonging',
}
const actions = {
    [belongingActionsTypes.getBelonging]({ commit }, userId) {
        return new Promise(resolve => {
            commit(belongingMutationsTypes.getBelongingStart)
            belongingApi
                .getBelonging(userId)
                .then(response => {
                    commit(belongingMutationsTypes.getBelongingSuccess, response.data)
                    resolve(response.data)
                })
                .catch(() => {
                    commit(belongingMutationsTypes.getBelongingFailure)
                })
        })
    },
    [belongingActionsTypes.addBelonging]({ commit }, belonging) {
        return new Promise(resolve => {
            commit(belongingMutationsTypes.addBelongingStart)
            belongingApi
                .addBelonging(belonging)
                .then(response => {
                    commit(belongingMutationsTypes.addBelongingSuccess, response.data)
                    resolve(response.data)
                })
                .catch(() => {
                    commit(belongingMutationsTypes.addBelongingFailure)
                })
        })
    },
    [belongingActionsTypes.deleteBelonging]({ commit }, data) {
        return new Promise(resolve => {
            commit(belongingMutationsTypes.deleteBelongingStart)
            belongingApi
                .deleteBelonging(data)
                .then(response => {
                    // eslint-disable-line no-console
                    commit(belongingMutationsTypes.deleteBelongingSuccess, response.data)
                    resolve(response.data)
                })
                .catch(response => {
                    // eslint-disable-line no-console
                    commit(belongingMutationsTypes.deleteBelongingFailure, response.error)
                })
        })
    },
    [belongingActionsTypes.updateBelonging]({ commit }, belonging) {
        return new Promise(resolve => {
            commit(belongingMutationsTypes.updateBelongingStart)
            belongingApi
                .updateBelonging(belonging)
                .then(response => {
                    commit(belongingMutationsTypes.updateBelongingSuccess, response.data)
                    resolve(response.data)
                })
                .catch(() => {
                    commit(belongingMutationsTypes.updateBelongingFailure)
                })
        })
    },
}

export const belongingGetterTypes = {
    getBelonging: 'belonging/belonging',
}
const getters = {
    [belongingGetterTypes.getBelonging]: state => {
        return state.belonging
    }
}

export default {
    state,
    actions,
    mutations,
    getters,
}