<template>
  <div class="head">
    <div class="head-left">
      <h3>{{ heading }}</h3>
    </div>
    <div class="head-right">
      <div class="head-right-cost">
        <span>Cost</span>
      </div>
      <div class="head-right-status">
        <span>Status</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeadTable',
  props: ['heading'],
}
</script>

<style lang="scss" scoped>
.head {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-bottom: 1px solid #464646;

  padding-bottom: 2.5%;

  &-left {
    h3 {
      text-transform: capitalize;
      font-weight: 550;
      font-size: 24px;
    }
  }

  &-right {
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>

