<template>
    <section class="table mb-5">
        <div class="table__header">Options</div>
        <div class="table__header">Write it yourself</div>
        <div class="table__header">Online will</div>
        <div class="table__header">Help from solicitor</div>
        <div class="table__first">How does it work? <br />Complexity?</div>
        <div>Online clicks</div>
        <div>Online clicks, then telephone</div>
        <div>Meet up and explain</div>
        <div class="table__first">Advantages</div>
        <div>Easy and quick</div>
        <div>Expertise results that <br />are regulated</div>
        <div>Not suitable when estate complex</div>
        <div class="table__first">Disadvantages</div>
        <div>Not suitable when estate complex</div>
        <div>Still unregulated</div>
        <div>Cost and time</div>
    </section>
</template>

<script>
export default {
    name: 'BpTable',
    data() {
        return {}
    },
}
</script>

<style lang="scss" scoped>
.table {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 16px;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);

    div {
        flex: 0 0 25%;
        padding: 3% 1%;
        border: 1px solid rgba(70, 70, 69, 0.3);
    }

    .table__header {
        font-weight: bold;
    }
    .table__first {
        font-weight: bold;
    }
}

</style>