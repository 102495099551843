import servicesApi from '@/api/services'

const state = {
  errors: null,
  isLoading: false,
}

export const servicesMutationsTypes = {
  sendContactFormStart: 'services/sendContactFormStart',
  sendContactFormSuccess: 'services/sendContactFormSuccess',
  sendContactFormFailure: 'services/sendContactFormFailure',
}

const mutations = {
  // contact form
  [servicesMutationsTypes.sendContactFormStart](state) {
    state.isLoading = true
  },
  [servicesMutationsTypes.sendContactFormSuccess](state) {
    state.isLoading = false
    state.errors = null
  },
  [servicesMutationsTypes.sendContactFormFailure](state, payload) {
    state.isLoading = false
    state.errors = payload
  },
}

export const servicesActionsTypes = {
  sendContactForm: 'services/sendContactForm',
}

const actions = {
  [servicesActionsTypes.sendContactForm]({ commit }, data) {
    return new Promise(resolve => {
      commit(servicesMutationsTypes.sendContactFormStart)
      servicesApi
        .sendContactFormData(data)
        .then(response => {
          commit(servicesMutationsTypes.sendContactFormSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(servicesMutationsTypes.sendContactFormFailure)
        })
    })
  },
}

export const servicesGettersTypes = {}

const getters = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
