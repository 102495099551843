<template>
    <div class="modal__place-body">
        <form class="modal__place-form" @submit.prevent="">
            <fieldset :class="['modal__place-field', { active: active1 }]">
                <legend>In the UK</legend>
                <input
                class="modal__place-input"
                type="search"
                placeholder="Enter a postcode, town or city"
                @focus="
                    active1 = true
                    active2 = false
                "
                />
            </fieldset>
            <fieldset :class="['modal__place-field', { active: active2 }]">
                <legend>In another country</legend>
                <input
                class="modal__place-input"
                type="search"
                placeholder="Enter a postcode, town or city"
                @focus="
                    active2 = true
                    active1 = false
                "
                />
            </fieldset>
        </form>
        <button @click="moveForward" class="modal__place-button">Choose</button>
    </div>
</template>

<script>
export default {
    name: 'FormBody',
    props: {
        moveForward: Function,
        data: Array,
        currentIndex: Number,
    },
    data() {
        return {
            active1: true,
            active2: false,
        }
    },
}
</script>

<style lang="scss" scoped>
.modal__place-body {
    width: 90%;

    .modal__place-form {
        width: 90%;
        margin: 5% auto;

        display: flex;
        align-items: center;
        justify-content: space-around;

        legend {
            text-align: left;
            font-size: 20px;
            color: #c4c4c4;
        }
        .active legend {
            color: #000000;
        }
    }
    .modal__place-field {
        width: 90%;
        margin: 0 auto;
        padding: 2%;
    }

    .modal__place-input {
        width: 100%;
        padding: 4%;
        margin-bottom: 10%;
        &::placeholder {
            color: #ffffff;
        }
    }
    .active .modal__place-input {
        border: 1px solid rgba(70, 70, 69, 0.3);
        border-radius: 2px;
        &::placeholder {
            color: #a7a7a7;
        }
    }

    .modal__place-button {
        margin: 15% auto 5% auto;
        background: #583b75;
        color: #ffffff;
        padding: 15px 80px;

        box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.3);

        font-weight: 600;
        font-size: 16px;
        text-align: center;
        border-radius: 2px;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}
</style>