<template>
  <section>
    <!--<h3>Socials account</h3> -->
    <form @submit.prevent="">
      <div class="socials">
        <v-icon name="fa-facebook-square" scale="2" fill="#583b75" />
        <input type="text" v-model="facebook" placeholder="Facebook" />
      </div>

      <div class="socials">
        <v-icon name="fa-twitter-square" scale="2" fill="#583b75" />
        <input type="text" v-model="twitter" placeholder="Twitter" />
      </div>

      <div class="socials">
        <v-icon name="fa-instagram-square" scale="2" fill="#583b75" />
        <input type="text" v-model="instagram" placeholder="Instagram" />
      </div>

      <div class="socials">
        <v-icon name="fa-whatsapp-square" scale="2" fill="#583b75" />
        <input type="text" v-model="whatsapp" placeholder="Whatsapp" />
      </div>

      <input type="submit" class="send" value="Save" />
    </form>
  </section>
</template>

<script>
export default {
  name: 'BpSocials',
  props: ['close'],
  data() {
    return {
      showInput: false,
      name: '',
      facebook: '',
      twitter: '',
      instagram: '',
      whatsapp: '',
    }
  },
  methods: {
    toggleInput() {
      this.showInput = !this.showInput
    },
  },
  watch: {
    showInput(value) {
      console.log('value ->', value) // eslint-disable-line no-console
    },
  },
}
</script>

<style lang="scss" scoped>
section {
  width: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  h3 {
    font-size: 24px;
    text-align: center;
    margin: 1% auto;
  }
}
.socials {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  input {
    width: 100%;
    padding: 1%;
    margin: 3%;
    border: 1px solid rgba(70, 70, 69, 0.3);
    border-radius: 2px;
    &::placeholder {
      color: #a7a7a7;
      font-size: 14px;
    }
  }
}

form {
  margin: 1% auto;
  width: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.send {
  width: 20%;
  margin-top: 5%;
  border: 1px solid rgba(88, 59, 117, 0.33);
  box-shadow: 5px 5px 5px rgba(88, 59, 117, 0.3);
  border-radius: 2px;
  padding: 1% 0;
  text-align: center;
  &:hover {
    background-color: #583b75;
    color: #fff;
  }
}
</style>
