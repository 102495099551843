<template>
  <section class="start row justify-content-center mb-5">
    <div
        v-for="(card, i) in cards"
        :key="i"
        class="col-12 col-md-6 mb-5 m-md-0"
    >
      <Card
          class="start__card"
          :card="card"
          :toggleModal="toggleModal"
      />
    </div>
  </section>
</template>

<script>
import Card from './Card.vue'

export default {
  name: 'BpPromoCard',
  components: { Card },
  props: ['cards', 'toggleModal'],
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.start {
  .start__card {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);
  }
}
</style>
