<template>
  <div>
    <div class="modal__content-main">
      <ul class="modal__content-setting">
        <li
          class="modal__content-setting_info"
          v-for="item in contents.actualSubmenu"
          :key="item.id"
        >
          <div class="desc" @click="showComponent(item.type, item.id)">
            <h5>{{ item.title }}</h5>
            <div class="subtitle">
              <h6>{{ item.subtitle }}</h6>
              <v-icon
                v-if="item.id !== activeContentID"
                name="fa-angle-down"
                scale="2"
                fill="rgba(70, 70, 70, 0.7)"
              />
              <div v-else @click.stop="closeComponent(item.id)">
                <v-icon
                  name="fa-angle-up"
                  scale="2"
                  fill="rgba(70, 70, 70, 0.7)"
                />
              </div>
            </div>
          </div>

          <component v-if="item.id === activeContentID" :is="currentModal" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BhUserInfo from './UpdateUser/UserInfo.vue'
import BhDeleteUser from './UpdateUser/DeleteUser.vue'
import BhLorem from './UpdateUser/Lorem.vue'

export default {
  name: 'BpMainContent',
  props: ['contents', 'open', 'close'],
  components: {
    BhUserInfo,
    BhDeleteUser,
    BhLorem,
  },
  computed: {
    currentModal() {
      const modals = {
        info: 'bh-user-info',
        delete: 'bh-delete-user',
        notifications: 'bh-lorem',
        verification: 'bh-lorem',
        security: 'bh-lorem',
      }
      return modals[this.modalType]
    },
  },
  data() {
    return {
      activeContentID: -1,
      modalType: '',
    }
  },
  methods: {
    showComponent(type, id) {
      this.modalType = type
      this.activeContentID = id
      this.open(id)
    },
    closeComponent(id) {
      this.activeContentID = null
      this.modalType = ''
      this.close(id)
    },
  },
}
</script>

<style lang="scss" scoped>
.modal__content-main {
  width: 100%;

  .modal__content-setting_info {
    cursor: pointer;
    text-align: left;
    color: rgba(70, 70, 70, 0.7);

    border-bottom: 1px solid rgba(70, 70, 70, 0.7);
    margin-top: 5%;

    h5 {
      font-weight: 600;
      font-size: 20px;
    }

    h6 {
      font-size: 16px;
    }
    .subtitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1% 0;
    }
  }
}
</style>
