import { render, staticRenderFns } from "./Lorem.vue?vue&type=template&id=13c858be&scoped=true&"
import script from "./Lorem.vue?vue&type=script&lang=js&"
export * from "./Lorem.vue?vue&type=script&lang=js&"
import style0 from "./Lorem.vue?vue&type=style&index=0&id=13c858be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13c858be",
  null
  
)

export default component.exports