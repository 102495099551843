import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/modules/auth'
import loginModals from '@/store/modules/loginModals'
import notificationModals from '@/store/modules/notificationModals'
import userProfile from '@/store/modules/userProfile'
import diedProfile from '@/store/modules/diedProfile'
import quizzesModals from '@/store/modules/quizzesModals'
import footer from '@/store/modules/footer'
import guests from '@/store/modules/guests'
import legal from '@/store/modules/legal'
import belonging from '@/store/modules/belonging'
import toggleNavbar from '@/store/modules/toggleNavbar'
import services from '@/store/modules/services'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    loginModals,
    notificationModals,
    userProfile,
    diedProfile,
    quizzesModals,
    footer,
    guests,
    legal,
    belonging,
    toggleNavbar,
    services,
  },
})
