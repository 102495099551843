<template>
  <div>
    <h5>Forgot password</h5>

    <form @submit.prevent="handleSubmit">
      <input
        type="email"
        placeholder="Email Address"
        v-model="email"
        required
      />
      <small v-show="!!error">{{ error }}</small>
      <small class="message" v-show="!!message">{{ message }}</small>

      <button type="submit" :disabled="isSubmitting">Send</button>
    </form>

    <a @click="toggleModalType('signIn')" href="#">Cancel?</a>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authActionTypes } from '@/store/modules/auth'

export default {
  name: 'BpForgotForm',
  props: ['closeModal', 'toggleModalType'],
  data() {
    return {
      email: '',
      error: '',
      message: '',
    }
  },

  computed: {
    ...mapState({
      isSubmitting: state => state.auth.isSubmitting,
      validationErrors: state => state.auth.validationErrors,
    }),
  },
  methods: {
    handleSubmit() {
      this.$store
        .dispatch(authActionTypes.forgotPass, {
          email: this.email,
        })
        .then(res => {
          this.message = res.data.message
          this.toggleModalType('done', res.data.message)
          this.error = ''
          /* this.closeModal() */
        })
    },
    close() {
      this.$emit('close')
    },
  },
  watch: {
    validationErrors(value) {
      if (!value) {
        return
      }
      this.error = value
    },
  },
}
</script>

<style lang="scss" scoped>
div {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h5 {
  width: 90%;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: #000000;
  margin: 10% auto;
}

.message {
  color: green;
  width: 100%;
  text-align: left;
}

form {
  small {
    color: red;
    width: 100%;
    text-align: left;
  }

  width: 90%;
  a {
    margin: 4% auto;
    font-size: 16px;
    text-align: center;
    text-decoration-line: underline;

    color: #9b9b9b;
  }

  input {
    width: 100%;
    padding: 4%;
    margin-bottom: 35%;
    border: 1px solid rgba(70, 70, 69, 0.3);
    border-radius: 2px;
    &::placeholder {
      color: #a7a7a7;
    }
    &:last-child {
      background-image: url('../../../assets/eye.png');
      background-repeat: no-repeat;
      background-position: 98% 50%;
    }
  }
}
p {
  font-size: 16px;
}

p > a {
  font-weight: 500;
  font-size: 16px;
  text-decoration-line: underline;
  color: #583b75;
}

a {
  font-size: 14px;
  text-align: center;
  text-decoration-line: underline;
  color: #583b75;
}
button {
  margin: 5% auto;
  background: #583b75;
  color: #ffffff;
  padding: 3% 18%;

  box-shadow: 0px 10px 10px rgba(88, 59, 117, 0.3);

  font-weight: 600;
  font-size: 16px;
  text-align: center;
  border-radius: 2px;
}
</style>
