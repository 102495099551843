<template>
  <div class="cards">
    <h4>{{ order }}. {{ question }}</h4>
    <div class="box">
      <div
        class="card"
        v-for="(card, i) in cards"
        :key="i"
        :class="{ active: card.optionsID == activeID }"
        @click="handleClick(card.optionsID)"
      >
        <h5>{{ card.title }}</h5>
        <p>{{ card.text }}</p>
        <img :src="url + card.img" alt="img" />
      </div>
    </div>
    <button
      :class="{ active: isActive }"
      @click="handleChoose"
      :disabled="!isActive"
    >
      Choose
    </button>
  </div>
</template>

<script>
export default {
  name: 'BpCardDown',
  props: ['data', 'currentIndex', 'moveForward', 'updateAnsw'],
  computed: {
    question() {
      return this.data.quizzData.question
    },
    order() {
      return this.data.order
    },
    cards() {
      return this.data.quizzData.options
    },
    isActive() {
      return !!this.activeID
    },
  },
  data() {
    return { activeID: null, url: 'http://efter-b.pandateam.net/' }
  },
  methods: {
    handleClick(id) {
      this.activeID = id
    },
    handleChoose() {
      const answ = this.cards.find(c => c.optionsID === this.activeID)
      this.updateAnsw(answ)
      this.activeID = null
      this.moveForward()
    },
  },
}
</script>

<style lang="scss" scoped>
.cards {
  width: 85%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  padding: 1%;
  margin: 2% auto;
  flex-wrap: wrap;
}
h4 {
  font-weight: bold;
}
.box {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: row;
  gap: 2%;
  margin: 5% auto;
}

.card {
  display: flex;
  height: 18rem;
  width: 30%;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.33);
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;

  &.active {
    box-shadow: 0 10px 10px rgba(88, 59, 117, 0.7);
    border: 1px solid #583b75;
  }
  &:hover {
    box-shadow: 0 10px 10px rgba(88, 59, 117, 0.3);
    border: 1px solid #583b75;
  }
  img {
    margin-top: auto;
    position: absolute;
    bottom: 0;
  }
  h5 {
    padding: 7%;
    font-weight: bold;
    font-size: 15px;
    margin: 0;
  }
  p {
    padding: 0 7%;
    font-size: 14px;
    height: 50%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: 1.3em;
    height: 5.4em;
  }
}
button {
  width: 20%;
  border: 1px solid rgba(88, 59, 117, 0.33);
  /* box-shadow: 5px 5px 5px rgba(88, 59, 117, 0.3); */
  border-radius: 2px;
  padding: 1% 0;
  text-align: center;
  &.active {
    background-color: #583b75;
    color: #fff;
  }
}
</style>
