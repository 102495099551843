<template>
  <footer class="container">
    <div class="footer">
      <nav class="footer__nav">
        <ul class="footer__list">
          <li class="footer__item" v-for="(item, i) in contents" :key="i">
            <router-link :to="normilizeLink(item.title)"
            >{{ item.title }}<br/>
              {{ item.description }}
            </router-link
            >
          </li>
        </ul>
      </nav>
      <div class="cookie" v-show="modalIsShown">
        <span class="cookie__title">Cookie control</span>
        <span @click="closeModal" class="cookie__close">&#x2715;</span>
        <p>
          We have placed cookies on your device to help make this website better.
        </p>
        <p>
          You can use this tool to change your cookie settings. Otherwise, we’ll
          assume you’re OK to continue.
        </p>
        <button @click="closeModal" class="cookie__button">I’m fine with this</button>
        <a class="cookie__link" @click="goToInfo">Information and Settings</a>
      </div>
      <ArrowUp/>
    </div>
  </footer>
</template>

<script>
import {mapState} from 'vuex'
import {footerActionsTypes} from '../store/modules/footer.js'
import ArrowUp from '@/components/ArrowUp'

const {getContents} = footerActionsTypes

export default {
  name: 'BhFooter',
  components: {
    ArrowUp
  },
  data() {
    return {
      modalIsShown: false,
    }
  },
  created() {
    setTimeout(() => (this.modalIsShown = true), 6000)
    this.$store.dispatch(getContents).catch(Error)
  },

  computed: {
    ...mapState({
      contents: state => state.footer.contents,
    }),
  },
  methods: {
    closeModal() {
      this.modalIsShown = false
    },
    showModal() {
      this.modalIsShown = true
    },
    goToInfo() {
      this.$router.push('/third')
    },
    normilizeLink(link) {
      return link.toLowerCase().replace(' ', '-')
    },
  },
}
</script>

<style lang="scss">
.cookie {
  text-align: center;
}
.cookie__link:hover {
  cursor: pointer;
}

.footer {
  display: flex;
  align-items: center;
  margin: 0 auto;
  border-top: 1px solid rgba(70, 70, 69, 0.3);
  position: relative;
  z-index: 10;

  .footer__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 42px 48px;
  }

  .footer__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
  }

  .footer__item {
    color: #000000;
    text-align: center;
    max-width: 140px;
    margin: 0 15px 15px;

    a {
      color: #000000;
    }

    a > br {
      color: #000000;
      padding: 6px;
      margin: 10px auto;
    }
  }

  .cookie {
    width: 300px;
    height: 400px;
    background: #ffffff;
    box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.1);
    border-radius: 2px;

    position: fixed;
    bottom: 5%;
    left: 5%;
    padding: 1%;

    p {
      font-size: 14px;
      padding: 5% 7% 0% 7%;
    }

    .cookie__button {
      margin: 15% auto 5% auto;
      background: #583b75;
      color: #ffffff;
      padding: 5% 18%;

      box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.3);

      font-weight: 600;
      font-size: 16px;
      text-align: center;
      border-radius: 2px;
    }

    a {
      font-size: 14px;
      text-align: center;
      text-decoration-line: underline;
      color: #583b75;
    }

    .cookie__title {
      width: 90%;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      color: #000000;
      margin-top: 3%;
    }

    .cookie__close {
      float: right;
      cursor: pointer;
    }
  }
}
</style>
