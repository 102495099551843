<template>
  <div class="user-profile container">
    <bp-tab-bar class="mb-5" />
    <component :is="currentTab" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
  diedProfileGetterTypes,
  diedProfileMutationsTypes,
  diedProfileActionsTypes,
} from '@/store/modules/diedProfile'

import { authGetterTypes } from '@/store/modules/auth'
import BpTabBar from '../../components/DiedProfile/DiedProfileTabs.vue'
import BpAccBody from '../../components/UserProfile/Account/Body.vue'
import BpLorem from '../../components/DiedProfile/Lorem.vue'
import BpMainList from '../../components/DiedProfile/ToDo/ToDoMain.vue'
import MainPageMyPlan from '../../components/DiedProfile/Funeral/FuneralMain.vue'

const { toggleActiveTabID } = diedProfileMutationsTypes
const { getToDo } = diedProfileActionsTypes
/* const { getAnswers } = quizzesActionsTypes */
const { isFirstLogin } = authGetterTypes
export default {
  name: 'BpDiedProfile',
  components: {
    BpTabBar,
    BpAccBody,
    BpMainList,
    BpLorem,
    MainPageMyPlan,
  },
  beforeMount() {
    /* window.location.replace('/died-profile') */
  },
  mounted() {
    if (!this.isFirstLogin) {
      /* this.$store.dispatch(getAnswers, 'logged-in') */
      this.$store.dispatch(getToDo)
    }
  },
  computed: {
    ...mapState({ isLoggedIn: state => state.auth.isLoggedIn }),
    ...mapGetters({
      tabs: diedProfileGetterTypes.tabs,
      activeTabID: diedProfileGetterTypes.activeTabID,
      isFirstLogin,
    }),
    currentTab() {
      const components = {
        account: 'bp-acc-body',
        wishlist: 'bp-main-list',
        plan: 'bp-lorem',
      }
      const { type } = this.tabs.filter(t => t.id === this.activeTabID)[0]

      return components[type]
    },
  },
  data() {
    return {}
  },

  methods: {
    toggleTab(id) {
      this.$store.commit(toggleActiveTabID, id)
    },
  },
  watch: {},
}
</script>
