<template>
  <div class="modal__place-body">
    <div class="modal__place-type">
      <div class="modal__place-type-content">
        <div
          :class="[
            'modal__place-type-content-item',
            { activeType: type.activeType },
            { activeOpacity: type.activeOpacity },
          ]"
          @mouseover="showDetails(type, data[currentIndex].modalData)"
          @mouseleave="hideDetails(type, data[currentIndex].modalData)"
          v-for="(type, index) in data[currentIndex].modalData"
          :key="index"
        >
          <img :src="type.img" :alt="index" />
          <h3>{{ type.head }}</h3>
          <p>{{ type.text }} <a href="#">Discover more</a></p>
        </div>
      </div>
    </div>
    <button @click="moveForward" class="modal__place-button">
      Choose
    </button>
  </div>
</template>

<script>
export default {
  name: 'RowImgUp',
  components: {},
  props: {
    moveForward: Function,
    data: Array,
    currentIndex: Number,
  },
  methods: {
    showDetails(obj, massOfTheObjs) {
      obj.activeType = true

      massOfTheObjs.forEach(item => {
        item.activeOpacity = true
      })

      obj.activeOpacity = false
    },
    hideDetails(obj, massOfTheObjs) {
      obj.activeType = false

      massOfTheObjs.forEach(item => {
        item.activeOpacity = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.activeType {
  border: 1px solid #583b75 !important;
  box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.3);
}
.activeOpacity {
  opacity: 0.5;
}

.modal__place-body {
  width: 90%;

  .modal__place-button {
    margin: 15% auto 5% auto;
    background: #583b75;
    color: #ffffff;
    padding: 15px 80px;

    box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.3);

    font-weight: 600;
    font-size: 16px;
    text-align: center;
    border-radius: 2px;
  }

  .modal__place-type {
    .modal__place-type-content {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: -7%;
      margin-top: 12%;
      .modal__place-type-content-item {
        display: flex;
        flex-direction: column;
        width: 25%;
        margin-left: 2%;
        border-radius: 3px;
        img {
          width: 100%;
        }
        h3 {
          font-size: 12px;
          text-align: left;
          margin: 5% 6% 5% 6%;
        }
        p {
          text-align: left;
          font-size: 9px;
          margin: 0 6% 5% 6%;
          a {
            text-decoration: underLineProgress;
            color: #583b75;
          }
        }
      }
    }
  }
}
</style>
