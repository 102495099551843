<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <nav
            class="navbar navbar-mob-ver mb-5"
            :class="{'navbar--show-menu': showNav}"
        >
          <ul class="navbar__list">
            <li
                @click="closeMenu()"
                v-for="item in navItems"
                :key="item.id"
                :itemid="item.id"
                class="navbar__item "
            >
              <router-link
                  class="navbar__link"
                  :to="item.path"
                  active-class="active"
              >
                {{ item.title }}
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {toggleNavbarMutationsTypes} from "@/store/modules/toggleNavbar";

export default {
  name: 'BhMainMenu',
  computed: {
    ...mapState({
      showNav: state => state.toggleNavbar.isShowNav,
    }),
  },
  data() {
    return {
      navItems: [
        {
          title: 'Planning for everything',
          id: 0,
          path: '/pre-planning-funeral',
        },
        { title: 'Arranging a funeral', id: 1, path: '/arranging-a-funeral' },
        { title: 'Legal issues', id: 2, path: '/legal' },
        { title: 'FAQ page', id: 3, path: '/faq' },
        { title: 'About us', id: 4, path: '/about-us' },
      ],
    }
  },
  methods: {
    ...mapMutations({
      closeNavbar: toggleNavbarMutationsTypes.closeNavbar,
    }),
    closeMenu() {
      this.closeNavbar()
    },
  },
}
</script>

<style lang="scss">
.navbar {
  padding: 0 !important;
  margin: 0 auto;
  border-top: 1px solid rgba(70, 70, 70, 0.3);
  border-bottom: 1px solid rgba(70, 70, 70, 0.3);

  .navbar__list {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 auto;
  }

  .navbar__link {
    display: block;
    font-size: 16px;
    color: #000000;
    width: 100%;
    text-decoration: none;
    margin: 0 auto;
    padding: 16px 0;
    border-bottom: 2px solid transparent;
    transition: border-bottom-color .5s;

    &.active {
      border-bottom-color: #000000;
    }
  }
}
.navbar__item {
  cursor: pointer;
}
.navbar__toggle-menu {
  display: none;
}

@media (max-width: 992px) {
  .navbar > ul {
    display: none;
  }
  .navbar.navbar-mob-ver {
    position: fixed;
    width: 100vw !important;
    top: 90px;
    left: 0;
    z-index: -100;
    background: #fff;
    box-sizing: border-box;
    height: 0;
    opacity: 0;
    transition: height .4s, opacity .5s;
    border: none;
    align-items: flex-start;

    .navbar__toggle-menu {
      display: block;
    }
  }
  .navbar.navbar-mob-ver .navbar__list {
    display: flex;
    height: 100%;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: flex-start;
  }
  .navbar.navbar--show-menu {
    height: calc(100vh - 90px);
    opacity: 1;
    z-index: 10;

    .navbar__toggle-menu {
      width: 75%;
      margin: 0 auto;
    }
    .navbar__close-menu {
      margin-top: 106px;
    }
  }
  .navbar .navbar__item {
    margin-top: 30px;
    border: none;
    position: relative;
  }
  .navbar .navbar__item:after {
    content: '';
    display: none;
    position: absolute;
    width: 50px;
    height: 2px;
    background: black;
    bottom: 0;
    left: 50%;
    margin-left: -25px;
  }
  .navbar .navbar__item.active:after {
    display: block;
  }
  .navbar .navbar__link {
    font-size: 18px;
  }
}
</style>
