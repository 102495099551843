<template>
  <transition name="modal">
    <div class="mask" @click="close">
      <div class="modal__login" @click.stop>
        <span @click="close" class="modal__close">&#x2715;</span>
        <component
          :is="currentModal"
          :toggleModalType="toggleModalType"
          :closeModal="close"
          :message="message"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import BpSignIn from './SingInModal.vue'
import BpForgot from './ForgotPassModal.vue'
import BpSignUp from './SignUpModal.vue'
import BpMainModal from '../../UserProfile/UserProfileMain.vue'
import BpConfirm from './ConfirmModal.vue'
import BpDone from './DoneModal.vue'

import { mapState } from 'vuex'

import { loginModalsMutationTypes } from '@/store/modules/loginModals'

export default {
  name: 'BpModalLogin',
  props: ['closeModal'],
  components: {
    BpSignIn,
    BpForgot,
    BpSignUp,
    BpMainModal,
    BpConfirm,
    BpDone,
  },

  computed: {
    ...mapState({
      loginModalProcces: state => state.loginModals.procces,
    }),
  },

  data() {
    return {
      currentModal: 'bp-sign-in',
      message: 'Done!',
    }
  },
  methods: {
    toggleModalType(procces, message = '') {
      this.$store.commit(loginModalsMutationTypes.updateLoginProcces, procces)
      this.message = message
    },
    close() {
      this.toggleModalType('signIn')
      this.$emit('close')
    },
  },
  watch: {
    loginModalProcces(procces) {
      const modals = {
        signIn: 'bp-sign-in',
        signUp: 'bp-sign-up',
        forgot: 'bp-forgot',
        confirm: 'bp-confirm',
        done: 'bp-done',
      }
      this.currentModal = modals[procces]
    },
  },
}
</script>

<style lang="scss" scoped>
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  .modal__login {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    width: 100%;
    background: #ffffff;
    border-radius: 2px;
    box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.1);
    position: fixed;
    top: 5%;
    right: 7%;
    z-index: 10;
    transition: all 0.3s ease;
    @media (min-width: 1366px) {
      top: 13%;
    }

    @media (min-width: 1370px) {
      top: 6%;
      right: 17%;
    }
    .modal__close {
      position: absolute;
      top: 10px;
      right: 13px;
      cursor: pointer;
    }
  }
}
@media (max-width: 500px) {
  .mask .modal__login {
    max-width: 320px;
    right: 15px;
  }
}
</style>
