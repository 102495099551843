<template>
  <main>
    <div class="modal__place">
      <transition name="modal__place">
        <div class="modal__place-mask" @click="close">
          <Loader v-show="loading" />
          <div class="modal__place-container" @click.stop>
            <img
              id="arrowLeft"
              v-if="currentIndex !== 0"
              @click="moveBack"
              src="./../../assets/arrow.png"
              alt="arrowLeft"
            />
            <img
              @click="moveForward"
              v-if="currentIndex !== lastIndex"
              class="arrowRight"
              src="./../../assets/arrow.png"
              alt="arrowRight"
            />
            <h4 class="modal__place-header">
              <span @click="close" class="modal__place-close">&#x2715;</span>
            </h4>

            <LineProgress :width="progressBarWidth" />

            <component
              :is="currentModal"
              :data="currentQuiz"
              :currentIndex="currentIndex"
              :moveForward="moveForward"
              :updateAnsw="updateAnsw"
            />
          </div>
        </div>
      </transition>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FormBody from './components/FormBody.vue'
import RowImgUp from './components/RowImgUp.vue'
import RowImgDown from './components/RowImgDown.vue'
import TableBody from './components/TableBody.vue'
import LineProgress from './components/LineProgress.vue'
import Loader from './../Loader/Loader.vue'
import AskerYesNo from './components/AskerYesNo.vue'
import BpQuestion from './Question.vue'
import BpCardUp from './CardUp.vue'
import BpCardDown from './CardDown.vue'
import BpTable from './Table.vue'
import {
  quizzesGettersTypes,
  quizzesMutationsTypes,
  quizzesActionsTypes,
} from '@/store/modules/quizzesModals'

import { authMutationsTypes, authActionTypes } from '@/store/modules/auth'
const { isLoggedIn, isFirstLogin } = authMutationsTypes
const { getCurrentUser } = authActionTypes

const { toggleActiveQuiz, toggleModals } = quizzesMutationsTypes
const { sendAnswers, updateAnswer, getAnswers } = quizzesActionsTypes
// const { getQuizz } = quizzesActionsTypes

export default {
  name: 'ConstructorModal',
  components: {
    FormBody,
    RowImgUp,
    RowImgDown,
    TableBody,
    LineProgress,
    Loader,
    AskerYesNo,
    BpQuestion,
    BpCardUp,
    BpCardDown,
    BpTable,
  },
  computed: {
    ...mapState({
      userID: state => state.auth.currentUser.id,
      activeQuizID: state => state.quizzesModals.activeQuizID,
      activeQuizProcess: state => state.quizzesModals.process,
      quizzes: state => state.quizzesModals.quizzes?.items,
      answers: state => state.quizzesModals.answers || [],
      isExecutor: state => state.auth.currentUser?.is_executor,
    }),
    ...mapGetters({
      currentQuiz: quizzesGettersTypes.currentQuiz,
    }),
    currentIndex() {
      return this.quizzes?.findIndex(q => q.quizzID === this.activeQuizID)
    },
    prevIndex() {
      return this.currentIndex <= 0 ? 0 : this.currentIndex - 1
    },
    nextIndex() {
      return this.currentIndex >= this.lastIndex
        ? this.lastIndex
        : this.currentIndex + 1
    },
    lastIndex() {
      return this.quizzes?.length - 1
    },
    progressBarWidth() {
      const fullWidth = 100
      const step = fullWidth / this.quizzes?.length
      const progress = Math.ceil((this.currentIndex + 1) * step)
      return progress
    },
    controlQuestionIsNo() {
      /* const controlQuestionIdx = this.answers.findIndex(a => a.control_question) */
      return this.answers[6]?.answer.text === 'NO'
    },
    finished() {
      return (
        this.quizzes?.length === this.answers?.length ||
        this.controlQuestionIsNo
      )
    },
    currentModal() {
      const modals = {
        form: 'FormBody',
        question: 'bp-question',
        cardUp: 'bp-card-up',
        cardDown: 'bp-card-down',
        table: 'bp-table',
      }
      const type = this.currentQuiz?.quizzType
      return modals[type]
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    moveBack() {
      const actualID = this.quizzes[this.prevIndex]?.quizzID
      this.$store.commit(toggleActiveQuiz, actualID)
    },
    moveForward() {
      const actualID = this.quizzes[this.nextIndex]?.quizzID
      /* const actualAnsw = this.currentQuiz */
      /* console.log('actualAnsw ->', actualAnsw) // eslint-disable-line no-console */
      this.$store.commit(toggleActiveQuiz, actualID)
    },
    close() {
      this.$store.commit(toggleModals)
    },
    updateAnsw(answer) {
      const data = {
        questionID: this.currentQuiz.quizzID,
        answer,
      }
      this.$store.dispatch(updateAnswer, data)
    },
    // useLoader() {
    //   this.loading = true
    //
    //   setTimeout(() => {
    //     this.loading = false
    //     this.setDefaultModal()
    //   }, 4000)
    // },
  },
  watch: {
    finished(value) {
      if (value && this.answers.length) {
        this.$store.dispatch(getCurrentUser).then(() => {
          this.$store.commit(toggleModals)
          const data = {
            userID: this.userID,
            answers: this.answers,
          }
          this.$store.dispatch(sendAnswers, data).then(() => {
            this.$store.commit(isLoggedIn)
            this.$store.commit(isFirstLogin, false)
            this.$store.dispatch(getAnswers, 'logged-in')
          })
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#arrowLeft {
  transform: rotate(90deg);
  position: absolute;
  left: 3%;
  top: 50%;
  height: 1.8%;
  width: 2.5%;
  filter: contrast(100%);
}
.arrowRight {
  transform: rotate(-90deg);
  position: absolute;
  right: 3%;
  top: 50%;
  height: 1.8%;
  width: 2.5%;
  filter: contrast(100%);
}
#arrowLeft:hover {
  cursor: pointer;
  filter: contrast(400%);
}
.arrowRight:hover {
  cursor: pointer;
  filter: contrast(400%);
}

.modal__place {
  .modal__place-mask {
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
  }

  .modal__place-container {
    width: 940px;
    height: 600px;
    max-width: 60%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;

    position: relative;

    .modal__place-header {
      width: 100%;
      margin: 3% 0;
      font-weight: 500;
      font-size: 24px;
      text-align: center;
    }
    .modal__place-close {
      position: absolute;
      top: 10px;
      right: 13px;
      cursor: pointer;
    }
  }
}
</style>
