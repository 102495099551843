const state = {
  isShown: false,
  procces: 'singUp',
}

export const loginModalsMutationTypes = {
  closeLoginModals: 'loginModal/closeModals',
  toggleLoginModals: 'loginModal/toggleModals',
  updateLoginProcces: 'loginModal/updateProcces',
}

const mutations = {
  [loginModalsMutationTypes.closeLoginModals](state) {
    state.isShown = false
  },
  [loginModalsMutationTypes.toggleLoginModals](state) {
    state.isShown = !state.loginModal.isShown
  },
  [loginModalsMutationTypes.updateLoginProcces](state, payload) {
    state.procces = payload
  },
}

export const loginModalsActionsTypes = {}

const actions = {}

export const loginModalsGettersTypes = {
  loginModalIsShown: 'loginModal/isShown',
  loginModalProcces: 'loginModal/procces',
}

const getters = {
  [loginModalsGettersTypes.loginModalIsShown]: state => {
    return state.isShown
  },
  [loginModalsGettersTypes.loginModalProcces]: state => {
    return state.procces
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
