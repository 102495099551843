<template>
  <section class="faq mb-5">
    <h4>{{ title }}</h4>
    <ul class="faq__list">
      <li v-for="item in data" :key="item.id" :itemid="item.id">
        <div class="faq__ask">
          <span>{{ item.ask }}</span>
          <button @click="handleClick(item.id)">
            <template
                v-if="item.isShown"
            >
              <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.90918 1H15.091" stroke="black"/>
              </svg>
            </template>
            <template v-else>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.90918 8H15.091" stroke="black"/>
                <path d="M8 15.0916L8 0.909733" stroke="black"/>
              </svg>
            </template>
          </button>
        </div>
        <div v-show="item.isShown" class="faq__answer">
          {{ item.answer }}
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'BpFaq',
  props: ['data', 'title'],
  data() {
    return {
      faqData: {
        title: 'Planning for everything',
        data: [
          {
            ask: 'Which types of payment can I make?',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
            id: 0,
            isShown: false,
          },
          {
            ask: 'Which types of payment can I make?',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
            id: 1,
            isShown: false,
          },
          {
            ask: 'Which types of payment can I make?',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
            id: 2,
            isShown: false,
          },
          {
            ask: 'Which types of payment can I make?',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla nulla vitae felis rhoncus eleifend. Pellentesque a ligula vel sapien pellentesque blandit. Nulla aliquet nulla ut justo blandit mollis. Sed efficitur nulla non massa porta finibus. Curabitur sed metus facilisis, sodales ex non, imperdiet erat. Aliquam id congue quam. Duis scelerisque pretium rutrum. Suspendisse condimentum lacus vel blandit iaculis. Cras nisl diam, dapibus non mattis vitae, suscipit a sem. Aliquam auctor odio dictum dolor fringilla, at bibendum nunc.',
            id: 3,
            isShown: false,
          },
        ],
      },
    }
  },
  methods: {
    handleClick(id) {
      const item = this.data.find(d => d.id === id)
      const { isShown } = item
      item.isShown = !isShown
    },
  },
}
</script>

<style lang="scss">
.faq {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq h4 {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin-bottom: 3%;
}
.faq__list {
  width: 100%;
  margin-top: 3%;

  li {
    margin-bottom: 3%;
  }
}
.faq__ask {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 36px;
  font-size: 18px;
  border-bottom: 1px solid rgba(70, 70, 69, 0.3);
  &:last-child {
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);
  }
}

.faq__answer {
  margin: 28px 32px;
  color: rgba(70, 70, 69, 0.5);
  text-align: left;
}
</style>
