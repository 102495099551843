<template>
  <ul class="modal__content-setting_list">
    <li
      v-for="(value, key, i) in user"
      class="modal__content-setting_list_item"
      :key="i"
      :itemid="i"
    >
      <div class="modal__content-setting_user">
        <p class="key">{{ key }}:</p>
        <input
          v-if="currEdit == key && key == 'name' || currEdit == key && key == 'email'"
          class="update-field-input value"
          type="text"
          v-model="update[currEdit]"
          required
        />
        <div class="edit_password_block" v-else-if="currEdit == key && key == 'password'">
          <input
            class="update-field-input value"
            type="password"
            placeholder="Old password"
            v-model="update[key].old"
          />
          <input
            class="update-field-input value"
            type="password"
            placeholder="New password"
             v-model="update[key].new"
          />
          <input
            class="update-field-input value"
            type="password"
            placeholder="Confirm password"
             v-model="update[key].confirm"
          />
        </div>
        <p v-else class="value">{{ value }}</p>
        <button v-if="currEdit !== key" @click="toggleEdit(key)">Edit</button>
        <div v-else class="edit_block">
          <button @click="save(key)">Save</button>
          <button @click="cancel()">Cancel</button>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import { authGetterTypes, authActionTypes } from '@/store/modules/auth'

const { changePersonalInfo, changePass } = authActionTypes;
export default {
  name: 'BpUserInfo',
  data() {
    return {
      currEdit: -1,
      update: {
        name: '',
        email: '',
        password: {
          old: '',
          new: '',
          confirm: '',
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: authGetterTypes.currentUser,
    }),
    user() {
      return {
        name: this.currentUser?.name,
        email: this.currentUser?.email,
        password: '*******',
      }
    },
  },
  methods: {
    setDefaultValuesForUpdate() {
      for (let key in this.update) {
        this.update[key] = '';
      }
    },
    isEmpty(val) {
      let valid = true;

      if (!val) {
        valid= false;
      }

      return valid;
    },
    validate(key) {
      let valid = true;
      const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      switch(key) {
        case 'email': {
          if (!regEmail.test(this.update[key])) {
            valid = false;
          }
          break;
        }
      }

      return valid;
    },
    toggleEdit(key) {
      this.currEdit = key;
      if (key !== 'password') {
        this.update[key] = this.user[key];
      }
    },
    save(key) {
      if (this.validate(key) && key !== 'password') {
        const dataInfo = {
          id: this.currentUser?.id,
          data: {
            'action': `change-${key}`,
            [key]: this.update[key]
          }
        };

        this.$store.dispatch(changePersonalInfo, dataInfo);
        this.currEdit = -1;
      } else {
        const data = {
          oldPassword: this.update[key].old,
          newPassword: this.update[key].new,
          c_newPassword: this.update[key].confirm,
        }

        this.$store
          .dispatch(changePass, data)
          .then(() => {
            this.cancel();
          })
          .catch(Error)
      }
    },
    cancel() {
      this.currEdit = -1;
      this.setDefaultValuesForUpdate();
    },
  },
}
</script>

<style lang="scss" scoped>
.modal__content-setting_user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  margin: 1% auto;

  width: 100%;

  .key {
    width: 15%;
    font-size: 18px;
    text-align: left;
  }

  .value {
    text-align: left;
    flex-grow: 1;
    font-weight: bold;
    color: blue;
  }

  input {
    width: 65%;
    padding: 1%;
    border: 1px solid rgba(70, 70, 69, 0.3);
    border-radius: 2px;
    &::placeholder {
      color: #a7a7a7;
    }
    .update-field-input {
      display: flex;
      width: 100%;
    }
  }

  button {
    color: #583b75;
  }

  .edit_password_block {
    width: 65%;
    display: flex;
    justify-content: flex-start;
    input {
      margin-right: 2%;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .edit_block {
    width: 20%;
    display: flex;
    justify-content: flex-start;
    margin-left: 2%;
    button {
      margin-right: 38%;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.modal__content-setting_edit {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 5% auto;
  .content-setting-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  .content-setting-input {
    width: 50%;
    padding: 1%;

    margin: 1%;
    border: 1px solid rgba(70, 70, 69, 0.3);

    border-radius: 2px;
    &::placeholder {
      color: #a7a7a7;
    }
  }

  .content-setting-buttons {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .content-setting-cancel {
    margin: 2% 4%;
    padding: 1% 4%;
    box-shadow: 0 10px 10px rgba(88, 59, 117, 0.3);
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid #a7a7a7;
    color: #a7a7a7;
    &:hover {
      background: #a7a7a7;
      color: #ffffff;
    }
  }

  .content-setting-save {
    margin: 2% 4%;
    padding: 1% 5%;
    box-shadow: 0 10px 10px rgba(88, 59, 117, 0.3);
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid #583b75;
    color: #583b75;
    &:hover {
      background: #583b75;
      color: #ffffff;
    }
  }
}
</style>
