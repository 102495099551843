import axios from '@/api/axios'

const getQuizz = quizz => {
  return axios.get(`/quizes?block_id=${quizz}`)
}
const getAnswers = quizz => {
  return axios.get(`/quizes/answers?block_id=${quizz}`)
}
const getDefaultAnswers = quizz => {
  return axios.get(`/quizes/default-answers?block_id=${quizz}&default=1`)
}

const changeAnswer = answer => {
  return axios.patch(`/quizes/answer/update`, answer)
}
const sendResult = answers => {
  return axios.post(`/quizes/send`, answers)
}

export default {
  getQuizz,
  getAnswers,
  sendResult,
  changeAnswer,
  getDefaultAnswers,
}
