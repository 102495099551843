<template>
  <section class="singup mb-5 row">
    <div class="col-12">
      <div class="callback_block">
        <span class="phone-number">0800 8611 720</span>
        <span class="text">Call us anytime or request a callback!</span>
      </div>
    </div>
    <div class="col-12 col-md-6 offset-0 offset-md-3">
      <form class="singup__form" @submit.prevent="handleSubmit">
        <input
          class="singup__input"
          type="text"
          placeholder="Your Name"
          v-model="user.name"
        />

        <div class="singup__name_block">
          <input
            class="singup__input"
            type="email"
            placeholder="Your Email"
            v-model="user.email"
          />
          <input
            class="singup__input"
            type="tel"
            placeholder="Your Phone Number"
            v-model="user.phone"
          />
        </div>

        <textarea
          class="singup__input"
          rows="4"
          placeholder="Your Message"
          v-model="user.message"
        ></textarea>

        <small class="messege" v-if="messege">{{ messege }}</small>
        <input
          class="singup__button"
          type="submit"
          value="Send"
          :disabled="isSubmitting"
        />
      </form>
    </div>
  </section>
</template>

<script>
import { servicesActionsTypes } from '@/store/modules/services'
const { sendContactForm } = servicesActionsTypes
export default {
  name: 'formPhone',
  data() {
    return {
      isSubmitting: false,
      url: '',
      user: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
      messege: '',
    }
  },
  methods: {
    setDefaultValues() {
      for (let key in this.user) {
        this.user[key] = ''
      }
    },
    handleSubmit() {
      const isChecked = this.checkForm()
      if (!isChecked) {
        return
      }

      this.isSubmitting = true
      this.$store
        .dispatch(sendContactForm, this.user)
        .then(() => {
          this.setDefaultValues()
          this.messege = ''
          this.isSubmitting = false
        })
        .catch(console.error)
    },
    checkForm() {
      if (!this.user.email) {
        this.messege = 'Email required.'
        return false
      }
      /* if (!this.user.name) { */
      /*   this.messege = 'Name required.' */
      /*   return false */
      /* } */
      if (!this.validEmail(this.user.email)) {
        this.messege = 'Valid email required.'
        return false
      }
      return true
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  .singup {
    .callback_block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .phone-number {
        font-size: 30px;
        font-weight: 700;
      }

      text {
        font-size: 23px;
        font-weight: 600;
      }
    }

    small {
      color: red;
      width: 100%;
      text-align: left;
    }

    h5 {
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      margin-bottom: 3%;
      margin-top: -0%;
    }

    p {
      margin: 2% auto;
      font-size: 11px;
      text-align: center;
      color: #6a6a6a;

      a {
        font-size: 12px;
        color: #536dcb;
      }
    }

    .singup__form {
      width: 100%;
      small {
        display: block;
        text-align: center;
        color: red;
      }
    }

    .singup__name_block {
      width: 100%;
      display: flex;
      margin: 0 auto;
    }
    .singup__name_block .singup__input:first-child {
      margin-right: 10px;
    }

    .singup__input {
      width: 100%;
      padding: 2%;
      margin: 1% 0;
      border: 1px solid rgba(70, 70, 69, 0.3);
      border-radius: 2px;

      &::placeholder {
        color: #a7a7a7;
      }
    }

    .singup__button {
      margin: 2% auto 5%;
      background: #583b75;
      color: #ffffff;
      padding: 13px 79px;
      display: block;

      font-weight: 600;
      font-size: 16px;
      text-align: center;
      border-radius: 2px;
    }
  }
}
</style>
