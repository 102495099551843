const state = {
  tabs: {
    activeTabID: 0,
    items: [
      { title: 'Legal', id: 0, type: 'wishlist' },
      { title: 'My plan', id: 1, type: 'plan' },
      { title: 'Guests', id: 2, type: 'guests' },
      { title: 'Account', id: 3, type: 'account' },
    ],
  },

  account: {
    activeMenuID: 6,
    activeSubmenuID: -1,
    executorWithdrawn: false,

    menu: [
      { title: 'Settings', id: 0 },
      // { title: 'Support & help', id: 4 },
      { title: 'About us', id: 5 },
      { title: 'Logout', id: 6 },
    ],

    submenu: [
      {
        title: 'Personal info',
        subtitle: 'Set up reminders to update your regularly',
        id: 0,
        isShown: false,
        menuID: 0,
        type: 'info',
      },
      {
        title: 'Notifications',
        subtitle: 'Set up reminders to update your regularly',
        id: 1,
        isShown: false,
        menuID: 0,
      },
      {
        title: 'Two-step verification',
        subtitle: 'Set up reminders to update your regularly',
        id: 2,
        isShown: false,
        menuID: 0,
      },
      {
        title: 'Add extra security',
        subtitle: 'Set up reminders to update your regularly',
        id: 3,
        isShown: false,
        menuID: 0,
      },
    ],
  },
}

export const userProfileMutationsTypes = {
  // tabs
  toggleActiveTabID: 'userProfile/toggleActiveTabID',
  // account
  toggleActiveMenuID: 'userProfile/toggleActiveMenuID',
  toggleActiveSubMenuID: 'userProfile/toggleActiveSubMenuID',
  setExecutorWithdrawn: 'userProfile/setExecutorWithdrawn',
}

const mutations = {
  //tabs
  [userProfileMutationsTypes.toggleActiveTabID](state, payload) {
    state.tabs.activeTabID = payload
  },
  // account
  [userProfileMutationsTypes.toggleActiveMenuID](state, payload) {
    state.account.activeMenuID = payload
  },
  [userProfileMutationsTypes.toggleActiveSubMenuID](state, payload) {
    state.account.activeSubmenuID = payload
  },
  [userProfileMutationsTypes.setExecutorWithdrawn](state, payload) {
    state.account.executorWithdrawn = payload
  }
}

export const modalsActionsTypes = {}

const actions = {}

export const userProfileGetterTypes = {
  // tobs
  tabs: 'userProfile/tabs',
  activeTabID: 'userProfile/activeTabID',
  // account
  menus: 'userProfile/menus',
  subMenus: 'userProfile/subMenus',
  activeMenu: 'userProfile/activeMenu',
  activeSubMenu: 'userProfile/subMenus',
  activeWishlistID: 'userProfile/activeWishlistID',
  getExecutorWithdrawn: 'userProfile/getExecutorWithdrawn',
}

const getters = {
  // tobs
  [userProfileGetterTypes.activeTabID]: state => {
    return state.tabs.activeTabID
  },
  [userProfileGetterTypes.tabs]: state => {
    return state.tabs.items
  },
  // account
  [userProfileGetterTypes.activeMenuID]: state => {
    return state.account.activeMenuID
  },
  [userProfileGetterTypes.activeSubmenuID]: state => {
    return state.account.activeSubmenuID
  },
  [userProfileGetterTypes.menus]: state => {
    return state.account.menu
  },
  [userProfileGetterTypes.subMenus]: state => {
    return state.account.submenu
  },
  [userProfileGetterTypes.getExecutorWithdrawn]: state => {
    return state.account.executorWithdrawn
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
