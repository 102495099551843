<template>
  <div class="tabs my-5 row">
    <div
      v-for="tab in tabs"
      :key="tab.id"
      class="col-12 col-sm-6 col-md-3 mb-4 mb-md-0"
    >
      <button
        :class="{ active: tab.id === activeTabID }"
        @click="toggleActiveTab(tab.id)"
      >
        {{ tab.title }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
  diedProfileGetterTypes,
  diedProfileMutationsTypes,
} from '@/store/modules/diedProfile'

const { toggleActiveTabID } = diedProfileMutationsTypes

export default {
  name: 'BpDiedTabBar',
  computed: {
    ...mapState({
      answers: state => state.quizzesModals.answers,
    }),
    ...mapGetters({
      tabs: diedProfileGetterTypes.tabs,
      showModal: diedProfileGetterTypes.isShown,
      activeTabID: diedProfileGetterTypes.activeTabID,
    }),
  },
  data() {
    return {}
  },
  methods: {
    toggleActiveTab(id) {
      this.$store.commit(toggleActiveTabID, id)
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  width: 100%;
  padding: 1% 4%;
  box-shadow: 0px 10px 10px rgba(88, 59, 117, 0.3);
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  border-radius: 2px;
  border: 1px solid #583b75;
  color: #583b75;
  &.active {
    background: #583b75;
    color: #ffffff;
  }
}
</style>
