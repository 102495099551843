<template>
  <section class="my_plan">
    <HeadTable :heading="heading" />
    <BodyTable :answers="answers" />
    <FooterTable :footer="footer" :cost="cost" />
  </section>
</template>

<script>
import HeadTable from './components/HeadTable.vue'
import BodyTable from './components/BodyTable.vue'
import FooterTable from './components/FooterTable.vue'

export default {
  name: 'main-table',
  props: ['heading', 'footer', 'answers', 'cost'],
  components: {
    HeadTable,
    BodyTable,
    FooterTable,
  },
}
</script>

<style lang="scss" scoped>
.my_plan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
